/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import {observable} from 'mobx';

export default class ObjectModel {
  ID;
  objCode;
  name;
  offset = 0;
  showMore = true;
  intervals = [];
  @observable expandable;
  @observable toggled;

  constructor(objCode, ID, name, expandable, intervals) {
    this.objCode = objCode;
    this.intervals = intervals;
    this.ID = ID;
    this.name = name;
    this.expandable = expandable;
    this.toggled = false;
  }
}
