import {observable} from 'mobx';
import utilities from '../services/Utilities';
import _ from 'lodash';
import {redrockClient} from '../../localization/localization';

export default class FilterStore {
  filterOptions;
  @observable uniqueUrlFilterID = null;
  @observable selectedFilterExpression = {};
  @observable statusesList = [];
  @observable applyingIsCompleted = false;
  @observable defaultFilter = null;

  get selectedFilterID() {
    return this.uniqueUrlFilterID || localStorage['PLANNERSelectedFilterID'] || -1;
  }

  fillStatusList(data) {
    let mappedStatues = _.chain(data)
      .map(item => {
        return {
          ID: item.value,
          name: item.label
        };
      })
      .value();
    this.statusesList.push(...mappedStatues);
  }

  generateDefaultFilter(stepCount) {
    let defaultFilter = {
      name: redrockClient.getTextSync('resourceplanner.default'),
      ID: -1,
      expression: [
        {
          fieldSearchValue: {
            group: {
              key: 'project',
              label: redrockClient.getTextSync('project')
            },
            item: {
              dataType: 'dateTime',
              value: 'plannedCompletionDate',
              label: redrockClient.getTextSync('plannedcompletiondate')
            }
          },
          filterType: 'CustomDataFilter',
          id: utilities.generateRandomNumber(),
          operator: {value: 'gte'},
          fieldValues: ['$$TODAYbm']
        },

        {
          fieldSearchValue: {
            group: {
              key: 'project',
              label: redrockClient.getTextSync('project')
            },
            item: {
              dataType: 'dateTime',
              value: 'plannedStartDate',
              label: redrockClient.getTextSync('plannedstartdate')
            }
          },
          filterType: 'CustomDataFilter',
          id: utilities.generateRandomNumber(),
          operator: {value: 'lte'},
          fieldValues: []
        },

        {
          fieldSearchValue: {
            group: {
              key: 'project',
              label: redrockClient.getTextSync('project')
            },
            item: {
              dataType: 'string',
              value: 'statusEquatesWith',
              label: redrockClient.getTextSync('statusequateswith'),
              enumType: 'PROJ'
            }
          },
          filterType: 'CustomDataFilter',
          id: utilities.generateRandomNumber(),
          operator: {value: 'cieq'},
          fieldValues: []
        }
      ],
      objCode: 'RPFL',
    };

    this.statusesList.map(item => {
      if (item.ID === 'CUR' || item.ID === 'PLN') {
        defaultFilter.expression[2].fieldValues.push(item);
      }
    });

    defaultFilter.expression[1].fieldValues.push(`$$TODAYe+${stepCount}m`);
    defaultFilter.expression = JSON.stringify(defaultFilter.expression);
    this.defaultFilter = defaultFilter;
  }

  constructor() {
    this.filterOptions = observable({
      visible: false
    });
  }

  showFilters() {
    this.filterOptions.visible = true;
  }

  hideFilters() {
    this.filterOptions.visible = false;
  }

  get hasSelectedFilter() {
      return !!Object.keys(this.selectedFilterExpression).length;
  }
}
