import React, {Component} from 'react';
import TreeNodeContainerLoading from './TreeNodeContainerLoading'
import IntervalsContainerLoading from './IntervalsContainerLoading';

export default class Loading extends Component {
  //noinspection JSAnnotator
  props: {
    height: Number
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="resource-planner-container">
        <TreeNodeContainerLoading height={this.props.height}/>
        <IntervalsContainerLoading height={this.props.height}/>
      </div>
    );
  }
}


