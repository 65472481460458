import ViewModeStore from '../../../shared/stores/ViewModeStore';
import {HOUR_VIEW_KEY, HourViewAttributes, ViewEnum} from '../../../shared/constants/ViewEnums';
import {checkIsNetEqualToAvlMinusPln, getUsePLNInNETCalculationsSettingValue} from '../../../shared/services/ColumnsCustomization';
import SettingsStore from '../../../shared/stores/SettingsStore';

export default class ObjectModel {
  ID;
  objCode;
  name;
  parentID;
  expandable;
  nodes;
  toggled;

  constructor(objCode, ID, name, nodes, parentID) {
    this.objCode = objCode;
    this.ID = ID;
    this.parentID = parentID;
    this.name = name;
    this.nodes = nodes;
    this.expandable = Boolean(nodes && nodes.length);
    this.toggled = false;
  }

  getCostRate(i, metric = HOUR_VIEW_KEY) {
    const costCoefficient = ViewModeStore.isCustomViewMode && HourViewAttributes[metric] ? HourViewAttributes[metric].costCoefficient : ViewModeStore.hourViewMode.costCoefficient;
    return costCoefficient || this[i].costRate;
  }

  getShowNetDiffOfAvlAndPln () {
    return checkIsNetEqualToAvlMinusPln(
      ViewModeStore.isCustomViewMode,
      getUsePLNInNETCalculationsSettingValue(SettingsStore.customColumnOptions['resource_planner.customColumns'].updates, ViewEnum.project)
    );
  }

  NETHour(i) {
    return this.getShowNetDiffOfAvlAndPln() ? this[i].AVLHour - this[i].PLNHour : this[i].AVLHour - this[i].BDGHour;
  }

  NETFTE(i) {
    return this.getShowNetDiffOfAvlAndPln() ? this[i].AVLFTE - this[i].PLNFTE : this[i].AVLFTE - this[i].BDGFTE;
  }

  NETCost(i) {
    return this.getShowNetDiffOfAvlAndPln() ?  this[i].AVLCost - this[i].PLNCost : this[i].AVLCost - this[i].BDGCost;
  }

  VARHour(i) {
    return this[i].BDGHour - this[i].PLNHour;
  }

  VARFTE(i) {
    return this[i].BDGFTE - this[i].PLNFTE;
  }

  VARCost(i) {
    return this[i].BDGCost - this[i].PLNCost;
  }
}
