import {observable, computed} from 'mobx';
import _ from 'lodash';
import {
  User as UserObjCode,
  Role as RoleObjCode,
  Project as ProjectObjCode
} from 'workfront-objcodes';
//Services
import {roundNumber} from '../../shared/services/Utilities';
import dataServiceUtilities from '../../shared/services/DataServiceUtilities';
//Models
import ObjectProjModel from '../models/nodes/ObjectProjModel';
import ObjectRoleModel from '../models/nodes/ObjectRoleModel';
import ObjectUserModel from '../models/nodes/ObjectUserModel';
//Stores
import {intervalsFTEs} from '../../shared/stores/IntervalsStore';
import ViewModeStore from '../../shared/stores/ViewModeStore';
//Constants
import {COST_VIEW_KEY, FTE_VIEW_KEY, HourViewCost} from '../../shared/constants/ViewEnums';

const projectViewDataService = {
  getNodeObjects: (nodes, intervals, indexes, lastLoadedProjectPriority, parentProjectID = null, parentRoleID = null, editBudgeting) => {
    if (!nodes) {
      return [];
    }

    let hour;

    return nodes.map((node) => {
      let objectModel;
      switch (node.objCode) {
        case ProjectObjCode :
          objectModel = new ObjectProjModel(
            node.ID,
            node.name,
            projectViewDataService.getNodeObjects(node.nodes, intervals, indexes, lastLoadedProjectPriority, node.ID, null, node.editBudgeting),
            node.priority + lastLoadedProjectPriority,
            node.optimizerPriority,
            node.realPriority === null,
            node.plannedStartDate,
            node.plannedCompletionDate,
            node.editBudgeting
          );

          intervals.forEach((interval) => {
            hour = interval.hours[objectModel.ID];
            projectViewDataService.fillProjectNodeHours(objectModel, hour, dataServiceUtilities.getIndex(interval.from, indexes));
          });

          break;
        case RoleObjCode :
          objectModel = new ObjectRoleModel(
            node.ID,
            node.name,
            projectViewDataService.getNodeObjects(node.nodes, intervals, indexes, lastLoadedProjectPriority, parentProjectID, node.ID, editBudgeting),
            parentProjectID,
            editBudgeting,
          );

          intervals.forEach((interval) => {
            hour = interval.hours[parentProjectID][objectModel.ID];
            projectViewDataService.fillRoleNodeHours(objectModel, hour, dataServiceUtilities.getIndex(interval.from, indexes));
          });

          break;
        case UserObjCode :
          objectModel = new ObjectUserModel(node.ID, node.name, parentRoleID, editBudgeting);

          intervals.forEach((interval) => {
            hour = interval.hours[parentProjectID][parentRoleID][objectModel.ID];
            projectViewDataService.fillUserNodeHours(objectModel, hour, dataServiceUtilities.getIndex(interval.from, indexes));
          });

          break;
      }

      return objectModel;
    });
  },

  fillProjectNodeHours(objectModel, hour, index) {
    if (hour) {
      objectModel[index] = {
        get PLNHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PLNHour, 0)
        },
        @computed get AVLHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].AVLHour, 0);
        },
        @computed get AVLFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].AVLFTE, 0);
        },
        @computed get AVLCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].AVLCost, 0);
        },
        @computed get BDGHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].BDGHour, 0);
        },
        @computed get BDGFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].BDGFTE, 0);
        },
        @computed get BDGCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].BDGCost, 0);
        },
        @computed get PLNCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PLNCost, 0);
        },
        @computed get PLNFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PLNFTE, 0);
        },
      };
    } else {
      objectModel[index] = {};
    }
  },

  fillRoleNodeHours(objectModel, hour, index) {
    if (hour) {
      objectModel[index] = {
        costRate: hour.COST_RATE || 0,
        PLNCost: hour.PLN_COST || 0,
        _PLN: hour.PLN || 0,
        get PLNHour() {
          if (ViewModeStore.hourViewMode.key === HourViewCost.key) {
            return objectModel[index].PLNCost
          }
          const FTE = intervalsFTEs.getFTE(index);
          if (FTE.get() === 0) {
            return 0;
          }
          return objectModel[index]._PLN / FTE;
        },
        @computed get AVLHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLHour, 0) * objectModel.getCostRate(index);
        },
        @computed get AVLCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLCost, 0) * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        @computed get AVLFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLFTE, 0);
        },
        @computed get BDGHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGHour, 0) * objectModel.getCostRate(index);
        },
        @computed get BDGFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGFTE, 0);
        },
        @computed get BDGCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGCost, 0) * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        @computed get BDGUserHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].BDGHour, 0);
        },
        get PLNFTE() {
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          return objectModel[index]._PLN / FTE;
        },
        get hasInvalidBDGUserHour() {
          return roundNumber(objectModel[index].BDGUserHour) > roundNumber(objectModel[index].BDGHour);
        }
      };
    } else {
      objectModel[index] = {};
    }
  },

  fillUserNodeHours(objectModel, hour, index, changedBDG = null, changedPBDG = null) {
    let FTE = intervalsFTEs.getFTE(index);
    objectModel[index] = {
      costRate: hour.COST_RATE || 0,
      PLNCost: hour.PLN_COST || 0,
      _AVL: hour.AVL || 0,
      _PAVL: hour.PAVL || 0,
      _PLN: hour.PLN || 0,
      chgBdg: _.isNumber(changedBDG),
      chgPbdg: _.isNumber(changedPBDG),
      @observable _BDG: _.isNumber(changedBDG) ? changedBDG : hour.BDG || 0,
      @observable _PBDG: _.isNumber(changedPBDG) ? changedPBDG : hour.PBDG || 0,
      get PLNHour() {
        if (ViewModeStore.hourViewMode.key === HourViewCost.key) {
          return this.PLNCost;
        }
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._PLN / FTE;
      },
      get BDGHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._BDG / FTE * objectModel.getCostRate(index);
      },
      get BDGFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._BDG / FTE;
      },
      get BDGCost() {
        return objectModel[index]._BDG * objectModel.getCostRate(index, COST_VIEW_KEY);
      },
      get PBDGHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._PBDG / FTE;
      },
      get PBDGFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._PBDG / FTE;
      },
      get PBDGCost() {
        return objectModel[index]._PBDG;
      },
      @computed get AVLHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        const filteredOutBDG = objectModel[index].filteredOutBDG / FTE * objectModel.getCostRate(index);
        const value = objectModel[index]._AVL / FTE * objectModel.getCostRate(index) - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGHour : value;
      },
      @computed get AVLFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (fteValue.get() === 0) {
          return 0;
        }
        const filteredOutBDG = objectModel[index].filteredOutBDG / fteValue;
        const value = objectModel[index]._AVL / fteValue - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGFTE : value;
      },
      @computed get AVLCost() {
        const costRateValue = objectModel.getCostRate(index, COST_VIEW_KEY);
        const filteredOutBDG = objectModel[index].filteredOutBDG * costRateValue;
        const value = objectModel[index]._AVL * costRateValue - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGCost : value;
      },
      @computed get PAVLHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        const filteredOutPBDG = objectModel[index].filteredOutPBDG / FTE;
        const value = objectModel[index]._PAVL / FTE - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGHour : value;
      },
      @computed get PAVLFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (fteValue.get() === 0) {
          return 0;
        }
        const filteredOutPBDG = objectModel[index].filteredOutPBDG / fteValue;
        const value = objectModel[index]._PAVL / fteValue - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGFTE : value;
      },
      @computed get PAVLCost() {
        const filteredOutPBDG = objectModel[index].filteredOutPBDG;
        const value = objectModel[index]._PAVL - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGCost : value;
      },
      @computed get higherBDGHour() {
        const filteredOutBDG = FTE.get() === 0 ? 0 : objectModel[index].filteredOutBDG / FTE * objectModel.getCostRate(index);
        const value = objectModel[index].BDGHour + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGHour : value;
      },
      @computed get higherBDGFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        const filteredOutBDG = fteValue.get() === 0 ? 0 : objectModel[index].filteredOutBDG / fteValue;
        const value = objectModel[index].BDGFTE + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGFTE : value;
      },
      @computed get higherBDGCost() {
        const filteredOutBDG = objectModel[index].filteredOutBDG * objectModel.getCostRate(index, COST_VIEW_KEY);
        const value = objectModel[index].BDGCost + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGCost : value;
      },
      @computed get higherPBDGHour() {
        const filteredOutPBDG = FTE.get() === 0 ? 0 : objectModel[index].filteredOutPBDG / FTE;
        const value = objectModel[index].PBDGHour + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGHour : value;
      },
      @computed get higherPBDGFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        const filteredOutPBDG = fteValue.get() === 0 ? 0 : objectModel[index].filteredOutPBDG / fteValue;
        const value = objectModel[index].PBDGFTE + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGFTE : value;
      },
      @computed get higherPBDGCost() {
        const filteredOutPBDG = objectModel[index].filteredOutPBDG;
        const value = objectModel[index].PBDGCost + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGCost : value;
      },
      @computed get PLNFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);

        if (FTE.get() === 0) {
          return 0;
        }

        return objectModel[index]._PLN / FTE;
      },
    };
  }
};

export default projectViewDataService;

