import {ViewEnum} from '../constants/ViewEnums';

export const getShowNetCalculationCheckboxValue = (viewMode) => {
  return viewMode !== ViewEnum.user;
}

export const checkIsNetEqualToAvlMinusPln = (isCustomViewMode, usePLNInNETCalculationsSetting) => {
  return isCustomViewMode && usePLNInNETCalculationsSetting;
}

export const getSortableCustomColumns = (customColumns) => {
  return Object.keys(customColumns).filter(key => Array.isArray(customColumns[key]));
}

export const getUsePLNInNETCalculationsSettingValue = (customColumnsSettingsDetails, activeView) => {
  return customColumnsSettingsDetails[activeView] && customColumnsSettingsDetails[activeView].usePLNInNETCalculationsSetting;
}
