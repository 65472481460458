/**
 * Created by anihambardzumyan on 8/23/17.
 */
import React, {Component} from 'react';
import {LightTertiaryButton} from 'titan/react-button';
import {Popup} from 'titan/react-popover';
import DownCaratSmallIcon from 'phoenix-icons/dist/DownCaratSmallIcon.js';
import {dropDownPopupOptions} from '../constants/PopupOptions';
import {observer} from 'mobx-react';

@observer
export default class DropDown extends Component {
  //noinspection JSAnnotator
  props: {
    selectedName: Object,
    disabled: Boolean
  };

  constructor(props) {
    super(props);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
  }

  handlePopupOpen() {
    if (this.dropDownListNode) {
      this.dropDownListNode.focusItemList();
    }
  }

  render() {
    return (
      <div className="dropdown-button-container">
        <Popup {...dropDownPopupOptions}
               content={this.props.children}
               onOpen={this.handlePopupOpen}>

          <LightTertiaryButton className="dropdown-button" disabled={this.props.disabled}>
            <span>{this.props.selectedName}</span>
            <DownCaratSmallIcon className="btn-arrow" color={this.props.disabled ? '#BDBDBD' : '#2f609f'}/>
          </LightTertiaryButton>
        </Popup>
      </div>
    );
  }
}
