import React, {Component} from 'react';
import LoadingRow from './LoadingRow';
import VisualizationStore from '../../../userView/stores/VisualizationStore';
import {loadingStore} from '../../stores/LoadingStore';
import {observer} from 'mobx-react';
import If from 'If';

@observer
export default class LoadingHeaderItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="interval-headers-col">
        <div className="date-interval date-interval-loading">
          <div className="loading-box loading-box-header"></div>
        </div>

        <If condition={VisualizationStore.data.showVisualization && loadingStore.nextPreviousLoading}>
          <div className="visualization-chart"></div>
        </If>

        <LoadingRow rowType="header"/>
      </div>
    );
  }
}


