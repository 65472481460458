/**
 * Created by artakpoghosyan on 2/22/18.
 */
export const ViewPortSizeNames = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extraSmall'
};

export const ViewPortSizeNumbers = {
  large: 25,
  medium: 33.33,
  small: 50,
  extraSmall: 100
};

export const ViewPortBreackPoints = {
  large: 1470,
  medium: 1260,
  small: 1054,
  extraSmall: 768
};
