/**
 * Created by artakpoghosyan on 2/15/18.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {PrimaryButton, TextButton, SecondaryButton} from 'titan/react-button';
import {Localization, redrockClient} from '../../../localization/localization'
import ViewModeStore from '../../stores/ViewModeStore';
import SaveDialogStore from '../../stores/SaveDialogStore';
import PropTypes from 'prop-types';
import {Dialog} from '@phoenix/all';
import {DialogHeader} from '../dialog/DialogHeader';

@observer
export default class SaveDialog extends Component {

  props: {
    onSave: Function
  };

  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.continue = this.continue.bind(this);
    this.close = this.close.bind(this);
  }

  save() {
    SaveDialogStore.state.visible = false;

    this.props.onSave().then(() => {
      SaveDialogStore.state.onConfirm();
      SaveDialogStore.state.onConfirm = undefined;
      ViewModeStore.priorityChanged = false;
    });
  }

  continue() {
    SaveDialogStore.state.onConfirm();
    SaveDialogStore.hideSaveDialog();
    ViewModeStore.priorityChanged = false;
  }

  close() {
    SaveDialogStore.hideSaveDialog();
    SaveDialogStore.state.isCanceled = true;
  }

  render() {
    return (
      <div>
        {SaveDialogStore.state.visible && <Dialog width={448}>
          <div className={'dialog-content'}>
            <DialogHeader
              title={redrockClient.getTextSync('confirm', 'Confirm')}
              onCancel={this.close}/>
            <div data-test-id="dialogbox-content" className={'dialogbox-content'}>
              <Localization messageKey={SaveDialogStore.state.messageKey}/>
            </div>
            <DialogContent save={this.save} continue={this.continue} close={this.close}/>
          </div>
        </Dialog>
        }
      </div>
    )
  }
}
//The separation of DialogContent has been done for quickSilver
//Without the separation in quickSilver throws error
function DialogContent(props) {
  return (
    <div className="dialog-footer">
      <div className="dialog-box-btns">
        <PrimaryButton onClick={props.save}>
          <Localization messageKey="action.save.and.continue"/>
        </PrimaryButton>

        <SecondaryButton onClick={props.continue} className="change-continue-button">
          <Localization messageKey="action.continue"/>
        </SecondaryButton>

        <TextButton onClick={props.close}>
          <Localization messageKey="action.cancel"/>
        </TextButton>
      </div>
    </div>
  )
}

DialogContent.propTypes = {
  save: PropTypes.func.isRequired,
  continue: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

