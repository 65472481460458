/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import {computed} from 'mobx';
import ViewModeStore from '../../../shared/stores/ViewModeStore';
import {FTE_VIEW_KEY, HOUR_VIEW_KEY, HourViewAttributes} from '../../../shared/constants/ViewEnums';

export default class HourTaskProjModel {
  constructor(PLN, ACT, fth) {
    this.showOnlyPlannedHour = true;
    this.fth = fth;
    this._PLN = PLN || 0;
    this._ACT = ACT || 0;
  }

  @computed
  get FTH() {
    return this.getFTHValue();
  }

  getFTHValue(metric = HOUR_VIEW_KEY) {
    const fteCoefficient = ViewModeStore.isCustomViewMode && HourViewAttributes[metric] ? HourViewAttributes[metric].fteCoefficient : ViewModeStore.hourViewMode.fteCoefficient;
    return fteCoefficient || this.fth;
  }

  @computed
  get PLNHour() {
    return this._PLN / this.FTH;
  }

  @computed
  get PLNFTE() {
    return this._PLN / this.getFTHValue(FTE_VIEW_KEY);
  }

  @computed
  get ACTHour() {
    return this._ACT / this.FTH;
  }

  @computed
  get ACTFTE() {
    return this._ACT / this.getFTHValue(FTE_VIEW_KEY);
  }
}
