import React, {Component} from 'react';
import {Localization} from '../../../../localization/localization';
import {TextButton} from '@wf-titan/react-button';
import NotificationStore from '../../../stores/NotificationStore';

export default class UniqueUrlGeneratorContent extends Component {
  props: {
    generatedLink: String,
  };

  static defaultProps = {
    generatedLink: '',
  };

  selectTextInput = () => {
    this.textInput.select();
  };

  componentDidUpdate() {
    this.selectTextInput();
  }

  copyHandler = () => {
    this.selectTextInput();
    try {
      document.execCommand('copy');
      NotificationStore.showNotification(<Localization messageKey={'share.copied'}/>, 'success', 2000);
    } catch (error) {
      NotificationStore.showNotification(error.message, 'error', 2000);
    }

  };

  render() {
    return (
      <div className="unique-url-content">
        <input value={this.props.generatedLink} ref={input => this.textInput = input} className="unique-url-input" readOnly={true}/>
        <TextButton onClick={this.copyHandler} className={'unique-url-save-btn'}>
          <Localization messageKey="object.action.share.button.copy"/>
        </TextButton>
      </div>)
  }
}
