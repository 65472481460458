/**
 * Created by anihambardzumyan on 4/27/17.
 */
import moment from 'moment';
import {observable} from 'mobx';
import { safeAPIDateToDate } from '../../shared/services/Utilities';

export default class IntervalsModel {
  from;
  to;
  current;
  fth;
  maxPoint;

  @observable chartData = {
    sumAVL: null,
    sumPLN: null,
    highlight: false,
    isHighlightPositive: false,
    showChartWarningIcon: false,
    warningIconClicked: false,
    loading: true
  };

  constructor(from, to, fth) {
    this.from = from;
    this.to = to;
    this.fth = fth;
    this.current = moment(safeAPIDateToDate(this.from)) <= moment() && moment() <= moment(safeAPIDateToDate(this.to));
  }

  clearChartHighlight() {
    this.chartData.highlight = false;
    this.chartData.warningIconClicked = false;
  }
}
