/**
 * Created by artakpoghosyan on 9/5/17.
 */
const ExcelLimitationEnum = {
  rows: 1048576,
  columns: 16384
};

const ExportPeriodLimitationEnum = {
  max: {
    week: 52,
    month: 36,
    quarter: 12
  },
  min: 1,
  minDate: '2000-01-01',
  defaultValue: 4
};

const PeriodDropDownItems = [
  {messageKey: 'week', key: 'week'},
  {messageKey: 'month', key: 'month'},
  {messageKey: 'quarter', key: 'quarter'}
];

const RAW = 'raw';
const GROUPED = 'grouped';

export {
  ExcelLimitationEnum,
  ExportPeriodLimitationEnum,
  PeriodDropDownItems,
  RAW,
  GROUPED
};
