/**
 * Created by artakpoghosyan on 7/23/18.
 */
import React from 'react';
import PropTypes from 'prop-types';

export const ExportDialogErrorMessage = (props) => {
  return (
    <div>
      {
        props.condition &&
        (<p className="export-dialog-error-message">
          {props.message}
        </p>)
      }
    </div>
  );
};

ExportDialogErrorMessage.propTypes = {
  message: PropTypes.object.isRequired,
  condition: PropTypes.bool.isRequired,
};

