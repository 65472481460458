// Libraries
import {observable} from 'mobx';
// Models
import ObjectModel from './ObjectModel';
// Constants
import {User as UserObjCode} from 'workfront-objcodes';
// Services
import {isNodeIdDefined} from '../../../shared/services/Utilities';
// Stores
import {intervalsFTEs} from '../../../shared/stores/IntervalsStore';
import {COST_VIEW_KEY, FTE_VIEW_KEY} from '../../../shared/constants/ViewEnums';

export default class ObjectUserModel extends ObjectModel {
  @observable prevProjectUserModel;
  avatarDownloadURL;

  constructor(ID, name, roleID, prevHour, editBudgeting = true) {
    super(UserObjCode, ID, name, false);

    this.avatarDownloadURL = `/internal/user/avatar?ID=${ID}&size=SMALL&time=${new Date().getTime()}`;

    //hour
    this.prevProjectUserModel = prevHour;
    this.showOnlyPlannedHour = !isNodeIdDefined(roleID);
    this.editBudgeting = editBudgeting
  }

  setBDGHour(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = this.getCostRate(i) ? value * intervalsFTEs.getFTE(i) / this.getCostRate(i) : 0;
  }

  setBDGFTE(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);
  }

  setBDGCost(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = this.getCostRate(i, COST_VIEW_KEY) ? value / this.getCostRate(i, COST_VIEW_KEY) : 0;
  }

  setPBDGHour(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }

    const PBDG = value * intervalsFTEs.getFTE(i);

    this[i].chgPbdg = true;
    this[i]._PBDG = PBDG;
  }

  setPBDGFTE(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }

    const PBDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);

    this[i].chgPbdg = true;
    this[i]._PBDG = PBDG;
  }

  setPBDGCost(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }

    this[i].chgPbdg = true;
    this[i]._PBDG = value;
  }

  setPBDGHourFromRole(value, i, isAllNodesLoaded) {
    if (value === this[i]._PBDG && value === this[i]._initialPBDG) {
      return;
    }

    const PBDG = value * intervalsFTEs.getFTE(i);
    this[i].chgPbdg = isAllNodesLoaded;
    this[i]._initialPBDG = PBDG;
    this[i]._PBDG = PBDG;
  }

  setPBDGFTEFromRole(value, i, isAllNodesLoaded) {
    if (value === this[i]._PBDG && value === this[i]._initialPBDG) {
      return;
    }

    const PBDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);
    this[i].chgPbdg = isAllNodesLoaded;
    this[i]._initialPBDG = PBDG;
    this[i]._PBDG = PBDG;
  }

  setPBDGCostFromRole(value, i, isAllNodesLoaded) {
    if (value === this[i]._PBDG && value === this[i]._initialPBDG) {
      return;
    }

    const PBDG = value;
    this[i].chgPbdg = isAllNodesLoaded;
    this[i]._initialPBDG = PBDG;
    this[i]._PBDG = PBDG;
  }
}
