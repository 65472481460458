import React, {Component} from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {HOUR_ROW_HEIGHT} from '../../constants/StyleConstants';
import TreeNodeItemLoading from './TreeNodeItemLoading';

@observer
export default class TreeNodeContainerLoading extends Component {
//noinspection JSAnnotator
  props: {
    height: Number
  };

  constructor(props) {
    super(props);
  }

  render() {
    let loadingElementList = [];
    const contentRepeatCount = Math.floor(this.props.height / HOUR_ROW_HEIGHT);

    _.times(contentRepeatCount, (key) => {
      loadingElementList.push(
        <TreeNodeItemLoading key={key} />
      );
    });

    return (
      <div className="node-collection-container node-collection-container-loading">
        <div className="left-info-space">
          <div className="empty-space-top"></div>
          <div className="empty-space-bottom"></div>
        </div>
        <div className="node-collection">
          {loadingElementList}
        </div>
      </div>
    );
  }
}
