import {computed, observable} from 'mobx';
import _ from 'lodash';
import IntervalsModel from '../models/IntervalsModel';
import ViewModeStore from './ViewModeStore';
import {DATE_FORMAT} from '../constants/MonthsEnum';
import moment from 'moment';
import {NextPreviousEnum} from '../constants/NextPreviousEnum';
import {HOUR_VIEW_KEY, HourViewAttributes} from '../constants/ViewEnums';

export const intervalsFTEs = {
  FTEsPerInterval: {},
  getFTE: (index, metric = HOUR_VIEW_KEY) => computed(() => {
    if (ViewModeStore.isCustomViewMode) {
      return HourViewAttributes[metric] && HourViewAttributes[metric].fteCoefficient ? HourViewAttributes[metric].fteCoefficient : intervalsFTEs.FTEsPerInterval[index];
    } else {
      return ViewModeStore.hourViewMode.fteCoefficient || intervalsFTEs.FTEsPerInterval[index];
    }
  }),
  removeFTEs: (deleteIntervals) => {
    if(deleteIntervals) {
      deleteIntervals.forEach(({index}) => {
        delete intervalsFTEs.FTEsPerInterval[index];
      });
    }
  },
  addFTEs: (intervals, intervalsIndexes) => {
    intervals.forEach((interval) => {
      let index = _.find(intervalsIndexes, (item) => item.from === interval.from).index;
      if (!intervalsFTEs.FTEsPerInterval[index]) {
        intervalsFTEs.FTEsPerInterval[index] = interval.fth;
      }
    });
  },
  clearFTEs: () => {
    intervalsFTEs.FTEsPerInterval = {};
  }
};

export default class IntervalsStore {
  @observable intervals = [];
  constructor(fromDate = null) {
    this.visibleIntervalsFrom = fromDate || this.getCurrentDate().format(DATE_FORMAT);
  }

  getCurrentDate() {
    return moment(new Date()).locale('en').startOf(ViewModeStore.activeStep.key);
  }

  addHourIntervals(intervals) {
    intervals.forEach((interval, index) => {
      this.intervals.push(new IntervalsModel(interval.from, interval.to, index));
    });
    intervalsFTEs.addFTEs(intervals, this.intervals);
  }

  getIntervalsForDelete(nextPrevious, stepCount) {
    let deleteIntervals = [], count, startIndex;

    if (nextPrevious === NextPreviousEnum.next) {
      startIndex = 0;
      count = stepCount;
    } else {
      startIndex = this.intervals.length - stepCount;
      count = this.intervals.length;
    }

    for (let i = startIndex; i < count; i++) {
      deleteIntervals.push({from: this.intervals[i].from, index: this.intervals[i].index});
    }

    return deleteIntervals;
  }

  addRemoveIntervals(nextPrevious, fromDate, stepCount) {
    //let newIndexes = [];
    let newIndex;
    let methodRemove;
    let methodAdd;


    if (nextPrevious === NextPreviousEnum.next) {
      newIndex = stepCount === ViewModeStore.intervalsCount ? 0 : this.intervals[this.intervals.length - 1].index;
      methodRemove = 'shift';
      methodAdd = 'push';
    } else {
      newIndex = this.intervals[0].index;
      methodRemove = 'pop';
      methodAdd = 'unshift';
    }

    for (let i = 1; i <= stepCount; i++) {
      fromDate = fromDate.add(nextPrevious, ViewModeStore.activeStep.key);
      let toStr = fromDate.clone().add(1, ViewModeStore.activeStep.key).format(DATE_FORMAT);

      newIndex += nextPrevious;

      this.intervals[methodRemove]();
      this.intervals[methodAdd](new IntervalsModel(fromDate.format(DATE_FORMAT), toStr, newIndex));
    }
  }

  clearIntervalStore() {
    this.intervals = [];
    intervalsFTEs.clearFTEs();
  }
}
