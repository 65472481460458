import {observable, autorun} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import ViewModeStore from '../../shared/stores/ViewModeStore';
import SettingsStore from '../../shared/stores/SettingsStore';
import {User as UserObjCode, Project as ProjectObjCode} from 'workfront-objcodes';
import {isNodeIdDefined, regExpForID} from '../../shared/services/Utilities';
//Services
import DataServiceUtilities from '../../shared/services/DataServiceUtilities';
import projectViewDataService from '../services/DataService';
//Constants
import {IDExpOptions} from '../../shared/constants/ExpressionOptions';
import {viewLimits} from '../../shared/constants/LimitConstants';

export const filteredOutStore = {
  filteredOutBudgets: {},
  filteredOutProjectIDs: {},

  removeFilteredOutBudgets: (intervals) => {
    _.forEach(intervals, ({from}) => {
      if (filteredOutStore.filteredOutBudgets[from]) {
        delete filteredOutStore.filteredOutBudgets[from];
      }
    });
  },

  addFilteredOutBudgets: (filteredOutBudgets, lastLoadedProjectPriority, filteredOutProjects) => {
    _.forEach(filteredOutProjects, (IDs, index) => {
      filteredOutStore.filteredOutProjectIDs[+index + lastLoadedProjectPriority] = IDs;
    });

    _.forEach(filteredOutBudgets, (items, fromDate) => {
      _.forEach(items, (budgets, index) => {
        filteredOutStore.filteredOutBudgets[fromDate] = filteredOutStore.filteredOutBudgets[fromDate] || {};
        filteredOutStore.filteredOutBudgets[fromDate][+index + lastLoadedProjectPriority] = budgets;
      });
    });
  },

  getHigherIndexProjectIDs: (projectIndex) => {
    return _.reduce(filteredOutStore.filteredOutProjectIDs, (projectIDs, IDs, index) => {
      if (+index <= projectIndex) {
        projectIDs.push(...IDs);
      }
      return projectIDs;
    }, []);
  },

  getFilteredOutBdgPbdg: (userID, roleID, intervalFrom, projectPriority) => {
    let result = {
      BDG: 0,
      PBDG: 0
    };

    let filteredOutBudgets = filteredOutStore.filteredOutBudgets[intervalFrom] &&
      filteredOutStore.filteredOutBudgets[intervalFrom][projectPriority] &&
      filteredOutStore.filteredOutBudgets[intervalFrom][projectPriority][roleID] &&
      filteredOutStore.filteredOutBudgets[intervalFrom][projectPriority][roleID][userID];

    if (filteredOutBudgets) {
      result.BDG = filteredOutBudgets.BDG;
      result.PBDG = filteredOutBudgets.PBDG;
    }

    return result;
  },

  clearFilteredOutStore: () => {
    filteredOutStore.filteredOutBudgets = {};
    filteredOutStore.filteredOutProjectIDs = {}
  }
};

export default class TreeNodesStore {
  nodes = [];
  projectIDs = [];
  //contains visible intervals object {from, index}
  intervalsIndexes = [];
  //project priorities array how is stored in db
  projectsPriorities = [];
  changedBDGHours = {};
  lazyLoadOffset = 0;
  @observable nodesIDs = [];
  @observable continueLazyLoading = true;
  @observable isSortReseted;

  constructor() {
    this.getHigherPriorities = this.getHigherPriorities.bind(this);
    this.getChangedBudgetedHourForDateAdjustment = this.getChangedBudgetedHourForDateAdjustment.bind(this);

    autorun(() => {
      this.projectIDs = _.reduce(this.nodesIDs.filter((ID) => ID != null && ID.toString().indexOf('_') === -1), (obj, ID, index) => {
        obj[ID] = index;
        return obj;
      }, {});
    });
  }

  /**
   * Creates node and hour models for the nodes
   * Shows notification when reaching the limits both for rows and project counts
   */
  addNodes(result, lastLoadedProjectPriority = 0) {
    this.nodes.push(...projectViewDataService.getNodeObjects(result.items, result.intervals, this.intervalsIndexes, lastLoadedProjectPriority, result.editBudgeting));
    this.updateAvailableAndFilteredOutHoursByPriority(this.nodes);

    result.items.forEach(({ID, priority}) => {
      this.nodesIDs.push(ID);
      this.projectsPriorities.push(lastLoadedProjectPriority + priority);
    });

    if (this.nodes.length >= viewLimits.project.firstLevelLimit) {
      this.nodesIDs.push(IDExpOptions.notification);
    } else if (result.clipped) {
      this.nodesIDs.push(IDExpOptions.notificationRows);
    }

    if (!result.hasMore || result.clipped || this.nodes.length >= viewLimits.project.firstLevelLimit) {
      this.continueLazyLoading = false;
    }
  }

  addLoadingRow() {
    this.nodesIDs.push(IDExpOptions.loading);
  }

  deleteLoadingRow() {
    let loadingIndex = this.nodesIDs.indexOf(`${IDExpOptions.loading}`);

    if(loadingIndex !== -1) {
      this.nodesIDs.splice(loadingIndex, 1);
    }
  }

  /**
   * Insert sub node IDs separated with '_' from the expression into nodeIDs array at appropriate index
   * IDExp format is {projID}, {projID_roleID}
   */
  insertSubNodes(IDExp) {
    const start = this.nodesIDs.indexOf(IDExp) + 1;
    let nodesIDs;
    const levels = IDExp.split('_');
    if (levels.length === 1) {
      nodesIDs = _.find(this.nodes, ({ID}) => ID === levels[0]).nodes.map(({ID}) => `${IDExp}_${ID}`);
    } else if (levels.length === 2) {
      nodesIDs = _.find(_.find(this.nodes, ({ID}) => ID === levels[0]).nodes, ({ID}) => ID === levels[1]).nodes.map(({ID}) => `${IDExp}_${ID}`);
    }
    this.nodesIDs.splice(start, 0, ...nodesIDs);
  }

  deleteSubNodes(IDExp, deleteSelf) {
    let regExp, start,
      deleteCount = 0,
      length = this.nodesIDs.length;

    if (deleteSelf) {
      regExp = regExpForID(IDExp);
      start = this.nodesIDs.indexOf(IDExp);
    } else {
      regExp = regExpForID(IDExp + '_');
      start = this.nodesIDs.indexOf(IDExp) + 1;
    }

    for (let i = start; i < length; i++) {
      let nodeIDExp = this.nodesIDs[i];

      if (!regExp.test(nodeIDExp)) {
        break;
      }

      const levels = nodeIDExp.split('_');

      if (levels.length === 2) {
        _.find(_.find(this.nodes, ({ID}) => ID === levels[0]).nodes, ({ID}) => ID === levels[1]).toggled = false;
      }

      deleteCount++;
    }
    return this.nodesIDs.splice(start, deleteCount);
  }

  /**
   * Return empty object when no priority has changed
   * otherwise returns changed priority projects new priority
   */
  getChangedPriorities() {
    let changedPriorities = {};

    this.nodes.forEach(({newPriority, initialPriority, ID, hasEmptyPriority}) => {
      if (newPriority !== initialPriority || (newPriority === initialPriority && hasEmptyPriority)) {
        changedPriorities[ID] = newPriority;
      }
    });

    return changedPriorities;
  }

  /**
   * Equates new priority to initial priority
   */
  resetProjectPriorities() {
    this.nodes.forEach(project => {
      project.initialPriority = project.newPriority;
      project.hasEmptyPriority = false;
    });
  }

  updatePriorities(oldIndex, newIndex, projectID) {
    const deletedProjectWithSubNodes = this.deleteSubNodes(projectID, true),
      _newIndex = newIndex >= this.nodes.length ? this.nodes.length - 1 : newIndex;
    let newIndexNodeIDs;

    if (oldIndex < _newIndex) {
      newIndexNodeIDs = _.findLastIndex(this.nodesIDs, ID => ID === this.nodes[_newIndex].ID) + 1;
    } else if (oldIndex > _newIndex) {
      newIndexNodeIDs = _.findIndex(this.nodesIDs, ID => ID === this.nodes[_newIndex].ID);
    }

    if (this.nodes[_newIndex].toggled) {
      if (oldIndex < _newIndex) {
        newIndexNodeIDs += this.nodes[_newIndex].nodes.length;

        this.nodes[_newIndex].nodes.map(node => {
          if (node.toggled) {
            newIndexNodeIDs += node.nodes.length;
          }
        })
      }
    }

    this.nodesIDs.splice(newIndexNodeIDs, 0, ...deletedProjectWithSubNodes);

    //nodes update
    let removedProject = this.nodes.splice(oldIndex, 1)[0];
    let distance = Math.abs(_newIndex - oldIndex);

    this.nodes.splice(_newIndex, 0, removedProject);

    this.nodes.map((node, index) => {
      node.newPriority = this.projectsPriorities[index];
    });

    this.updateAvailableAndFilteredOutHoursByPriority(this.nodes);

    return distance;
  }

  updateAvailableAndFilteredOutHoursByPriority(nodes, parentNodeID = null, roles = {}, projectPriority = null) {
    nodes.forEach((node) => {
      if (!node) {
        return;
      }

      if (node.objCode === UserObjCode) {
        //Adding filteredOut BDG/PBDG for user node
        this.intervalsIndexes.forEach(({from, index}) => {
          let filteredOutBudgets = filteredOutStore.getFilteredOutBdgPbdg(node.ID, parentNodeID, from, projectPriority);
          node[index].filteredOutBDG = filteredOutBudgets.BDG;
          node[index].filteredOutPBDG = filteredOutBudgets.PBDG;
        });

        node.prevProjectUserModel = (roles[parentNodeID] && roles[parentNodeID][node.ID]) || null;

        if (!roles[parentNodeID]) {
          roles[parentNodeID] = {};
        }
        roles[parentNodeID][node.ID] = node;
      } else {

        if (node.objCode === ProjectObjCode) {
          projectPriority = node.newPriority;
        }

        this.updateAvailableAndFilteredOutHoursByPriority(node.nodes, node.ID, roles, projectPriority);
      }
    });
  }

  /**
   * Returns empty object if there is no projects before it
   * Return array of project IDs before it
   */
  getHigherPriorities(projectID) {
    let higherPriorities = [];

    _.forEach(this.nodes, ({ID}) => {
      if (ID === projectID) {
        return false;
      }
      higherPriorities.push(ID);
    });

    //return isPriorityChangedOrNull ? higherPriorities : null;
    //should always send higher priorities (BackEnd side need it every time)
    return higherPriorities;
  }

  removeIntervalHours(intervalIndexes, nodes) {
    nodes.forEach(node => {
      intervalIndexes.forEach(({index}) => {
        delete node[index];
      });

      if (node.nodes) {
        this.removeIntervalHours(intervalIndexes, node.nodes);
      }
    });
  }

  fillHours(intervals) {
    intervals.forEach((interval) => {
      _.forEach(interval.hours, (projectHour, projectID) => {
        let projectNode = this.nodes[this.projectIDs[projectID]];

        let index = DataServiceUtilities.getIndex(interval.from, this.intervalsIndexes);
        projectViewDataService.fillProjectNodeHours(projectNode, projectHour, index);

        projectNode.nodes.forEach(roleNode => {
          let roleHour = interval.hours[projectNode.ID][roleNode.ID];
          projectViewDataService.fillRoleNodeHours(roleNode, roleHour, index);

          roleNode.nodes.forEach(userNode => {
            let userHour = interval.hours[projectNode.ID][roleNode.ID][userNode.ID];
            let changedBDG = null, changedPBDG = null;
            let changedData = this.changedBDGHours[interval.from] &&
              this.changedBDGHours[interval.from][projectNode.ID] &&
              this.changedBDGHours[interval.from][projectNode.ID][roleNode.ID] &&
              this.changedBDGHours[interval.from][projectNode.ID][roleNode.ID][userNode.ID];

            if (changedData) {
              changedBDG = changedData.BDG;
              changedPBDG = changedData.PBDG;
            }
            let filteredOutBudgets = filteredOutStore.getFilteredOutBdgPbdg(userNode.ID, roleNode.ID, interval.from, projectNode.newPriority);
            projectViewDataService.fillUserNodeHours(userNode, userHour, index, changedBDG, changedPBDG);
            userNode[index].filteredOutBDG = filteredOutBudgets.BDG;
            userNode[index].filteredOutPBDG = filteredOutBudgets.PBDG;
          });
        });
      });
    });
  }

  /**
   * Stores changed BDG and PBDG hour per role/user /interval into changedBDGHours property
   */
  storeChangedBDGHours(intervals) {
    this.nodes.forEach(projectNode => {
      projectNode.nodes.forEach(roleNode => {
        roleNode.nodes.forEach(userNode => {
          _.forEach(intervals, ({from, index}) => {

            if (userNode[index].chgBdg || userNode[index].chgPbdg) {

              this.changedBDGHours[from] = this.changedBDGHours[from] || {};
              this.changedBDGHours[from][projectNode.ID] = this.changedBDGHours[from][projectNode.ID] || {};
              this.changedBDGHours[from][projectNode.ID][roleNode.ID] = this.changedBDGHours[from][projectNode.ID][roleNode.ID] || {};

              this.changedBDGHours[from][projectNode.ID][roleNode.ID][userNode.ID] = {
                BDG: userNode[index]._BDG,
                PBDG: userNode[index]._PBDG
              };
            }
          });
        });
      });
    });
  }

  /**
   * set empty object to changedBDgHours
   * and update flag that indicates whether user bdgHour is changed to false
   */
  emptyChangedBDGHours() {
    this.changedBDGHours = {};

    this.nodes.forEach(projectNode => {
      projectNode.nodes.forEach(roleNode => {
        roleNode.nodes.forEach(userNode => {
          this.intervalsIndexes.forEach(({index}) => {
            userNode[index].chgBdg = false;
            userNode[index].chgPbdg = false;
          });
        });
      });
    });
  }

  getChangedBudgetedHourByIntervalsForSave(intervals) {
    this.storeChangedBDGHours(intervals);

    const data = {budgetedHours: []};

    _.forEach(this.changedBDGHours, (intervalHours, from) => {
      data.budgetedHours.push({
        hours: intervalHours,
        from,
        to: moment(from).add(1, ViewModeStore.activeStep.key).format(ViewModeStore.dateFormat)
      });
    });

    return data;
  }

  getChangedBudgetedHourForDateAdjustment(higherPriorities, projectID) {
    this.storeChangedBDGHours(this.intervalsIndexes);

    const projectIDs = [...higherPriorities],
      budgetedHoursByIntervals = [];

    if (projectID) {
      projectIDs.push(projectID)
    }
    _.forEach(this.changedBDGHours, (intervalHours, from) => {
      const hoursByHigherPriorities = (_.pickBy || _.pick)(intervalHours, (value, ID) => projectIDs.indexOf(ID) !== -1);

      if (!_.isEmpty(hoursByHigherPriorities)) {
        budgetedHoursByIntervals.push({
          hours: hoursByHigherPriorities,
          from: moment(from).format(ViewModeStore.dateFormat),
          to: moment(from).add(1, ViewModeStore.activeStep.key).format(ViewModeStore.dateFormat)
        });
      }
    });


    return budgetedHoursByIntervals;
  }

  setPlannedHoursToBudgeted(projectID, roleID) {
    const project = _.find(this.nodes, ({ID}) => ID === projectID);

    if (roleID) {
      const role = _.find(project.nodes, ({ID}) => ID === roleID);

      role.nodes.forEach(user => {
        this.intervalsIndexes.forEach(({index}) => {
          if (user[index].PLNHour !== 0 || (user[index].PLNHour === 0 && user[index].BDGHour !== 0)) {
            user.setBDGHour(user[index].PLNHour, index);
          }
        });
      });
    } else {
      project.nodes.forEach(role => {
        if (isNodeIdDefined(role)) {
          this.intervalsIndexes.forEach(({index}) => {
            if (role[index].PLNHour !== 0 || (role[index].PLNHour === 0 && role[index].BDGHour !== 0)) {
              role.setBDGHour(role[index].PLNHour, index);
            }
          });
        }
      });
    }
  }

  equateBudgetedFromUser(projectID, roleID) {
    const role = _.find(_.find(this.nodes, ({ID}) => ID === projectID).nodes, ({ID}) => ID === roleID);

    this.intervalsIndexes.forEach(({index}) => {
      role.setBDGHour(role[index].BDGUserHour, index);
    });
  }

  get unSavedChangesExists() {
    return ViewModeStore.priorityChanged || this.hasChangedBDGHours();
  }

  get showGlobalPriorities() {
    return SettingsStore.options['resource_planner.globalPriorities'].value || (ViewModeStore.projectID && this.nodes[0].globalPriority !== null)
  }

  hasChangedBDGHours() {
    if (!_.isEmpty(this.changedBDGHours)) {
      return true;
    }

    let hasChangeBdg = false;

    _.forEach(this.nodes, projectNode => { // project level
      if (hasChangeBdg) {
        return false;
      }

      projectNode.nodes.forEach(roleNode => { // role level
        if (hasChangeBdg) {
          return false;
        }

        roleNode.nodes.forEach(userNode => { // user level
          if (hasChangeBdg) {
            return false;
          }

          this.intervalsIndexes.forEach(item => {
            if (userNode[item.index].chgBdg || userNode[item.index].chgPbdg) {
              hasChangeBdg = true;
              return false;
            }
          });
        });
      });
    });

    return hasChangeBdg;
  }

  getNodesCount() {
    let projectsLength = this.nodes.length;

    if (projectsLength === 0) {
      return 0;
    }

    return this.nodes.reduce((projectNodesLength, project) => {
      return projectNodesLength + project.nodes.reduce((roleNodesLength, role) => {
        return roleNodesLength + role.nodes.length;
      }, project.nodes.length);
    }, projectsLength);
  }

  clearTreeNodesStore() {
    this.nodes = [];
    this.projectIDs = [];
    this.intervalsIndexes = [];
    this.projectsPriorities = [];
    this.changedBDGHours = {};
    this.lazyLoadOffset = 0;
    this.nodesIDs = [];
    this.continueLazyLoading = true;
    filteredOutStore.clearFilteredOutStore();
  }
}
