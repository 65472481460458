/**
 * Created by anihambardzumyan on 5/23/17.
 */
import NotificationStore from '../stores/NotificationStore';
import {loadingStore} from '../stores/LoadingStore';
import {notify} from './Utilities';

const ResponseHandler = {
  success: (response) => {
    if (response.ok) {
      return response.json().then(
        (data) => {
          if (data.error) {
            NotificationStore.showNotification(data.error.message, 'error');
            loadingStore.loading = false;
            loadingStore.isLazyLoadingMode = false;

            throw {
              status: response.status,
              message: data.error.message
            };
          }

          return data;
        }
      )
    }
    else {
      return response.json().then(
        (data) => {
          NotificationStore.showNotification(data.error.message, 'error');
          loadingStore.loading = false;
          loadingStore.isLazyLoadingMode = false;

          throw {
            status: response.status,
            message: data.error.message
          };
        },
        () => {
          notify.stop();
          NotificationStore.showNotification(response.statusText, 'error');

          throw {
            status: response.status,
            message: response.statusText
          };
        }
      )
    }
  },
  failure: (err) => {
    NotificationStore.showNotification(err.message || err.statusText, 'error');

    loadingStore.loading = false;
    loadingStore.isLazyLoadingMode = false;

    throw {
      message: err.message || err.statusText
    };
  }
};

export {
  ResponseHandler
};
