// Libraries
import {observable} from 'mobx';
import _ from 'lodash';
// Stores
import {intervalsFTEs} from '../../../shared/stores/IntervalsStore';
// Constants
import {Role as RoleObjCode} from 'workfront-objcodes';
// Models
import ObjectModel from './ObjectModel';
// Services
import {isNodeIdDefined} from '../../../shared/services/Utilities';
import {COST_VIEW_KEY, FTE_VIEW_KEY} from '../../../shared/constants/ViewEnums';


export default class ObjectRoleModel extends ObjectModel {
  @observable showMore = true;
  @observable isInLoadingMode = false;
  @observable nodes;

  constructor(ID, name, nodes, hasUserNodes, editBudgeting = true) {
    super(RoleObjCode, ID, name, true);

    this.offset = 0;
    this.nodes = nodes;
    this.showEquateBudgetedFromUserLink = false;
    this.showSetPlannedHourToBudgetedLink = editBudgeting && hasUserNodes && isNodeIdDefined(ID);
    this.showThreeDots = this.showSetPlannedHourToBudgetedLink;
    this.showOnlyPlannedHour = !hasUserNodes || !isNodeIdDefined(ID);
    this.editBudgeting = editBudgeting;
  }

  calculateBDG(value, i, node) {
    if (this[i].BPLNHour === 0) { //all nodes plnHour is 0
      return value / this[i].notEmptyNodesCount;
    } else {
      return value * (node[i].PLNHour / this[i].BPLNHour);
    }
  }

  calculateBDGFTE(value, i, node) {
    if (this[i].BPLNFTE === 0) { //all nodes plnHour is 0
      return value / this[i].notEmptyNodesCount;
    } else {
      return value * (node[i].PLNFTE / this[i].BPLNFTE);
    }
  }

  calculateBDGCost(value, i, node) {
    if (this[i].BPLNCost === 0) { //all nodes plnHour is 0
      return value / this[i].notEmptyNodesCount;
    } else {
      return value * (node[i].PLNCost / this[i].BPLNCost);
    }
  }

  setBDGHour(value, i) {
    this[i].chg = this.showMore;
    this[i].isSetPLN = false;
    //value convert to hour for roleBDG
    this[i].BDG = this.getCostRate(i) ? value * intervalsFTEs.getFTE(i) / this.getCostRate(i) : 0;
    this.nodes.map(node => {
      if (!node.showOnlyPlannedHour && node.editBudgeting ) {
        node.setPBDGHourFromRole(this.calculateBDG(value, i, node), i, !this.showMore);
      }
    });
  }

  setBDGFTE(value, i) {
    this[i].chg = this.showMore;
    this[i].isSetPLN = false;

    this[i].BDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);
    this.nodes.map(node => {
      if (!node.showOnlyPlannedHour && node.editBudgeting ) {
        node.setPBDGFTEFromRole(this.calculateBDGFTE(value, i, node), i, !this.showMore);
      }
    });
  }

  setBDGCost(value, i) {
    this[i].chg = this.showMore;
    this[i].isSetPLN = false;
    //value convert to hour for roleBDG
    this[i].BDG = this.getCostRate(i, COST_VIEW_KEY) ? value / this.getCostRate(i, COST_VIEW_KEY) : 0;
    this.nodes.map(node => {
      if (!node.showOnlyPlannedHour && node.editBudgeting ) {
        node.setPBDGCostFromRole(this.calculateBDGCost(value, i, node), i, !this.showMore);
      }
    });
  }

  setPLNToBDG(i) {
    this[i].chg = false;
    this[i].isSetPLN = true;
    //value convert to hour for roleBDG
    this[i].BDG = this[i].BPLN;

    this.nodes.map(node => {
      if (!_.isEmpty(node[i]) && node.editBudgeting) { //TODO think about this condition && (node[i].PLNHour !== 0 || (node[i].PLNHour === 0 && node[i].BDGHour !== 0)) it needs for not sending unchanged 0 BDG
        node.setPBDGHourFromRole(node[i].PLNHour, i, !this.showMore);
      }
    });
  }
}
