/**
 * Created by anihambardzumyan on 4/17/17.
 */
import {COST_VIEW_KEY, FTE_VIEW_KEY, HOUR_VIEW_KEY} from './ViewEnums';

const HourKeys = {
  'project': [
    'AVL',
    'PLN',
    'BDG',
    'VAR',
    'NET'
  ], 'user': [
    'AVL',
    'PLN',
    'ACT',
    'NET',
    'PCT'
  ]
};

const HourMetrics = {
  'project': [
    HOUR_VIEW_KEY,
    FTE_VIEW_KEY,
    COST_VIEW_KEY
  ], 'role': [
    HOUR_VIEW_KEY,
    FTE_VIEW_KEY,
    COST_VIEW_KEY
  ], 'user': [
    HOUR_VIEW_KEY,
    FTE_VIEW_KEY
  ]
};

const HourHeaders = {
  project: {
    AVL: {
      name: 'resourceplanner.available.abbr',
      displayName: 'resourceplanner.available',
      description: 'resourceplanner.available.description',
      displayOrder: 0
    },
    PLN: {
      name: 'resourceplanner.planned.abbr',
      displayName: 'resourceplanner.planned',
      description: 'resourceplanner.planned.description',
      displayOrder: 1
    },
    BDG: {
      name: 'resourceplanner.budgeted.abbr',
      displayName: 'resourceplanner.budgeted',
      description: 'resourceplanner.budgeted.description',
      displayOrder: 2
    },
    VAR: {
      name: 'resourceplanner.variance.abbr',
      displayName: 'resourceplanner.variance',
      description: 'resourceplanner.variance.description',
      displayOrder: 3
    },
    NET: {
      name: 'resourceplanner.net.abbr',
      displayName: 'resourceplanner.net',
      description: 'resourceplanner.net.description',
      displayOrder: 4
    }
  },
  user: {
    AVL: {
      name: 'resourceplanner.available.abbr',
      displayName: 'resourceplanner.available',
      description: 'resourceplanner.available.user.view.description',
      displayOrder: 0
    },
    PLN: {
      name: 'resourceplanner.planned.abbr',
      displayName: 'resourceplanner.planned',
      description: 'resourceplanner.planned.description',
      displayOrder: 1
    },
    ACT: {
      name: 'resourceplanner.actual.abbr',
      displayName: 'resourceplanner.actual',
      description: 'resourceplanner.actual.description',
      displayOrder: 2
    },
    NET: {
      name: 'resourceplanner.diff.abbr',
      displayName: 'resourceplanner.diff',
      description: 'resourceplanner.diff.description',
      displayOrder: 3
    },
    PCT: {
      name: 'percent.abbr',
      displayName: 'percent',
      description: 'resourceplanner.percent.description',
      displayOrder: 4
    }
  }
};

HourHeaders.role = HourHeaders.project;
HourKeys.role = HourKeys.project;

export {HourKeys, HourHeaders, HourMetrics};
