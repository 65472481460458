/**
 * Created by anihambardzumyan on 8/18/17.
 */
import {observable} from 'mobx';

export default {
  options: {
    'resource_planner.issues': {
      @observable value: false,
      @observable toggleValue: false,
      mainMessageKey: 'resourceplanner.include.issue.hours',
      secondaryMessageKey: 'resourceplanner.include.issue.hours.description'
    },

    'resource_planner.showActualHours': {
      @observable value: false,
      @observable toggleValue: false,
      mainMessageKey: 'resourceplanner.show.actual.hours',
      secondaryMessageKey: 'resourceplanner.show.actual.hours.description'
    },

    'resource_planner.globalPriorities': {
      @observable value: false,
      @observable toggleValue: false,
      mainMessageKey: 'resourceplanner.display.global.priorities',
      secondaryMessageKey: 'resourceplanner.display.global.priorities.description'
    }
  },

  customColumnOptions: {
    'resource_planner.customColumns': {
      value: ({
        project: {},
        role: {},
        user: {}
      }),
      @observable updates: ({
        project: {},
        role: {},
        user: {}
      }),
    }
  },
};
