import React, {Component} from 'react';
import LoadingHeader from './LoadingHeader';
import LoadingBody from './LoadingBody';

export default class IntervalsContainerLoading extends Component {
  //noinspection JSAnnotator
  props: {
    height: Number
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="intervals-container intervals-container-loading">
        <LoadingHeader />
        <LoadingBody height={this.props.height}/>
      </div>
    );
  }
}


