import CloseIcon from 'phoenix-icons/dist/CloseIcon';
import React from 'react';
import PropTypes from 'prop-types';

export const DialogHeader = (props) => {
  const {title, onCancel} = props;
  return (
      <div className={'dialog-header'}>
        <span data-test-id="title" className="dialog-title">
          {title}
        </span>
        <span data-test-id="close-button" id="modal-close-btn" className={'dialog-close-btn'}>
          <CloseIcon onClick={onCancel} />
        </span>
      </div>
  )
}

DialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

