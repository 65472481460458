/**
 * Created by artakpoghosyan on 8/22/18.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {FilterHeader} from 'titan/react-filter-group';
import ScrollStore from '../../stores/ScrollStore';
import {getResourcePlannerArea} from '../../services/Utilities';
import ViewModeStore from '../../stores/ViewModeStore';

@observer
export default class FilterDropDown extends Component {
  //noinspection JSAnnotator
  props: {
    filterStore: Object,
    loadStoreData: Function,
    toggleCallback: Function,
    disabled: Boolean,
    treeNodeStore: Object,
    showSaveDialog: Function
  };

  constructor(props) {
    super(props);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.addNewFilter = this.addNewFilter.bind(this);
  }

  toggleFilter() {
    if (!this.props.filterStore.filterOptions.visible) {
      this.props.filterStore.showFilters();
      this.props.toggleCallback(true);
    } else {
      this.props.filterStore.hideFilters();
      this.props.toggleCallback(false);
    }
  }

  addNewFilter() {
    ScrollStore.scrollStore.filterOpen = true;
    this.toggleFilter();
  }

  render() {
    let {disabled} = this.props;

    return (
      <div className={`filter-dropdown-button rp-has-dropdown ${disabled ? 'disabled' : ''}`} data-wf-popover-container>
        <FilterHeader toggleCallback={this.toggleFilter}
                      addNewFilterCallback={this.addNewFilter}
                      disabled={disabled}
                      openedFrom={getResourcePlannerArea(ViewModeStore)}
        />
      </div>
    );
  }
}

