/**
 * Created by anihambardzumyan on 5/23/17.
 */
import {observable} from 'mobx';
import {toast} from '@phoenix/all';
import {noop} from 'lodash';

const notifications = observable({
  closeFn: noop,
  showNotification: false,
  message: null,
});

const showNotification = (message, type, duration) => {
  notifications.closeFn = toast[type](message, {
    duration, onDismiss: clearNotification
  });
  notifications.showNotification = true;
  notifications.message = message;
};

const clearNotification = () => {
  notifications.closeFn();
  notifications.closeFn = noop;
  notifications.showNotification = false;
  notifications.message = null;
};


export default {
  notifications,
  showNotification,
  clearNotification
}
