export const viewLimits = {
  user: {
    firstLevelLimit: 2000,
    secondLevelLimit: 500,
    loadLimit: 20
  },

  role: {
    firstLevelLimit: 300,
    loadLimit: 20,
    nextPrevLimit: 60
  },

  project: {
    firstLevelLimit: 300,
    loadLimit: 20,
    nextPrevLimit: 60
  },
};
