import GoogleAnalyticsTracker from './GoogleAnalyticsTracker';
import pendoAnalyticsTracker from './PendoAnalytics';

// this category will be used for all events tracked in ResourcePlanner
const ResourcePlannerAnalyticsCategory = 'ResourcePlanner';

const AnalyticsService = {
  getTracker: function () {
    // Use Google Analytics by default.
    // You may want to stub this method in tests to avoid calls to Google Analytics.
    return GoogleAnalyticsTracker
  },

  trackResourcePlannerEvent: function (action, label, value) {
    this.getTracker().trackEvent(ResourcePlannerAnalyticsCategory, action, label, value)
  },

  pendoAnalyticsTracker: function (trackName) {
    return pendoAnalyticsTracker(trackName);
  }
};

export default AnalyticsService;
