/**
 * Created by anihambardzumyan on 4/25/17.
 */
import 'whatwg-fetch';
import NotificationStore from '../stores/NotificationStore';
import {ResponseHandler} from './APISdkUtil';
import {getSessionID, getXSRFToken} from 'workfront-cookie';
import {FILTER_TYPE} from '../constants/FilterOptions';
import {getApi} from 'Api';
import _ from 'lodash';
import { loadingStore } from '../stores/LoadingStore';

const APIService = {
  api: getApi(),
  saveSettings: (name, value) => {
    return APIService.api.create('USERPF', {name, value}).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },
  getSettings: (data) => {
    let requestDataArr = _.map(data, (item, key) => {
      return `name=${key}`
    });

    return APIService.api.request(`USERPF/search?${requestDataArr.join('&')}`).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },
  getProjectPools: (projectID) => {
    return APIService.api.request(`PROJ/search?ID=${projectID}&fields=resourcePools`).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },

  saveProjectPools: (projectID, resourcePoolIDs) => {
    return APIService.api.edit('PROJ', projectID, {resourcePools: resourcePoolIDs}).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },

  getUsersCountInPool: (resourcePoolIDs) => {
    return APIService.api.count('USER', {resourcePoolIDs: resourcePoolIDs, isActive: true}).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },
  getFilterByID(ID) {
    return getApi().search(
      'RPFL',
      {type: FILTER_TYPE, ID},
      'expression',
    );
  },
  getFilters() {
    return getApi().execute('RPFL', null, 'getSharedFiltersByType', {
      type: 'PLANNER'
    });
  },
  getProjectStatuses: () => {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }

    return fetch('/internal/customenum/getMergedStatuses?statusType=STATUS_PROJ', {
      method: 'GET',
      credentials: 'same-origin',
      headers
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    );
  },
  /**
   * **
   * @param url
   * @param verticalFields: can be null for getting single project data
   * @param horizontalFields
   */
  getData(url, verticalFields, horizontalFields) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }

    let body = '';
    body += horizontalFields ? `horizontalFields=${JSON.stringify(horizontalFields)}` : '';
    body += verticalFields ? `&verticalFields=${encodeURIComponent(JSON.stringify(verticalFields))}` : '';

    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    ).then((result) => {
      return result.data;
    }).catch((error) => {
      loadingStore.noResults = true;

      return ResponseHandler.failure(error)
    });
  },
  saveData: (url, data) => {
    let body = `form=${JSON.stringify({data})}`;

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }
    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    )
  },
  saveRoleData: (data, verticalFields = null) => {
    let body = `form=${JSON.stringify({data})}&verticalFields=${JSON.stringify(verticalFields)}`;

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }
    return fetch('/internal/resourceplanner/roleview/save', {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    )
  },

  getBudgetedData: (projectID, step, {higherPriorities, includeBudgetedHours, allocationStartDate, allocationEndDate, changedBudgetedHours}) => {
    const fields = {
      step,
      changedBudgetedHours,
      higherPriorities,
      includeBudgetedHours
    };

    if (allocationStartDate) {
      fields.allocationStartDate = allocationStartDate;
    }

    if (allocationEndDate) {
      fields.allocationEndDate = allocationEndDate;
    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    };

    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }

    let fetchUrl = `/internal/resourceplanner/projectview/adjustment/${projectID}`;

    return fetch(fetchUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body: `fields=${JSON.stringify(fields)}`
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    ).then((result) => {
      return result.data;
    });
  },

  startDownloadExcelProcess: (view, verticalFields, horizontalFields, projectID) => {
    let body = `view=${view}&horizontalFields=${JSON.stringify(horizontalFields)}&verticalFields=${encodeURIComponent(JSON.stringify(verticalFields))}`;

    if (projectID) {
      body += `&projectID=${projectID}`;
    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }
    return fetch('/internal/jobs/startResourcePlannerExport', {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    )
  },

  checkDownloadStatus: (id, objCode) => {
    return APIService.api.get(objCode, id, 'status').catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },

  alignProjects(filters) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    };
    if (getXSRFToken()) {
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }

    let body = '';
    body += filters ? `filters=${JSON.stringify(filters)}` : '';

    return fetch('/internal/resourceplanner/projectview/align', {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    ).then((result) => {
      return ( result.data);
    });
  },

  revertAlignedProjects: () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    };
    if (getXSRFToken()) { //TODO remove condition when AttaskCookieHttpOnlyPitBoss pitboss will be deleted
      headers['X-XSRF-TOKEN'] = getXSRFToken();
    } else {
      headers.sessionID = getSessionID();
    }

    return fetch('/internal/resourceplanner/projectview/revertalign', {
      method: 'POST',
      credentials: 'same-origin',
      headers,
    }).then(
      ResponseHandler.success,
      ResponseHandler.failure
    ).then((result) => {
      return (result.data);
    });
  },

  getAlign: (key) => {
    return APIService.api.request(`USERPF/search?${`name=${key}`}`).catch((error) => {
      NotificationStore.showNotification(error.message, 'error');
      throw error;
    });
  },

};

export default APIService;
