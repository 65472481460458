/**ViewModeStore.urlGeneratorVisible
 * Created by anihambardzumyan on 8/18/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ViewModeStore from '../stores/ViewModeStore';
import {getResourcePlannerArea} from '../services/Utilities';

export const ToggleFormField = ({checked, handleChange, name, children}) => (
  <label
    className="toggle-label"
    data-checked-state={checked}
    data-testid={`${name}-toggle-container-${getResourcePlannerArea(ViewModeStore)}`} >
    <input type="checkbox"
           className="toggle-input"
           id={`${name}-toggle`}
           name={name}
           checked={checked}
           onChange={(event) => handleChange(!checked, event)}/>
    <div className="toggle-switch"></div>
    <div className="text-container">
      {children}
    </div>
  </label>
);


ToggleFormField.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.object
};
