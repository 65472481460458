import ObjectUserModel from '../models/nodes/ObjectUserModel';
import ObjectProjModel from '../models/nodes/ObjectProjModel';
import ObjectRoleModel from '../models/nodes/ObjectRoleModel';
import ObjectTaskIssueModel from '../models/nodes/ObjectTaskIssueModel';
import UserViewHourUserRoleModel from '../models/hours/HourUserRoleModel';
import UserViewHourTaskProjModel from '../models/hours/HourTaskProjModel';

const userViewDataService = {
  createNodeObjectModel: (level, node, intervals, storeIntervals) => {
    //Adding node model
    let nodeIntervalsHours = userViewDataService.createIntervalHours(level, node, intervals, storeIntervals);

    if (level === 0) {
      return new ObjectUserModel(node.ID, node.name, node.expandable, nodeIntervalsHours);
    } else if (level === 1) {
      return new ObjectProjModel(node.ID, node.name, node.plannedStartDate, node.plannedCompletionDate, nodeIntervalsHours);
    } else if (level === 2) {
      return new ObjectRoleModel(node.ID, node.name, nodeIntervalsHours);
    } else if (level === 3) {
      return new ObjectTaskIssueModel(node.objCode, node.ID, node.name, nodeIntervalsHours);
    }
  },

  createIntervalHours(level, node, intervals, storeIntervals) {
    let nodeIntervalsHours = [];

    storeIntervals.forEach((storeInterval, index) => {
      const interval = intervals[index];

      if (!interval) {
        nodeIntervalsHours.push(null);
        return false;
      }

      let hour = interval.hours[node.ID],
        hourModel = null;

      if (hour) {
        if (level === 0 || level === 2) {
          hourModel = new UserViewHourUserRoleModel(hour.AVL, hour.PLN, hour.ACT, storeInterval.fth);
        } else if (level === 1 || level === 3) {
          hourModel = new UserViewHourTaskProjModel(hour.PLN, hour.ACT, storeInterval.fth);
        }
      }

      nodeIntervalsHours.push(hourModel);
    });

    return nodeIntervalsHours
  },

  getUserViewRequestURL(IDExp) {
    let url = '/internal/resourceplanner/userview/users';

    if (IDExp) {
      let IDs = IDExp.split('_');
      url += `/${IDs[0]}/projects`;

      if (IDs.length === 2) {
        url += `/${IDs[1]}/roles`;
      } else if (IDs.length === 3) {
        url += `/${IDs[1]}/roles/${IDs[2]}/works`;
      }
    }

    return url;
  }
};

export default userViewDataService;
