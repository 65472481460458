/**
 * Created by artakpoghosyan on 7/12/18.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import DropDown from '../../DropDown';
import DropDownList from '../../DropDownList';
import {Localization, redrockClient} from '../../../../localization/localization';
import { DatePicker } from '@phoenix/all';
import moment from 'moment';
import {isValidIntegerNumber} from '../../../services/Utilities';
import {
  ExportPeriodLimitationEnum,
  PeriodDropDownItems,
  RAW, GROUPED
} from '../../../constants/ExportToExcelEnum';
import ExportToExcelDialogButtons from './ExportToExcelDialogButtons';
import ViewModeStore from '../../../stores/ViewModeStore';
import _ from 'lodash';
import {ExportDialogErrorMessage} from './ExportDialogErrorMessage';

@observer
export default class ExportToExcelDialogContent extends Component {
  @observable type;
  @observable exportOption;
  @observable outOfLimit = false;
  @observable showDatePicker = false;
  @observable isSelectedDateValid = false;
  @observable fromDate = moment(new Date());
  @observable periodValue = ExportPeriodLimitationEnum.defaultValue;
  @observable dataFormatting = RAW;

  props: {
    filterStore: Object,
    closeDialog: Function,
    showSaveDialog: Function,
    treeNodeStore: Object
  };

  ExportOptionsDropDownItems = [
    {messageKey: `export.first.level.${ViewModeStore.activeView.key}`, key: 'firstLevel', value: 'FIRST_LEVEL'},
    {
      messageKey: `export.first.two.levels.${ViewModeStore.activeView.key}`,
      key: 'firstTwoLevels',
      value: 'FIRST_TWO_LEVELS'
    },
    {messageKey: 'export.everything', key: 'allLevels', value: 'ALL_LEVELS'}
  ];

  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTypeClick = this.handleTypeClick.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleClickOnDatePikerBtn = this.handleClickOnDatePikerBtn.bind(this);
    this.handleCloseDatePicker = this.handleCloseDatePicker.bind(this);
    this.valitadePeriod = this.valitadePeriod.bind(this);
    this.handleRowTypeChange = this.handleRowTypeChange.bind(this);
    this.type = _.find(PeriodDropDownItems, (item) => item.key === ViewModeStore.activeStep.key);
    this.exportOption = this.ExportOptionsDropDownItems[2];

    document.addEventListener('click', this.handleCloseDatePicker);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCloseDatePicker);
  }

  handleCloseDatePicker(e) {
    if (e.target.classList.contains('export-dialog-field-datepicker')) {
      return;
    }

    if (!(e.target.classList.contains('DatePicker') || e.target.closest('.DatePicker'))) {
      this.showDatePicker = false;
    }
  }

  handleClickOnDatePikerBtn() {
    this.showDatePicker = !this.showDatePicker;
  }

  handleDateChange(event) {
    this.fromDate = event;
    this.showDatePicker = false;

    this.isSelectedDateValid = this.fromDate < moment(ExportPeriodLimitationEnum.minDate);
  }

  handleTypeClick(item) {
    this.type = item;
    this.valitadePeriod(this.periodValue);
  }

  handleExportOptionClick = (item) => {
    this.exportOption = item;
  };

  handlePeriodChange(event) {
    const value = event.target.value;

    if (!isValidIntegerNumber(value) || parseInt(value) < ExportPeriodLimitationEnum.min) {
      return;
    }

    this.valitadePeriod(value);
    this.periodValue = value;
  }

  handleRowTypeChange(e) {
    this.dataFormatting = e.target.value;
  }

  valitadePeriod(value) {
    this.outOfLimit = parseInt(value) > ExportPeriodLimitationEnum.max[this.type.key];
  }

  render() {
    const isInvalidInput = this.outOfLimit || !this.periodValue,
      isDisabled = isInvalidInput || this.isSelectedDateValid;

    return (
      <div className="export-to-excel-dialog-content">
        <div className="export-dialog-row">
          <div className="export-dialog-coll">
            <label className="export-dialog-label">
              <Localization messageKey="startdate"/>
            </label>

            <button className="export-dialog-field export-dialog-field-datepicker"
                    onClick={this.handleClickOnDatePikerBtn}>
              {this.fromDate.format('L')}
            </button>

            <ExportDialogErrorMessage condition={this.isSelectedDateValid}
                                      message={<Localization messageKey="resourceplanner.invalid.date"/>}/>

            {
              this.showDatePicker && (
                <div className={'DatePicker'}>
                  <DatePicker
                    date={moment(this.fromDate)}
                    format={null}
                    maxValue={moment('1-1-2999', 'MM-DD-YYYY')}
                    minValue={moment('1-1-1980', 'MM-DD-YYYY')}
                    name="datepicker-0"
                    pastDatePickable
                    weekendPickable
                    onChange={(pickedDate) => {
                      this.handleDateChange(pickedDate)
                    }
                    }
                  />
                </div>
              )
            }

          </div>

          <div className="export-dialog-coll">
            <label htmlFor="period" className="export-dialog-label">
              <Localization messageKey="resourceplanner.export.period"/>
            </label>

            <input type="text" id="period"
                   className={`export-dialog-field ${isInvalidInput ? 'rp-invalid-field' : ''}`}
                   value={this.periodValue} onChange={this.handlePeriodChange}/>
          </div>

          <div className="export-dialog-coll rp-has-dropdown" data-wf-popover-container>
            <label htmlFor="" className="export-dialog-label">
              <Localization messageKey="type"/>
            </label>

            <DropDown selectedName={<Localization messageKey={this.type.messageKey}/>}>
              <DropDownList listItems={PeriodDropDownItems}
                            handleClick={this.handleTypeClick}
                            activeItem={this.type}/>
            </DropDown>
          </div>
        </div>

        <div className="export-dialog-row">
          <div className="export-dialog-coll export-dialog-coll-full export-dialog-export-options rp-has-dropdown"
               data-wf-popover-container>
            <label htmlFor="" className="export-dialog-label">
              <Localization messageKey="data.level.to.export"/>
            </label>

            <DropDown selectedName={<Localization messageKey={this.exportOption.messageKey}/>}>
              <DropDownList listItems={this.ExportOptionsDropDownItems}
                            handleClick={this.handleExportOptionClick}
                            activeItem={this.exportOption}/>
            </DropDown>
          </div>
        </div>

        <div className="export-dialog-row">
          <div className="export-dialog-coll">
            <label className="export-dialog-label">
              <Localization messageKey="resourceplanner.data.formatting"/>
            </label>
            <div className="export-dialog-data-formatting">
              <input type="radio" name="data-formatting" id="raw"
                     onChange={this.handleRowTypeChange}
                     value={RAW}
                     checked={this.dataFormatting === RAW}/>
              <label htmlFor="raw">
                <Localization messageKey="resourceplanner.data.formatting.raw"/>
              </label>
            </div>

            <div className="export-dialog-data-formatting">
              <input type="radio" name="data-formatting" id="grouped"
                     onChange={this.handleRowTypeChange}
                     value={GROUPED}
                     checked={this.dataFormatting === GROUPED}/>
              <label htmlFor="grouped">
                <Localization messageKey="resourceplanner.data.formatting.grouped"/>
              </label>
            </div>
          </div>

          <div className="export-dialog-coll export-dialog-coll-large">
            <div className={`export-dialog-data-format-img export-dialog-data-format-img-${this.dataFormatting}`}></div>
          </div>
        </div>

        <ExportDialogErrorMessage condition={this.outOfLimit}
                                  message={
                                    <Localization messageKey="resourceplanner.export.validation.error">
                                      {message => {
                                        return message({
                                          0: ExportPeriodLimitationEnum.max[this.type.key],
                                          1: redrockClient.getTextSync(`durationranges.${this.type.key.charAt(0)}.plural`)
                                        })
                                      }
                                      }
                                    </Localization>
        }
        />

        <ExportToExcelDialogButtons
          disabled={isDisabled}
          step={this.type.key}
          fromDate={this.fromDate}
          stepCount={this.periodValue}
          closeDialog={this.props.closeDialog}
          exportOption={this.exportOption.value}
          showSaveDialog={this.props.showSaveDialog}
          filterStore={this.props.filterStore}
          treeNodeStore={this.props.treeNodeStore}
          dataFormatting={this.dataFormatting}/>
      </div>
    );
  }
}
