/**
 * Created by iskuhihakobyan on 11/21/17.
 */
export const RESOURCE_PLANNER_HEADER_HEIGHT = 48;
export const RESOURCE_PLANNER_HEADER_PADDING_TOP = 16;
export const RESOURCE_PLANNER_FOOTER_HEIGHT = 66;
export const RESOURCE_PLANNER_CONTAINER_BC_HEIGHT = 570;
export const RESOURCE_PLANNER_CONTAINER_BC_CONTAINER_HEIGHT = 300;
export const RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT = 86;
export const RESOURCE_PLANNER_BUTTON_WRAPPER = 66;
export const RESOURCE_PLANNER_NODES_WIDTH = 295;
export const HOUR_ROW_HEIGHT = 50;
export const CHART_MAX_HEIGHT = 123;
export const OVER_ALLOCATION_COLOR = '#e97e3b';
export const UNDER_ALLOCATION_COLOR = '#d8ece0';
export const CONTAINER_MIN_WIDTH = 1065;
export const VIRTUILAZED_TABLE_HEADER_HEIGHT_OPEN = 251;
export const RESOURCE_PLANNER_BUSINESS_CASE_PADDING = 3;

