/**
 * Created by anihambardzumyan on 8/18/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Localization} from '../../../../localization/localization';
import {ToggleFormField} from '../../ToggleFormFiled';
import SettingsStore from '../../../stores/SettingsStore';
import If from 'If';
import _ from 'lodash';

@observer
export default class SettingsDialogContent extends Component {
  //noinspection JSAnnotator
  props: {
    showWarningMessage: Boolean,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, event) {
    SettingsStore.options[event.target.name].toggleValue = value;
  }

  render() {
    return (
      <div className="resource-planner-settings">
        <If condition={this.props.showWarningMessage}>
          <div className="warning-message"><Localization messageKey="resourceplanner.settings.save.warning"/></div>
        </If>

        {_.map(SettingsStore.options, (item, key) => (
          <If condition={key !== 'resource_planner.showActualHours'}>
            <ToggleFormField handleChange={this.handleChange} checked={item.toggleValue} name={key} key={key}>
              <div className="toggle-message" data-test-id={`${key}-toggle-label`}>
                <Localization messageKey={item.mainMessageKey}/>
                <span className="toggle-sub-message"><Localization messageKey={item.secondaryMessageKey}/></span>
              </div>
            </ToggleFormField>
          </If>
        ))}
      </div>
    );
  }
}
