//Libraries
import React, {Component, lazy, Suspense} from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import If from 'If';
import _ from 'lodash';
import { Localization } from '../../localization/localization';
//Components
import Loading from './loading/Loading';
import NoResults from './NoResults';
import SaveDialog from './saveChangesDialog/SaveDialog';
import ResourcePlannerOverlay from './ResourcePlannerOverlay';
//Stores
import NotificationStore from '../stores/NotificationStore';
import ConfirmationDialogStore from '../stores/ConfirmationDialogStore';
import { loadingStore } from '../stores/LoadingStore';
import ScrollStore from '../stores/ScrollStore';
import VisualizationStore from '../../userView/stores/VisualizationStore';
import ChangedHoursStore from '../../roleView/stores/ChangedHoursStore';
import ViewModeStore from '../stores/ViewModeStore';
import OverlayStore from '../stores/OverlayStore';
//Constants
import { CONTAINER_MIN_WIDTH } from '../constants/StyleConstants';
import { ViewEnum } from '../constants/ViewEnums';
//Services
import APIService from '../services/APIService';
import ResourcePlannerButtonsContainer from './ResourcePlannerButtonsContainer';
import { trackLaunchCustomEvent } from '@wf-mfe/logger';

const UserViewTable = lazy(() => import('../../userView/components/UserViewTable'))
const ProjectViewTable = lazy(() => import('../../projectView/components/ProjectViewTable'))
const RoleViewTable = lazy(() => import('../../roleView/components/RoleViewTable'))

@observer
class ResourcePlannerContainer extends Component {
  @observable budgetAdjustProject = null;

  //noinspection JSAnnotator
  props: {
    intervalStore: Object,
    treeNodeStore: Object,
    loadData: Function,
    loadInitialData: Function,
    cancelCallbackHandler: Function,
    loadMissingData: Function,
    reRenderTable: Boolean,
    filterStore: Object,
    saveCallbackHandler: ?Function,
    showProjectPriorityAlign: ?Boolean,
    showSaveDialog: ?Function,
    exitFullScreen: Function,
    analyzingTimeout: Object,
  };

  constructor(props) {
    super(props);

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.wrapperRef = React.createRef();
    this.observer = new window.ResizeObserver(this.windowResize);

    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidMount() {
    ScrollStore.changeResize();
    ScrollStore.calculateVirtualizedTableWidth();
    this.observer.observe(this.wrapperRef.current);
    this.observer.observe(document.body);
  }

  componentWillUnmount() {
    NotificationStore.clearNotification();
    this.observer.disconnect();
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp(evt) {
    if (
      VisualizationStore.data &&
      VisualizationStore.data.showVisualization &&
      evt.keyCode === 27
    ) {
      this.props.intervalStore.intervals.forEach((interval) => {
        interval.clearChartHighlight();
      });
    }
  }

  windowResize() {
    ScrollStore.changeResize();
    ScrollStore.calculateVirtualizedTableHeight();
    window.innerWidth > CONTAINER_MIN_WIDTH ? ScrollStore.calculateVirtualizedTableWidth() : '';
  }

  saveDataForProjectView(saveCallbackHandler) {
    loadingStore.loading = true;
    loadingStore.saveInProcess = true;

    const data = this.props.treeNodeStore.getChangedBudgetedHourByIntervalsForSave(
      this.props.intervalStore.intervals
    );
    const url = `/internal/resourceplanner/projectview/save${
      ViewModeStore.projectID ? `/${ViewModeStore.projectID}` : ''
    }`;

    data.priorities = ViewModeStore.hidePriority
      ? {}
      : this.props.treeNodeStore.getChangedPriorities();

    return APIService.saveData(url, data)
      .then(() => {
        loadingStore.saveInProcess = false;
        loadingStore.loading = false;
        NotificationStore.showNotification(
          <Localization messageKey="resourceplanner.saved" />,
          'success'
        );
        ViewModeStore.priorityChanged = false;
        this.props.treeNodeStore.emptyChangedBDGHours();
        this.props.treeNodeStore.resetProjectPriorities();

        if (_.isFunction(saveCallbackHandler)) {
          saveCallbackHandler();
        }
      })
      .catch(() => {
        loadingStore.loading = false;
        loadingStore.saveInProcess = false;
      });
  }

  saveDataForRoleView() {
    loadingStore.loading = true;
    loadingStore.saveInProcess = true;

    let verticalFields = {
      filters: this.props.filterStore.selectedFilterExpression,
    };

    const data = this.props.treeNodeStore.getChangedBudgetedHourByIntervalsForSave();

    return APIService.saveRoleData(data, verticalFields)
      .then(() => {
        NotificationStore.showNotification(
          <Localization messageKey="resourceplanner.saved" />,
          'success'
        );
        this.props.treeNodeStore.changeNodesBDGHoursByTmpDataAfterSave(
          ChangedHoursStore.tmpChangedBDGHours.budgetedHours
        );
        ChangedHoursStore.emptyTmpChangedBDGHours();
        loadingStore.saveInProcess = false;
        loadingStore.loading = false;
      })
      .catch(() => {
        ChangedHoursStore.mergeCurrentAndTmpChanges();
        ChangedHoursStore.emptyTmpChangedBDGHours();
        loadingStore.loading = false;
        loadingStore.saveInProcess = false;
      });
  }

  onSave(saveCallbackHandler) {
      trackLaunchCustomEvent('Changes Finalized', {
        'resourceMgmnt': {
          finalAction:  'save',
          budgetFormat: ViewModeStore.FTEView.key && ViewModeStore.FTEView.key.toLowerCase(),   //String
          viewByRp: ViewModeStore.view.key && ViewModeStore.view.key.toLowerCase(),   //String
        },
      })
    if (ViewModeStore.activeView.key === ViewEnum.project) {
      return this.saveDataForProjectView(saveCallbackHandler);
    } else if (ViewModeStore.activeView.key === ViewEnum.role) {
      return this.saveDataForRoleView();
    }
  }

  onCancel() {
      trackLaunchCustomEvent('Changes Finalized', {
        'resourceMgmnt': {
          finalAction:  'cancel',
          budgetFormat: ViewModeStore.FTEView.key && ViewModeStore.FTEView.key.toLowerCase(),   //String
          viewByRp: ViewModeStore.view.key && ViewModeStore.view.key.toLowerCase(),   //String
        },
      })
    if (this.props.treeNodeStore.unSavedChangesExists) {
      ConfirmationDialogStore.showConfirmationDialog(
        this.reloadData,
        'resourceplanner.cancel.message',
        'confirm'
      );
    } else {
      this.reloadData();
    }
  }

  reloadData() {
    ViewModeStore.priorityChanged = false;
    if (this.props.treeNodeStore.isSortReseted) {
      APIService.revertAlignedProjects().then(() => {
        this.props.loadInitialData();
        this.props.cancelCallbackHandler();
      });
    } else {
      this.props.loadInitialData();
      this.props.cancelCallbackHandler();
    }
  }

  render() {
    const {
      treeNodeStore,
      intervalStore,
      loadData,
      reRenderTable,
      loadMissingData,
      filterStore,
      showProjectPriorityAlign,
      showSaveDialog,
    } = this.props;

    return (
      <div
        ref={this.wrapperRef}
        className={`resource-planner-body ${
          ViewModeStore.isCustomViewMode ? 'custom-view' : 'standard-view'
        }`}
      >
        <If condition={loadingStore.loading}>
          <Loading height={ScrollStore.scrollStore.resourcePlannerComputedHeight} />
        </If>

        <If condition={!loadingStore.loading}>
          <div>
            <If condition={loadingStore.noResults}>
              <NoResults filterMessage={this.props.filterStore.hasSelectedFilter} />
            </If>

            <If
              condition={
                !loadingStore.noResults && treeNodeStore.nodes && treeNodeStore.nodesIDs.length > 0
              }
            >
              <div
                className={`resource-planner-container node-collection ${
                  ViewModeStore.isUserActiveView && VisualizationStore.data.showVisualization
                    ? 'visualization-chart-open'
                    : ''
                }`}
              >
                {OverlayStore.overlay.visible && <ResourcePlannerOverlay />}

                <If condition={ViewModeStore.activeView.key === ViewEnum.user}>
                  <Suspense fallback="">
                    <UserViewTable
                      intervalStore={intervalStore}
                      treeNodeStore={treeNodeStore}
                      loadMissingData={loadMissingData}
                      loadData={loadData}
                      reRenderTable={reRenderTable}
                    />
                  </Suspense>
                </If>

                <If condition={ViewModeStore.activeView.key === ViewEnum.project}>
                  <Suspense fallback="">
                    <ProjectViewTable
                      treeNodeStore={treeNodeStore}
                      intervalStore={intervalStore}
                      loadInitialData={this.props.loadInitialData}
                      loadData={loadData}
                      reRenderTable={reRenderTable}
                      filterStore={filterStore}
                      showProjectPriorityAlign={showProjectPriorityAlign}
                      showSaveDialog={showSaveDialog}
                      analyzingTimeout = {this.props.analyzingTimeout}
                    />
                  </Suspense>
                </If>

                <If condition={ViewModeStore.activeView.key === ViewEnum.role}>
                  <Suspense fallback="">
                    <RoleViewTable
                      intervalStore={intervalStore}
                      treeNodeStore={treeNodeStore}
                      loadMissingData={loadMissingData}
                      loadData={loadData}
                      filterStore={filterStore}
                      reRenderTable={reRenderTable}
                      analyzingTimeout = {this.props.analyzingTimeout}
                    />
                  </Suspense>
                </If>
              </div>
            </If>
          </div>
        </If>

        <SaveDialog onSave={this.onSave} />

        <If
          condition={
            !(ViewModeStore.hasViewAccess && !ViewModeStore.projectID) &&
            !ViewModeStore.isUserActiveView &&
            !loadingStore.noResults &&
            (!loadingStore.loading || loadingStore.saveInProcess)
          }
        >
          <ResourcePlannerButtonsContainer onCancel={this.onCancel} onSave={this.onSave} saveCallbackHandler={this.props.saveCallbackHandler} />
        </If>
      </div>
    );
  }
}

export default ResourcePlannerContainer;
