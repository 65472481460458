import React, {Component} from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import LoadingHeaderItem from './LoadingHeaderItem';
import ViewModeStore from '../../stores/ViewModeStore';

@observer
export default class LoadingHeader extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const loadingHeaderContent = [];

    _.times(ViewModeStore.initialStepCount, (key) => {
      loadingHeaderContent.push(<LoadingHeaderItem key={key}/>);
    });

    return (
      <div className="interval-headers interval-headers-loader">
        {loadingHeaderContent}
      </div>
    );
  }
}


