import React, {Component} from 'react';
import {observer} from 'mobx-react';
import ReactDOM from 'react-dom';
//Components
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
//Stores
import ScrollStore from '../../stores/ScrollStore';
import {loadingStore} from '../../stores/LoadingStore';
//Constants
import {RESOURCE_PLANNER_HEADER_PADDING_TOP} from '../../constants/StyleConstants';

@observer
export default class ResourcePlannerHeader extends Component {
  //noinspection JSAnnotator
  props: {
    intervalStore: Object,
    treeNodeStore: Object,
    filterStore: Object,
    loadStoreData: Function,
    mfeMigrationEnabled: ?Boolean,
    loadVisualizationData: Function,
    fullModeToggle: Function,
    loadNextPrevData: Function,
    showSaveDialog: Function
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ScrollStore.scrollStore.containerTop = ReactDOM.findDOMNode(this).getBoundingClientRect().top - RESOURCE_PLANNER_HEADER_PADDING_TOP;
  }

  render() {
    const disableCondition = loadingStore.loading || loadingStore.nextPreviousLoading

    return (
      <div className="resource-planner-header" style={{
        paddingLeft: ScrollStore.scrollStore.resourcePlannerHeaderPadding,
        paddingRight: ScrollStore.scrollStore.resourcePlannerHeaderPadding
      }}>
        <HeaderLeft {...this.props}
                    disableCondition={disableCondition || loadingStore.isLazyLoadingMode}/>

        <HeaderRight loadStoreData={this.props.loadStoreData}
                     disableCondition={disableCondition}
                     loadVisualizationData={this.props.loadVisualizationData}
                     fullModeToggle={this.props.fullModeToggle}
                     intervalStore={this.props.intervalStore}
                     treeNodeStore={this.props.treeNodeStore}
                     mfeMigrationEnabled={this.props.mfeMigrationEnabled}
                     isFilterVisible={this.props.filterStore.filterOptions.visible}
                     filterStore={this.props.filterStore}
                     showSaveDialog={this.props.showSaveDialog}/>
      </div>
    )
  }
}
