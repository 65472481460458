/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import {computed} from 'mobx';
import ViewModeStore from '../../../shared/stores/ViewModeStore';
import {roundNumber} from '../../../shared/services/Utilities';
import {FTE_VIEW_KEY, HOUR_VIEW_KEY, HourViewAttributes} from '../../../shared/constants/ViewEnums';

export default class HourUserRoleModel {
  nodes;

  constructor(AVL, PLN, ACT, fth) {
    this.fth = fth;
    this._AVL = AVL || 0;
    this._PLN = PLN || 0;
    this._ACT = ACT || 0;
  }

  @computed
  get FTH() {
    return this.getFTHValue();
  }

  getFTHValue(metric = HOUR_VIEW_KEY) {
    const fteCoefficient = ViewModeStore.isCustomViewMode && HourViewAttributes[metric] ? HourViewAttributes[metric].fteCoefficient : ViewModeStore.hourViewMode.fteCoefficient;
    return fteCoefficient || this.fth;
  }

  @computed
  get AVLHour() {
    return this._AVL / this.FTH;
  }

  @computed
  get AVLFTE() {
    return this._AVL / this.getFTHValue(FTE_VIEW_KEY);
  }

  @computed
  get AVLCost() {
    return this._AVL;
  }

  @computed
  get PLNHour() {
    return this._PLN / this.FTH;
  }

  @computed
  get PLNFTE() {
    return this._PLN / this.getFTHValue(FTE_VIEW_KEY);
  }

  @computed
  get ACTHour() {
    return this._ACT / this.FTH;
  }

  @computed
  get ACTFTE() {
    return this._ACT / this.getFTHValue(FTE_VIEW_KEY);
  }

  @computed
  get NETHour() {
    return this.AVLHour - this.PLNHour;
  }

  @computed
  get NETFTE() {
    return this.AVLFTE - this.PLNFTE;
  }

  @computed
  get PCTHour() {
    return this.getPCTValue();
  }

  @computed
  get PCTFTE() {
    return this.getPCTValue();
  }

  getPCTValue() {
    let pctValue;
    if (this._AVL === 0) {
      pctValue = '-';
    } else {
      pctValue = roundNumber(this._PLN * 100 / this._AVL);
    }

    return pctValue;
  }
}
