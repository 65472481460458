import ObjectModel from './ObjectModel';
import _ from 'lodash';
import {Project as ProjectObjCode} from 'workfront-objcodes';
import {isNodeIdDefined} from '../../../shared/services/Utilities';
import {COST_VIEW_KEY} from '../../../shared/constants/ViewEnums';

export default class ObjectProjModel extends ObjectModel {
  initialPriority;
  newPriority;
  plannedStartDate;
  plannedCompletionDate;
  globalPriority;

  constructor(ID, name, nodes, initialPriority, globalPriority, hasEmptyPriority, plannedStartDate, plannedCompletionDate, editBudgeting = true) {
    super(ProjectObjCode, ID, name, nodes, null);

    this.initialPriority = this.newPriority = initialPriority;
    this.globalPriority = globalPriority;
    this.hasEmptyPriority = hasEmptyPriority;
    this.plannedStartDate = plannedStartDate;
    this.plannedCompletionDate = plannedCompletionDate;
    this.editBudgeting = editBudgeting;

    //three dots properties
    this.showSetPlannedHourToBudgetedLink = _.some(nodes, nodeItem => nodeItem.showThreeDots);
    this.showEquateBudgetedFromUserLink = false;
    this.showThreeDots = _.some(nodes, nodeItem => nodeItem.showThreeDots || !nodeItem.editBudgeting) ;
    this.showBudgetAdjustmentLink = this.showThreeDots;
    //HOUR
    this.hasOnlyNoRole = _.every(nodes, nodeItem => !isNodeIdDefined(nodeItem));
    this.showOnlyPlannedHour = !this.expandable || this.hasOnlyNoRole || _.every(this.nodes, nodeItem => nodeItem.showOnlyPlannedHour);
  }

  setBDGHour(value, i) {
    //all nodes plnHour is 0
    if (_.every(this.nodes, node => node[i].PLNHour === 0 || (node[i].PLNHour !== 0 && node.showOnlyPlannedHour))) {
      const notEmptyNodesCount = _.filter(this.nodes, node => node[i].PLNHour === 0 && !node.showOnlyPlannedHour && node.getCostRate(i)).length;

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGHour(value / notEmptyNodesCount, i);
        }
      });
    } else {
      //if there's any role with planned hour and with user node
      //Added costRate for fixing issue #19726300.
      const purePlannedHour = this[i].PLNHour - _.reduce(this.nodes, (sum, node) =>
        sum + (node.showOnlyPlannedHour || !node.getCostRate(i) ? node[i].PLNHour : 0), 0);

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGHour(value * (node[i].PLNHour / purePlannedHour), i);
        }
      });
    }
  }

  setBDGFTE(value, i) {
    if (_.every(this.nodes, node => node[i].PLNFTE === 0 || (node[i].PLNFTE !== 0 && node.showOnlyPlannedHour))) {
      const notEmptyNodesCount = _.filter(this.nodes, node => node[i].PLNFTE === 0 && !node.showOnlyPlannedHour).length;

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGFTE(value / notEmptyNodesCount, i);
        }
      });
    } else {
      const purePlannedHour = this[i].PLNFTE - _.reduce(this.nodes, (sum, node) =>
        sum + (node.showOnlyPlannedHour ? node[i].PLNFTE : 0), 0);

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGFTE(value * (node[i].PLNFTE / purePlannedHour), i);
        }
      });
    }
  }

  setBDGCost(value, i) {
    if (_.every(this.nodes, node => node[i].PLNCost === 0 || (node[i].PLNCost !== 0 && node.showOnlyPlannedHour))) {
      const notEmptyNodesCount = _.filter(this.nodes, node => node[i].PLNCost === 0 && !node.showOnlyPlannedHour && node.getCostRate(i, COST_VIEW_KEY)).length;

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGCost(value / notEmptyNodesCount, i);
        }
      });
    } else {
      const purePlannedHour = this[i].PLNCost - _.reduce(this.nodes, (sum, node) =>
        sum + (node.showOnlyPlannedHour || !node.getCostRate(i, COST_VIEW_KEY) ? node[i].PLNCost : 0), 0);

      this.nodes.map(node => {
        if (!node.showOnlyPlannedHour) {
          node.setBDGCost(value * (node[i].PLNCost / purePlannedHour), i);
        }
      });
    }
  }
}
