import React, {Component} from 'react';
import {AutoCompleteFilter, CustomDataFilter, Filter, TypeAheadFilter} from 'titan/react-filter-group';
import ScrollStore from '../../stores/ScrollStore';
import {excludeGroupItems, excludeGroups} from '../../constants/FilterOptions';
import ViewModeStore from '../../stores/ViewModeStore';
import {ViewEnum} from '../../constants/ViewEnums';
import {getResourcePlannerArea} from '../../services/Utilities';
import {redrockClient} from '../../../localization/localization';

export default class FilterNew extends Component {
  props: {
    loadStoreData: Function,
    filterStore: Object,
    filterToggleCallback: Function,
    treeNodeStore: Object
  };


  componentDidMount() {
    ScrollStore.calculateVirtualizedTableWidth();
  }


  componentWillUnmount(){
    ScrollStore.scrollStore.filterOpen = false;
  }

  render() {
    let reportifyObjCodes = ['PORT', 'PRGM', 'PROJ', 'USER', 'CMPY'],
      disableCustomFilterGroups,
      objCode;

    if (ViewModeStore.activeView.key === ViewEnum.user) {
      reportifyObjCodes.push('OPTASK', 'TASK');
      disableCustomFilterGroups = [];
      objCode = 'userView';
    } else {
      disableCustomFilterGroups = ['opTask', 'task'];
      objCode = 'roleProjectView';
    }

    return (
      <Filter className="filter"
              width="265"
              showApply={true}
              elementsUnderFilter={['.new-footer', '.button-container-wrapper']}
              applyBtnMessageKey="action.apply"
              openedFrom={getResourcePlannerArea(ViewModeStore)}>
        <TypeAheadFilter
          labelKey="portfolio"
          objCode={'portfolio'}
          fields={['ID']}
        />

        <AutoCompleteFilter
          labelKey="projectstatus"
          objCode={'project:status'}
          statusesList={this.props.filterStore.statusesList}
        />

        <TypeAheadFilter
          labelKey="team"
          objCode="team"
          fields={['ID']}
        />

        <TypeAheadFilter
          labelKey="role"
          objCode="role"
          fields={['ID']}
        />

        <TypeAheadFilter
          labelKey="group"
          objCode="group"
          fields={['ID']}
        />

        <TypeAheadFilter
          labelKey="pools"
          objCode="RsrcPool"
          fields={['ID']}
        />

        <CustomDataFilter
          objCode={objCode}
          reportifyObjCodes={reportifyObjCodes}
          excludeGroups={excludeGroups}
          excludeGroupItems={excludeGroupItems}
          disableCustomFilterGroups={disableCustomFilterGroups}
          disabledMessage={redrockClient.getTextSync('resourceplanner.customfilter.disabled.message')}
          openedFrom={getResourcePlannerArea(ViewModeStore)}
        />
      </Filter>
    )
  }
}
