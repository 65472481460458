import {Api} from 'workfront-api';
import {getSessionID, getXSRFToken} from 'workfront-cookie';

export const getApi = function() {
  if (!getApi.instance) {
    getApi.instance = new Api({
      url: document.location.origin,
      version: 'internal'
    });
    if (getXSRFToken()) { //TODO remove condition when AttaskCookieHttpOnlyPitBoss pitboss will be deleted
      getApi.instance.setXSRFToken(getXSRFToken());
    } else {
      getApi.instance.setSessionID(getSessionID());
    }
  }
  return getApi.instance;
};

export function clearApiInstance() {
  getApi.instance = undefined;
}
