import React, {Component} from 'react';
import _ from 'lodash';
import LoadingBodyItem from './LoadingBodyItem';
import ViewModeStore from '../../stores/ViewModeStore';

export default class LoadingBody extends Component {
  //noinspection JSAnnotator
  props: {
    height: Number
  };

  constructor(props) {
    super(props);
  }

  render() {
    const loadingBodyContent = [];

    _.times(ViewModeStore.initialStepCount, (key) => {
      loadingBodyContent.push(<LoadingBodyItem key={key} height={this.props.height}/>);
    });

    return (
      <div className="intervals">
        <div className="interval-hours-container">
          {loadingBodyContent}
        </div>
      </div>
    );
  }
}


