/**
 * Created by iskuhihakobyan on 5/24/17.
 */
import {observable} from 'mobx';

export default {
  data: observable({
    showVisualization: false,
    chartMaxPoint: 0
  })
};
