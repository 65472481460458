/**
 * Created by artakpoghosyan on 8/23/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {TertiaryButton} from 'titan/react-button';
import {Tip} from 'titan/react-popover';
import {topTooltipOptions, standardTooltipOptions} from '../../constants/TooltipOptions';
import ViewModeStore from '../../stores/ViewModeStore';
import {ViewEnum} from '../../constants/ViewEnums';
import {Localization} from '../../../localization/localization';
import VisualizationStore from '../../../userView/stores/VisualizationStore';
import ScrollStore from '../../stores/ScrollStore';
import {
  VIRTUILAZED_TABLE_HEADER_HEIGHT_OPEN,
  RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT
} from '../../constants/StyleConstants';
import {getResourcePlannerArea} from '../../services/Utilities';

@observer
export default class VisualizationButton extends Component {

  //noinspection JSAnnotator
  props: {
    intervalStore: Object,
    disabled: Boolean,
    loadVisualizationData: Function,
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.disabled || ViewModeStore.activeView.key !== ViewEnum.user) {
      return;
    }
    VisualizationStore.data.showVisualization = !VisualizationStore.data.showVisualization;
    ScrollStore.scrollStore.virtualizedTableHeaderHeight = VisualizationStore.data.showVisualization ? VIRTUILAZED_TABLE_HEADER_HEIGHT_OPEN : RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT;
    this.props.intervalStore.intervals.forEach((interval) => {
      interval.clearChartHighlight();
    });

    if (VisualizationStore.data.showVisualization) {
      let fromDate,
        stepCount = 0;

      this.props.intervalStore.intervals.map((interval) => {
        if (interval.chartData.sumAVL === null && interval.chartData.sumPLN === null) {
          fromDate = fromDate || interval.from;
          stepCount++;
        }
      });

      if (stepCount) {
        this.props.loadVisualizationData(fromDate, stepCount);
      }
    }
  }

  render() {
    let disableCondition = this.props.disabled || ViewModeStore.activeView.key !== ViewEnum.user;
    let iconColor = disableCondition ? '#BDBDBD' : '#2F609F';
    //Tip doesn't work properly on disable button
    // That's why given disabled class instead of disable attribute
    const toolTipOptions = ViewModeStore.isQuickSilver ? standardTooltipOptions :topTooltipOptions;
    return (
      <div data-wf-popover-container
           data-testid={`visualization button-${getResourcePlannerArea(ViewModeStore)}-${ViewModeStore.activeStep.key}`}
        >
        <Tip isEnabled={ViewModeStore.activeView.key !== ViewEnum.user}
             content={<span><Localization messageKey="resourceplanner.visualization.tip.text"/></span>}
             {...toolTipOptions}>
          <TertiaryButton
            className={`btn-visualization ${disableCondition ? 'disabled' : ''} ${VisualizationStore.data.showVisualization ? 'active' : ''}`}
            onClick={this.handleClick}>
            <svg width="15px" height="14px" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path className="st0" d="M2.6,12.6c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.5-0.2-0.7l3.8-6.2C6,5.4,6.2,5.4,6.3,5.3c0.2,0,0.3,0,0.4,0.1
	l1.8,1.9l3.4-5.7c0.1-0.2,0.5-0.3,0.7-0.2c0.2,0.1,0.3,0.4,0.2,0.7L9.1,8.4C9,8.6,8.8,8.6,8.7,8.7c-0.2,0-0.3,0-0.4-0.2L6.5,6.6
	l-3.4,5.7C3,12.5,2.8,12.6,2.6,12.6z M0.8,13.2V0.4C0.8,0.2,0.6,0,0.4,0S0,0.2,0,0.4v13.2c0,0.1,0,0.2,0.1,0.3C0.2,14,0.3,14,0.4,14
	h14.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H0.8z" fill={iconColor}/>
            </svg>
          </TertiaryButton>
        </Tip>
      </div>
    );
  }
}
