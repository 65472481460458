import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Localization, redrockClient} from '../../localization/localization';
import ConfirmationDialogStore from '../stores/ConfirmationDialogStore';
import If from 'If';
import {Dialog} from '@phoenix/all';
import {DialogBody} from './dialog/DialogBody';

@observer
export default class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
  }

  cancelAction() {
    ConfirmationDialogStore.hideConfirmationDialog();
  }

  continueAction() {
    ConfirmationDialogStore.dialogStore.onConfirm();
    ConfirmationDialogStore.hideConfirmationDialog();
  }

  render() {
    return (
      <If condition={ConfirmationDialogStore.dialogStore.dialogVisible}>
        <Localization messageKeys={[ConfirmationDialogStore.dialogStore.titleKey, ConfirmationDialogStore.dialogStore.messageKey]}>
          {(messages) => (
            <Dialog medium>
              <DialogBody
                title={messages[0]()}
                onCancel={this.cancelAction}
                onSave={this.continueAction}
                onSaveText={redrockClient.getTextSync('action.continue', 'Continue')}
              >
              {messages[1]()}
              </DialogBody>
            </Dialog>
          )}
        </Localization>
      </If>
    )
  }
}
