import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Popup } from 'titan/react-popover';
import UniqueUrlGeneratorContent from './UniqueUrlGeneratorContent';
import ViewModeStore from '../../../stores/ViewModeStore';
import { urlPopupOptions } from '../../../constants/PopupOptions';
import SettingsStore from '../../../stores/SettingsStore';

@observer
export default class UniqueUrlGenerator extends Component {
  @observable generatedLink = '';

  //noinspection JSAnnotator
  props: {
    filterStore: Object,
    disabled: Boolean,
    mfeMigrationEnabled: ?Boolean,
  };

  onOpenHandler = () => {
    const parameters =
      'full=true' +
      `&filter=${String(this.props.filterStore.selectedFilterID)}` +
      `&count=${ViewModeStore.intervalsCount}` +
      `&step=${ViewModeStore.activeStep.key}` +
      `&mode=${ViewModeStore.hourViewMode.key}` +
      `&view=${ViewModeStore.activeView.key}` +
      '&hideView=true' +
      '&hideURLGenerator=true' +
      '&hideFullScreen=true' +
      `&customColumns=${
        ViewModeStore.isCustomViewMode
          ? JSON.stringify(
              SettingsStore.customColumnOptions['resource_planner.customColumns'].value
            )
          : ''
      }`;

    const encoded64Data = window.btoa(parameters);
    const location = window.location.protocol + '//' + window.location.host;

    this.generatedLink = encodeURI(
      this.props.mfeMigrationEnabled
        ? `${location}/resource-planner-shareable-link?p=${encoded64Data}`
        : `${location}/tile?content=tab-resource-planner&p=${encoded64Data}`
    );
  };

  onCloseHandler = () => {
    this.generatedLink = '';
  };

  render() {
    return (
      <div className="unique-url-generator" data-wf-popover-container>
        <Popup
          {...urlPopupOptions}
          onOpen={this.onOpenHandler}
          onClose={this.onCloseHandler}
          content={<UniqueUrlGeneratorContent generatedLink={this.generatedLink} />}
        >
          <div className="btn-url-share" disabled={this.props.disabled}>
            <svg width="12px" height="17px" viewBox="0 0 12 17" version="1.1">
              <defs>
                <linearGradient x1="50%" y1="101.572327%" x2="50%" y2="0%">
                  <stop stopColor="#A3BFE3" stopOpacity="0.615602355" offset="0%"></stop>
                  <stop stopColor="#0E3B76" offset="100%"></stop>
                </linearGradient>
                <path
                  d="M10.9905,1.53795312 C9.6485,0.154015625 7.4675,0.154015625 6.1255,1.53795312 L3.0065,4.75442187 C1.8125,5.98573437 1.6875,7.89767187 2.6175,9.28057812 L1.0065,10.9419219 C-0.3355,12.3258594 -0.3355,14.5750156 1.0065,15.9589531 C1.6775,16.6509219 2.5585,16.9963906 3.4395,16.9963906 C4.3205,16.9963906 5.2015,16.6509219 5.8725,15.9589531 L8.9915,12.7424844 C10.1855,11.5111719 10.3105,9.59923438 9.3805,8.21632813 L10.9915,6.55498438 C12.3325,5.17104687 12.3325,2.92189062 10.9905,1.53795312 Z M8.6885,7.47073437 C7.3385,6.36420312 5.3735,6.43845312 4.1255,7.72545312 C3.9305,7.92654687 3.9305,8.25345312 4.1255,8.45454687 C4.3205,8.65564063 4.6375,8.65564063 4.8325,8.45454687 C5.7835,7.47382812 7.3325,7.47382812 8.2835,8.45454687 C9.2345,9.43526562 9.2345,11.0326719 8.2835,12.0133906 L5.1645,15.2298594 C4.2135,16.2105781 2.6645,16.2105781 1.7135,15.2298594 C0.7625,14.2491406 0.7625,12.6517344 1.7135,11.6710156 L3.3085,10.0261719 C3.9325,10.5376719 4.6815,10.8088906 5.4385,10.8088906 C6.3195,10.8088906 7.2005,10.4634219 7.8715,9.77145312 C8.0665,9.57035937 8.0665,9.24345312 7.8715,9.04235937 C7.6765,8.84126562 7.3595,8.84126562 7.1645,9.04235937 C6.2135,10.0230781 4.6645,10.0230781 3.7135,9.04235937 C2.7625,8.06164062 2.7625,6.46423437 3.7135,5.48351562 L6.8325,2.26704687 C7.7835,1.28632812 9.3325,1.28632812 10.2835,2.26704687 C11.2345,3.24776562 11.2345,4.84517187 10.2835,5.82589062 L8.6885,7.47073437 Z"
                  id="path-2"
                ></path>
              </defs>
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1342.000000, -171.000000)">
                  <g id="icon/Link/24" transform="translate(1336.000000, 167.000000)">
                    <g id="24_Link">
                      <g id="color/default24-" transform="translate(6.000000, 3.500000)">
                        <mask id="mask-3" fill="white">
                          <use xlinkHref="#path-2" />
                        </mask>
                        <use id="Mask" fill="#4A90E2" fillRule="nonzero" xlinkHref="#path-2" />
                        <g
                          id="color/default24"
                          mask="url(#mask-3)"
                          fill={this.props.disabled ? '#BDBDBD' : '#2f609f'}
                        >
                          <g transform="translate(-6.000000, -4.000000)" id="Rectangle-2">
                            <rect x="0" y="0" width="24" height="24"></rect>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </Popup>
      </div>
    );
  }
}
