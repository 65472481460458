/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import ObjectModel from './ObjectModel';
import {User as UserObjCode} from 'workfront-objcodes';

export default class ObjectUserModel extends ObjectModel {
  avatarDownloadURL;

  constructor(ID, name, expandable, intervals) {
    super(UserObjCode, ID, name, expandable, intervals);

    this.avatarDownloadURL = `/internal/user/avatar?ID=${ID}&size=SMALL&time=${new Date().getTime()}`;
  }
}
