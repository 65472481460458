/**
 * Created by anihambardzumyan on 4/26/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Localization} from '../../../localization/localization';
import moment from 'moment';
import _ from 'lodash';
import LeftCaratSmallIcon from 'phoenix-icons/dist/LeftCaratSmallIcon.js';
import RightCaratSmallIcon from 'phoenix-icons/dist/RightCaratSmallIcon.js';
//Components
import {LightTertiaryButton} from 'titan/react-button'
//Services
import AnalyticsService from '../../../analytics/AnalyticsService';
import {getDataTestIdForButtons} from '../../services/Utilities'
//Stores
import ViewModeStore from '../../stores/ViewModeStore';
import {loadingStore} from '../../stores/LoadingStore';
//Constants
import {NextPreviousEnum} from '../../constants/NextPreviousEnum';
import {DATE_FORMAT} from '../../constants/MonthsEnum';

@observer
export default class NextPrevious extends Component {
  //noinspection JSAnnotator
  props: {
    intervalStore: Object,
    loadNextPrevData: Function,
    disabled: Boolean
  };

  constructor(props) {
    super(props);

    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.showToday = this.showToday.bind(this);
  }

  showToday() {
    let {intervalStore} = this.props;

    const current = intervalStore.getCurrentDate(),
      intervalsLength = intervalStore.intervals.length,
      intervalStartDate = moment(intervalStore.intervals[0].from).locale('en'),
      intervalEndDate = moment(intervalStore.intervals[intervalsLength - 1].from).locale('en'),
      diff = current.diff(intervalStartDate, ViewModeStore.activeStep.key);

    if (diff === 0) {
      return;
    }

    loadingStore.nextPreviousLoading = true;

    let stepCount = Math.abs(diff),
      requestFrom = current.format(DATE_FORMAT),
      nextPrev, //For determine add interval from the end or start
      fromDate; //For adding interval dates

    if (stepCount > ViewModeStore.intervalsCount) {
      stepCount = ViewModeStore.intervalsCount;
      nextPrev = 1;
      fromDate = current.add(-1, ViewModeStore.activeStep.key);
    } else {
      if (diff > 0) {
        nextPrev = 1;
        fromDate = intervalEndDate;
        requestFrom = fromDate.clone().add(1, ViewModeStore.activeStep.key).format(DATE_FORMAT);
      } else {
        nextPrev = -1;
        fromDate = intervalStartDate;
      }
    }

    this.props.loadNextPrevData(nextPrev, stepCount, fromDate, requestFrom).then(() => {
      loadingStore.nextPreviousLoading = false;
    });
  }

  handleClick(nextPrev) { //nextPrev can be 1=(next) or -1=(previous)
    loadingStore.nextPreviousLoading = true;
    let action, index;

    if (nextPrev === NextPreviousEnum.next) {
      action = 'next';
      index = this.props.intervalStore.intervals.length - 1;
    } else {
      action = 'previous';
      index = 0;
    }

    AnalyticsService.trackResourcePlannerEvent(action, ViewModeStore.activeStep.key);

    let from = moment(this.props.intervalStore.intervals[index].from).locale('en');
    let requestFrom = from.clone().add(nextPrev, ViewModeStore.activeStep.key).format(DATE_FORMAT);

    this.props.loadNextPrevData(nextPrev, 1, from, requestFrom).then(() => {
      loadingStore.nextPreviousLoading = false;
    });
  }

  handleClickPrev() {
    this.handleClick(NextPreviousEnum.previous)
  }

  handleClickNext() {
    this.handleClick(NextPreviousEnum.next)
  }

  render() {
    const {disabled, intervalStore} = this.props;
    const currentDate = intervalStore.getCurrentDate().format(DATE_FORMAT);


    let isVisibleToday = _.find(intervalStore.intervals, ({from}) => from === currentDate);

    return (
      <div className={`next-prev-navigation ${disabled ? 'disabled' : ''}`}>
        <Localization messageKey="resourceplanner.prev">
          {prevMessage => (
            <LightTertiaryButton
              className="planner-header-prev-button"
              title={prevMessage()}
              data-testid={`${getDataTestIdForButtons(ViewModeStore, 'prevButton')}`}
              onClick={this.handleClickPrev}
              disabled={disabled}
            >
              <LeftCaratSmallIcon color={disabled ? '#BDBDBD' : '#2F609F'} />
            </LightTertiaryButton>
          )}
        </Localization>

        <LightTertiaryButton className="planner-header-today-button"
                             data-testid={`${getDataTestIdForButtons(ViewModeStore, 'todayButton',)}`}
                             disabled={disabled || isVisibleToday}
                             onClick={this.showToday}>
          <Localization messageKey="resourceplanner.today"/>
        </LightTertiaryButton>

        <Localization messageKey="resourceplanner.next">
          {nextMessage => (
            <LightTertiaryButton
              className="planner-header-next-button"
              title={nextMessage()}
              data-testid={`${getDataTestIdForButtons(ViewModeStore, 'nextButton',)}`}
              onClick={this.handleClickNext}
              disabled={disabled}
            >
              <RightCaratSmallIcon color={disabled ? '#BDBDBD' : '#2F609F'} />
            </LightTertiaryButton>
          )}
        </Localization>
      </div>
    )
  }
}
