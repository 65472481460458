import MonthsMessageKeys from '../shared/constants/MonthsEnum';

export const MessageKeys = [
  'applying',
  'action.apply',
  'action.applying',
  'action.cancel',
  'action.continue',
  'action.export',
  'action.exporting',
  'add.more',
  'action.save',
  'action.save.and.continue',
  'action.save.and.switch.hint',
  'action.save.and.switch.hint.export',
  'action.saving',
  'action.sort',
  'action.order',
  'confirm',
  'clear.all',
  'close',
  'custom',
  'customize',
  'data.level.to.export',
  'done',
  'durationranges.m.plural',
  'durationranges.q.plural',
  'durationranges.w.plural',
  'exporting',
  'export.everything',
  'export.first.level.project',
  'export.first.level.role',
  'export.first.level.user',
  'export.first.two.levels.user',
  'export.first.two.levels.role',
  'export.first.two.levels.project',
  'filter',
  'filter.plural',
  'group',
  'groupid',
  'information',
  'longwait',
  'loading',
  'month',
  'month.lowercase',
  'object.action.share.button.copy',
  'quarter',
  'quarter.lowercase',
  'percent',
  'percent.abbr',
  'plannedcompletiondate',
  'plannedstartdate',
  'pools',
  'portfolio',
  'portfoliopriorities',
  'project',
  'projectstatus',
  'project.action.view',
  'resourceplanner.actual',
  'resourceplanner.actual.abbr',
  'resourceplanner.actual.description',
  'resourceplanner.adjust.budgeted.dates',
  'resourceplanner.adjust.budgeted.dates.no.budgeted.hours.new',
  'resourceplanner.businesscase.empty.pools',
  'resourceplanner.add.new.filter',
  'resourceplanner.add.resource.pools',
  'resourceplanner.allocation.conflict.message',
  'resourceplanner.apply.and.save',
  'resourceplanner.available',
  'resourceplanner.available.abbr',
  'resourceplanner.available.description',
  'resourceplanner.available.user.view.description',
  'resourceplanner.budgeted',
  'resourceplanner.budgeted.abbr',
  'resourceplanner.budgeted.description',
  'resourceplanner.budgeting.done',
  'resourceplanner.budgeting.uneditable.proj',
  'resourceplanner.budgeting.uneditable.proj.cost',
  'resourceplanner.budgeting.uneditable.role',
  'resourceplanner.cancel.message',
  'resourceplanner.context.sensitive.help',
  'resourceplanner.customfilter.disabled.message',
  'resourceplanner.data.formatting',
  'resourceplanner.data.formatting.raw',
  'resourceplanner.data.formatting.grouped',
  'resourceplanner.default',
  'resourceplanner.display.global.priorities',
  'resourceplanner.display.global.priorities.description',
  'resourceplanner.pool.cancel.message',
  'resourceplanner.default',
  'resourceplanner.difference',
  'resourceplanner.diff',
  'resourceplanner.diff.abbr',
  'resourceplanner.diff.description',
  'resourceplanner.display.selected.items',
  'resourceplanner.drag.bar.multi.selected.label',
  'resourceplanner.drag.bar.single.selected.label',
  'resourceplanner.equate.role.budgeted.hour.from.user',
  'resourceplanner.equate.proj.budgeted.hour.from.user',
  'resourceplanner.equate.role.budgeted.hour.from.user.success',
  'resourceplanner.equate.proj.budgeted.hour.from.user.success',
  'resourceplanner.export.button.popover',
  'resourceplanner.export.canceled.error.status',
  'resourceplanner.export.check.your.email',
  'resourceplanner.export.error',
  'resourceplanner.export.period',
  'resourceplanner.export.period.title',
  'resourceplanner.export.validation.error',
  'resourceplanner.filter.no.results',
  'resourceplanner.cost',
  'resourceplanner.fte',
  'resourceplanner.hours',
  'resourceplanner.filter.saved.successfully',
  'resourceplanner.invalid.date',
  'resourceplanner.include.issue.hours',
  'resourceplanner.include.issue.hours.description',
  'resourceplanner.learn.more',
  'resourceplanner.limit.assignment.info',
  'resourceplanner.limit.project.info',
  'resourceplanner.load.more',
  'resourceplanner.no.filter.results',
  'resourceplanner.no.project.found',
  'resourceplanner.no.project.to.see.information.new',
  'resourceplanner.no.role.found',
  'resourceplanner.no.role.to.see.information.new',
  'resourceplanner.no.user.found',
  'resourceplanner.no.user.in.role',
  'resourceplanner.no.user.in.proj',
  'resourceplanner.normal.allocation.message',
  'resourceplanner.net',
  'resourceplanner.net.abbr',
  'resourceplanner.net.description',
  'resourceplanner.net.remaining.description',
  'resourceplanner.net.calculation.info',
  'resourceplanner.net.calculation.setting.description',
  'resourceplanner.percent.description',
  'resourceplanner.over.allocation.message',
  'resourceplanner.percent',
  'resourceplanner.planned',
  'resourceplanner.planned.abbr',
  'resourceplanner.planned.description',
  'resourceplanner.project.budget',
  'resourceplanner.project.budgeted.error',
  'resourceplanner.project.view',
  'resourceplanner.project.view.first.level.limit',
  'resourceplanner.project.view.rows.limit',
  'resourceplanner.projectview.set.planned.to.budgeted.proj',
  'resourceplanner.projectview.set.planned.to.budgeted.role',
  'resourceplanner.projectview.set.planned.to.budgeted.proj.success',
  'resourceplanner.projectview.set.planned.to.budgeted.proj.success.new',
  'resourceplanner.projectview.set.planned.to.budgeted.role.success',
  'resourceplanner.quarter.number',
  'resourceplanner.role.budget',
  'resourceplanner.rename',
  'resourceplanner.remove',
  'resourceplanner.role.budgeted.error',
  'resourceplanner.role.view',
  'resourceplanner.role.view.first.level.limit',
  'resourceplanner.role.view.rows.limit',
  'resourceplanner.roleview.set.planned.to.budgeted.proj',
  'resourceplanner.roleview.set.planned.to.budgeted.role',
  'resourceplanner.roleview.set.planned.to.budgeted.proj.success',
  'resourceplanner.roleview.set.planned.to.budgeted.role.success',
  'resourceplanner.roleview.set.planned.to.budgeted.role.success.new',
  'resourceplanner.saved',
  'resourceplanner.settings',
  'resourceplanner.settings.customize.columns',
  'resourceplanner.settings.save.warning',
  'resourceplanner.settings.saved.successfully',
  'resourceplanner.show.actual.hours',
  'resourceplanner.show.actual.hours.description',
  'resourceplanner.total',
  'resourceplanner.under.allocation.message',
  'resourceplanner.user.budget',
  'resourceplanner.user.view',
  'resourceplanner.user.view.first.level.limit',
  'resourceplanner.user.view.second.level.limit',
  'resourceplanner.views.limit.description',
  'resourceplanner.variance',
  'resourceplanner.variance.abbr',
  'resourceplanner.variance.description',
  'resourceplanner.view.type',
  'resourceplanner.visualization.tip.text',
  'resourceplanner.week.number',
  'resourcepools',
  'role.abbr',
  'share.copied',
  'startdate',
  'statusequateswith',
  'type',
  'user',
  'viewbuilder.row',
  'week',
  'week.lowercase',
  'widgets.typeahead.novalue',
  'resourceplanner.prev',
  'resourceplanner.next',
  'resourceplanner.today',
  ...MonthsMessageKeys
];
