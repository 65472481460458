/**
 * Created by anihambardzumyan on 5/25/17.
 */
import {observable} from 'mobx';

const state = observable({
  visible: false,
  onConfirm: undefined,
  messageKey: 'action.save.and.switch.hint',
  isCanceled: false
});

const showSaveDialog = (onConfirm, messageKey = state.messageKey) => {
  state.visible = true;
  state.onConfirm = onConfirm;
  state.messageKey = messageKey;
};

const hideSaveDialog = () => {
  state.visible = false;
  state.onConfirm = undefined;
  state.messageKey = 'action.save.and.switch.hint';
};

export default {
  state,
  hideSaveDialog,
  showSaveDialog
}
