/**
 * Created by iskuhihakobyan on 5/16/17.
 */
import React from 'react';
import {Localization} from '../../localization/localization';
import styled from 'react-emotion';
import noAccessIcon from '../../images/s-lg.png';


export default function NoAccessComponent() {
  return (
    <NoAccess>
      <NoAccessTextBlock >
        <div><Localization messageKey="resourceplanner.no.access.message"/></div>
        <div><Localization messageKey="resourceplanner.no.access.description"/></div>
      </NoAccessTextBlock>
    </NoAccess>
  );
}

const NoAccessTextBlock = styled('div')`
    background-image: url('${noAccessIcon}');
    background-position: -11px -1289px;
    background-repeat: no-repeat;
    height: 120px;
    min-width: 200px;
    padding-left: 150px;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    padding-top: 30px;
    font-size: 12px;

    div:first-child {
      font-size: 14px;
      font-weight: bold;
    }
`;

const NoAccess = styled('div')`
    display: flex;
    height: calc(100vh - 52px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
