import React, {Component} from 'react';
import _ from 'lodash';
import LoadingRow from './LoadingRow';
import {HOUR_ROW_HEIGHT} from '../../constants/StyleConstants';
import {observer} from 'mobx-react';

@observer
export default class LoadingBodyItem extends Component {
  //noinspection JSAnnotator
  props:{
    nodeItemsCount: Number,
    height: Number
  };

  constructor(props) {
    super(props);
  }

  render() {
    const loadingRows = [],
      verticalRepeatCount = this.props.nodeItemsCount || Math.floor(this.props.height / HOUR_ROW_HEIGHT);

    _.times(verticalRepeatCount, key => {
      loadingRows.push(<LoadingRow rowType="body" key={key}/>);
    });

    return (
      <div className="interval-hours">
        <div className="hour-row-collection">
          {loadingRows}
        </div>
      </div>
    );
  }
}


