/*@flow*/
import {Component} from 'react';

export default class If extends Component {
  //noinspection JSAnnotator
  props: {
    condition: ?boolean;
  };

  static defaultProps = {
    condition: false
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.condition !== this.props.condition || this.props.condition;
  }

  render() {
    const {children, condition} = this.props;

    return condition ? children : null;
  }
}
