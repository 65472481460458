/**
 * Created by iskuhihakobyan on 4/5/17.
 */
import IntervalsModel from '../models/IntervalsModel';
import ViewModeStore from '../../shared/stores/ViewModeStore';
import {NextPreviousEnum} from '../../shared/constants/NextPreviousEnum';
import {DATE_FORMAT} from '../../shared/constants/MonthsEnum';
import moment from 'moment';
import {observable} from 'mobx';

export default class IntervalsStore {
  visibleIntervalsFrom;
  @observable intervals = [];

  //fromData for week/month/quarter view change for preserving the current
  constructor(fromDate = null) {
    this.visibleIntervalsFrom = fromDate || this.getCurrentDate().format(DATE_FORMAT);
  }

  getCurrentDate() {
    return moment(new Date()).locale('en').startOf(ViewModeStore.activeStep.key);
  }

  addIntervals(intervals) {
    intervals.forEach(interval => {
      this.intervals.push(new IntervalsModel(interval.from, interval.to, interval.fth));
    });
  }

  addRemoveIntervals(nextPrevious, fromDate, stepCount) {
    let methodRemove;
    let methodAdd;

    if (nextPrevious === NextPreviousEnum.next) {
      methodRemove = 'shift';
      methodAdd = 'push';
    } else {
      methodRemove = 'pop';
      methodAdd = 'unshift';
    }

    for (let i = 1; i <= stepCount; i++) {
      fromDate = fromDate.add(nextPrevious, ViewModeStore.activeStep.key);
      let toStr = fromDate.clone().add(1, ViewModeStore.activeStep.key).format(DATE_FORMAT);

      this.intervals[methodRemove]();
      this.intervals[methodAdd](new IntervalsModel(fromDate.format(DATE_FORMAT), toStr));
    }
  }

  clearIntervalStore() {
    this.intervals = [];
    this.visibleIntervalsFrom = null;
  }
}
