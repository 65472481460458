import React, {Component} from 'react';
import {HourViewHours, ViewList} from '../../constants/ViewEnums';
import ViewModeStore from '../../stores/ViewModeStore';
import {Localization} from '../../../localization/localization';
import AnalyticsService from '../../../analytics/AnalyticsService';
import DropDown from '../DropDown';
import DropDownList from '../DropDownList';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {loadingStore} from '../../stores/LoadingStore';
import {getTrackNameForPendoAnalytics} from '../../services/Utilities';

@observer
export default class ViewMode extends Component {
  changedActiveView;

  // noinspection JSAnnotator
  props: {
    disabled: Boolean,
    showSaveDialog: Function,
    treeNodeStore: Object,
    toggleCallback: Function,
    intervalStore: Object,
    loadStoreData: Function
  };

  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.changeViewAction = this.changeViewAction.bind(this);
  }

  @action changeViewAction() {
    loadingStore.loading = true;
    AnalyticsService.trackResourcePlannerEvent(`View changed from ${ViewModeStore.activeView.key} to ${this.changedActiveView.key}`);
    this.props.treeNodeStore.clearTreeNodesStore();
    this.props.intervalStore.clearIntervalStore();

    ViewModeStore.activeView = this.changedActiveView;

    if (ViewModeStore.isCustomViewMode && ViewModeStore.customColumnCount === 0) {
      ViewModeStore.hourViewMode = HourViewHours;
    }

    this.props.loadStoreData();
    this.props.toggleCallback(false);
  }

  trackViewModeChange = (item) => {
    AnalyticsService.pendoAnalyticsTracker(
      getTrackNameForPendoAnalytics(ViewModeStore, `View change from ${ViewModeStore.activeView.key} to ${item.key}`)
    );
  };

  changeView(item) {
    this.trackViewModeChange(item);
    this.changedActiveView = item;

    if (this.props.treeNodeStore.unSavedChangesExists) {
      this.props.showSaveDialog(this.changeViewAction);
    } else {
      loadingStore.loading = true;
      this.changeViewAction(item);
    }
  }

  render() {
    return (
      <div className="view-mode-button rp-has-dropdown" data-wf-popover-container>
        <DropDown selectedName={<Localization messageKey={ViewModeStore.activeView.messageKey}/>}
                  disabled={this.props.disabled}>
          <DropDownList listItems={ViewList} handleClick={this.changeView}
                        activeItem={ViewModeStore.activeView} ref={(node) => this.dropDownListNode = node}/>
        </DropDown>
      </div>
    )
  }
}
