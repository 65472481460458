/**
 * Created by anihambardzumyan on 8/1/17.
 */
export const FILTER_TYPE = 'PLANNER';

export const excludeGroupItems = {
  project: [
    'teamID',
    'auditTypes',
    'projectUserIDs',
    'roleIDs',
    'resourcePoolIDs',
    'resourceManagerIDs'
  ],
  user: [
    'roleIDs'
  ],
  portfolio: [
    'groupIDs',
    'auditTypes'
  ],
  program: [
    'portfolioOwnerID',
    'auditTypes',
    'portfolioEnteredByID'
  ],
  task: [
    'approvalRequired',
    'projectOwnerID',
    'projectPortfolioID',
    'projectProgramID',
    'projectSponsorID',
    'projectUserIDs',
    'auditTypes'
  ],
  opTask: [
    'projectGroupID',
    'projectOwnerID',
    'projectPortfolioID',
    'projectProgramID',
    'projectUserIDs',
    'auditTypes'
  ]
};

export const excludeGroups = [
  undefined,
  'assignment',
  'owner',
  'lastUpdatedBy',
  'notes',
  'objectCategoriesMM',
  'accessLevelMM',
  'alignmentScoreCard',
  'category',
  'enteredBy',
  'customer',
  'template',
  'popAccount',
  'sponsor',
  'currentApprovalStep',
  'assignments',
  'opTasksOM',
  'projectUserRolesRolesMM',
  'openOpTasks',
  'projectUsersMM',
  'allHoursOM',
  'exchangeRate',
  'rejectionIssue',
  'lastConditionNote',
  'resourcePool',
  'submittedBy',
  'defaultBaseline',
  'queueDef',
  'deliverableScoreCard',
  'favoritedByUsersMM',
  'lastNote',
  'milestonePath',
  'allDocumentsOM',
  'awaitingApprovalStepApproversMM',
  'allNotesOM',
  'schedule',
  'sharingSettings',
  'approvalProcess',
  'projectUserRolesUsersM',
  'poolsMM',
  'role',
  'team',
  'group',
  'lastStatusNote',
  'roles',
  'ssoOption',
  'homeGroup',
  'projectsMM',
  'portalProfilePortalTabsMM',
  'highPriorityWorkItem',
  'lastEnteredNote',
  'homeTeam',
  'delegationTo',
  'timesheetProfile',
  'templatesMM',
  'manager',
  'accessLevel',
  'defaultHourType',
  'assignmentsProjectsMM',
  'externalUsername',
  'portalProfile',
  'latestUpdateNote',
  'otherGroups',
  'layoutTemplate',
  'userNotes',
  'licenseTypeLimit',
  'parent',
  'groupOwnersMM',
  'assignedTo',
  'assignmentsRolesMM',
  'assignmentsUsersMM',
  'billingRecord',
  'convertedOpTask',
  'convertedOpTaskOriginator',
  'currentRoleApproversMM',
  'currentUserApproversMM',
  'defaultBaselineTask',
  'iteration',
  'journalEntries',
  'kanbanBoard',
  'milestone',
  'predecessorsMM',
  'primaryAssignment',
  'projectCompanyMM',
  'projectMilestonePathMM',
  'projectOwnerMM',
  'projectUserRolesOM',
  'projectUsersOM',
  'recurrenceRule',
  'reservedTime',
  'successorsMM',
  'templateTask',
  'workItem',
  'ownerCompanyMM',
  'groupMM',
  'projectPortfolioMM',
  'queueDefOO',
  'queueTopic',
  'resolveOpTask',
  'resolveProject',
  'resolveTask',
  'sourceTask',
  'projectUserRolesUsersMM',
  'assignmentsTasksMM',
  'assignmentsOpTasksMM',
  'taskDef',
  'issueDef',
  'unassignedTasks',
  'initiative',
  'goalsMM'
];
