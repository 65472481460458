/**
 * Created by artakpoghosyan on 7/16/18.
 */
import {observable} from 'mobx';

export const exportStore = observable({
  isDialogVisible: false,
  canExport: true,
  exporting: false
});
