/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import ObjectModel from './ObjectModel';

export default class ObjectTaskIssueModel extends ObjectModel {

  constructor(objCode, ID, name, intervals) {
    super(objCode, ID, name, false, intervals);

    this.showThreeDots = false;
  }
}
