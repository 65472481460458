import {observable} from 'mobx';

const overlay = observable({
  visible: false,
  fullScreen: false
});

const showOverlay = (full = false) => {
  overlay.visible = true;
  overlay.fullScreen = full;
};

const hideOverlay = () => {
  overlay.visible = false;
  overlay.fullScreen = false;
};

export default {
  overlay,
  showOverlay,
  hideOverlay
}
