const tooltipOptions = {
  alignment: 'middle',
  attachTo: 'container',
  tweakAlignment: false,
  tweakPosition: false
};

export const standardTooltipOptions = {
  position: 'bottom',
  className: 'toolTip',
  offset: 10,
  ...tooltipOptions
};

export const topTooltipOptions = {
  position: 'top',
  className: 'top-toolTip',
  offset: 10,
  ...tooltipOptions
};

export const infoTooltipOptions = {
  position: 'top',
  className: 'info-tooltip',
  ...tooltipOptions
};

//this is used in old planner
export const limitInfoTooltipOptions = {
  position: 'left',
  className: 'info-limit-tooltip',
  alignment: 'end',
  attachTo: 'container',
  tweakAlignment: false,
  tweakPosition: false
};
