/**
 * Created by anihambardzumyan on 8/8/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {ItemList} from '@workfront/react-item-list';
import {Ellipse} from '@phoenix/all';
import {Localization} from '../../localization/localization';
import {getResourcePlannerArea} from '../services/Utilities';
import ViewModeStore from '../stores/ViewModeStore';

@observer
export default class DropDownList extends Component {
  itemListNode;

  //noinspection JSAnnotator
  props: {
    popup: Object,
    listItems: Array,
    activeItem: Object,
    handleClick: Function
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(item) {
    this.props.popup.hide();

    if (this.props.activeItem.key === item.key) {
      return null;
    }

    this.props.handleClick(item);
  }

  focusItemList() {
    if (this.itemListNode) {
      this.itemListNode.focus()
    }
  }

  render() {
    return (
      <ItemList items={this.props.listItems} ref={(node) => this.itemListNode = node}>
        {
          (item, index, isActive) => item.visible !== false ? (
            <li key={item.key}
                data-test-id={`${item.key.toLowerCase()}-${getResourcePlannerArea(ViewModeStore)}`}
                onClick={() => this.handleClick(item)}
                className={`${this.props.activeItem.key === item.key ? 'selected' : ''} ${isActive ? 'active' : ''} ${item.key === 'customize' ? 'customize' : ''}`}>
              <Ellipse tooltipPosition="right-start">
                <Localization messageKey={item.messageKey}/>
              </Ellipse>
          </li>): <li style={{display: 'none'}}></li>
        }
      </ItemList>
    );
  }
}
