import {apiDateToDate} from '@workfront/fns';
import moment from 'moment';
import {loadingOptions} from '../constants/LoadingOptions';
import notificationStore from '../stores/NotificationStore';
import {redrockClient} from '../../localization/localization';

const isEmptyString = (string) => {
  return string === '';
};

const roundNumber = (number, roundPrecision = 100) => {
  return Math.round(number * roundPrecision) / roundPrecision;
};

const endsWithPointOrPointAndZero = (string) => {
  //if empty string or ends with point or zero, do not round
  return /^\d{1,8}\.(\d*[0]+){0,2}$/.test(string);
};

const startsWithPoint = (string) => {
  return /^\.(\d){0,2}$/.test(string);
};

const generateRandomNumber = () => {
  return Math.floor((Math.random() * 1000000) + 1);
};

const isValidDecimalNumber = (number, {digitsCountBeforePoint, digitsCountAfterPoint}) => {
  var regex = new RegExp('^\\d{1,' + digitsCountBeforePoint + '}(\\.\\d{0,' + digitsCountAfterPoint + '})?$');
  return regex.test(number);
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const isDeletePressed = (newValue, oldValue, digitsCount) => {
  return !isValidDecimalNumber(oldValue, digitsCount) && `${newValue}`.length < `${oldValue}`.length && isNumeric(newValue);
};

const isValidIntegerNumber = (number) => {
  var regex = /^(\s*|\d+)$/;
  return regex.test(number);
};

const getWidthByDigitsCount = (number) => {
  let width = number.toString().length * 9;
  return width < 18 ? 18 : width;
};

//can be passed both ID and node as 'node' argument
const isNodeIdDefined = (node) => {
  //when node is passed
  if (typeof node === 'object' && node.hasOwnProperty('ID')) {
    return node.ID !== '-1';
  }

  //when ID is passed
  if (typeof node === 'string') {
    return node !== '-1';
  }
};

const setObjectToLocalStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};

const getObjectFromLocalStorage = (key) => {
  if (localStorage[key]) {
    return tryParseJSON(localStorage[key]);
  }

  return false;
};

const containsSpecialSymbols = (label) => {
  var rexgExp = /[<>%$=+*&#.@!?()"'`\\^]/;
  return rexgExp.test(label);
};

function safeAPIDateToDate(date) {
  return moment(apiDateToDate(date) || date)
}

const converter = (date) => {
  return moment(safeAPIDateToDate(date));
};

const formatDate = (date) => {
  return moment(safeAPIDateToDate(date)).format('YYYY-MM-DD');
};

const tryParseJSON = (jsonString) => {
  let parsedObject;
  try {
    parsedObject = JSON.parse(jsonString);
  }
  catch (e) {
    parsedObject = {};
  }

  return parsedObject;
};

const notify = {
  handle: 0,
  start: function () {
    this.stop();
    this.handle = setTimeout(() => {
      notificationStore.showNotification(redrockClient.getTextSync('longwait'), 'info', 0);
    }, loadingOptions.longWaitTimeOut);
  },
  stop: function () {
    if (this.handle) {
      clearTimeout(this.handle);
      notificationStore.clearNotification();
      this.handle = 0;
    }
  }
};

const regExpForID = (IDExp) => {
  return new RegExp(`^${IDExp}.*$`);
};

const getResourcePlannerArea = (viewModeStore) => {
  if (viewModeStore.isOpenedFromBusinessCase) {
    return 'businessCase';
  } else if (viewModeStore.urlGeneratorVisible === false) {
    return 'uniqueURL';

  } else {
    return 'globalPlanner';
  }
};

const getDataTestIdForButtons = (viewModeStore, buttonType) => {
  return `${buttonType}-${getResourcePlannerArea(viewModeStore)}-${viewModeStore.view.key}-${viewModeStore.step.key}`
};

export const getTrackNameForPendoAnalytics = (viewModeStore, actionType) => {
  const classicOrQS = viewModeStore.isQuickSilver ? 'NWE' : 'Classic';
  return `VB - ${getResourcePlannerArea(viewModeStore)}: ${classicOrQS} - ${actionType}`
};

const getDataTestIdForBDGAdjustments = (viewModeStore, buttonType) => {
  return `${buttonType}-${getResourcePlannerArea(viewModeStore)}-${viewModeStore.step.key}`
};
getDataTestIdForBDGAdjustments
// TODO change and review in tests
const utilities = {
  setObjectToLocalStorage,
  getObjectFromLocalStorage,
  endsWithPointOrPointAndZero,
  startsWithPoint,
  getWidthByDigitsCount,
  isEmptyString,
  isNodeIdDefined,
  isValidDecimalNumber,
  isValidIntegerNumber,
  roundNumber,
  containsSpecialSymbols,
  safeAPIDateToDate,
  tryParseJSON,
  formatDate,
  converter,
  regExpForID,
  isDeletePressed,
  generateRandomNumber,
  notify
};

export {
  utilities as default,
  setObjectToLocalStorage,
  getObjectFromLocalStorage,
  endsWithPointOrPointAndZero,
  startsWithPoint,
  getWidthByDigitsCount,
  isEmptyString,
  isNodeIdDefined,
  isValidDecimalNumber,
  isValidIntegerNumber,
  roundNumber,
  containsSpecialSymbols,
  safeAPIDateToDate,
  tryParseJSON,
  formatDate,
  converter,
  regExpForID,
  isDeletePressed,
  generateRandomNumber,
  getResourcePlannerArea,
  getDataTestIdForButtons,
  getDataTestIdForBDGAdjustments,
  notify
}
  ;
