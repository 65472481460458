// Libraries
import _ from 'lodash';
// Models
import ObjectModel from './ObjectModel';
// Constants
import {Project as ProjectObjCode} from 'workfront-objcodes';
// Services
import {isNodeIdDefined} from '../../../shared/services/Utilities';
import {COST_VIEW_KEY} from '../../../shared/constants/ViewEnums';

export default class ObjectProjModel extends ObjectModel {
  constructor(ID, name, nodes, parentID, plannedStartDate, plannedCompletionDate, editBudgeting = true) {
    super(ProjectObjCode, ID, name, nodes.length);
    this.nodes = nodes;
    this.plannedStartDate = plannedStartDate;
    this.plannedCompletionDate = plannedCompletionDate;
    this.parentID = parentID;
    this.editBudgeting = editBudgeting;

    //three dots properties
    this.showSetPlannedHourToBudgetedLink = this.showEquateBudgetedFromUserLink = editBudgeting && isNodeIdDefined(parentID) && nodes.length;
    this.showThreeDots = this.showSetPlannedHourToBudgetedLink ;

    //HOUR
    this.showOnlyPlannedHour = _.every(this.nodes, nodeItem => nodeItem.showOnlyPlannedHour);
  }

  calculateBDG(node, sumNodesPLN, costRate, value) {
    if (sumNodesPLN === 0) {
      return costRate ? (value / this.nodes.length) / costRate : 0;
    } else {
      return costRate ? node.PLNHour / sumNodesPLN * (value / costRate) : 0;
    }
  }

  calculateBDGFTE(node, sumNodesPLNFTE, value) {
    if (sumNodesPLNFTE === 0) {
      return value / this.nodes.length;
    } else {
      return node.PLNFTE / sumNodesPLNFTE * value;
    }
  }

  calculateBDGCost(node, sumNodesPLNCost, costRate, value) {
    if (sumNodesPLNCost === 0) {
      return costRate ? (value / this.nodes.length) / costRate : 0;
    } else {
      return costRate ? node.PLNCost / sumNodesPLNCost * (value / costRate) : 0;
    }
  }

  getSumOfNodesPLN(i) {
    return this.nodes.reduce((sum, node) => sum + node[i].PLNHour, 0);
  }

  getSumOfNodesPLNFTE(i) {
    return this.nodes.reduce((sum, node) => sum + node[i].PLNFTE, 0);
  }

  getSumOfNodesPLNCost(i) {
    return this.nodes.reduce((sum, node) => sum + node[i].PLNCost, 0);
  }

  setBDGHour(value, i) {
    let sumNodesPLN = this.getSumOfNodesPLN(i);
    const costRate = this.getCostRate(i);

    this.nodes.forEach(node => {
      node.setPBDGHour(this.calculateBDG(node[i], sumNodesPLN, costRate, value), i)
    });
  }

  setBDGFTE(value, i) {
    let sumNodesPLNFTE = this.getSumOfNodesPLNFTE(i);

    this.nodes.forEach(node => {
      node.setPBDGFTE(this.calculateBDGFTE(node[i], sumNodesPLNFTE, value), i)
    });
  }

  setBDGCost(value, i) {
    let sumNodesPLNCost = this.getSumOfNodesPLNCost(i);
    const costRate = this.getCostRate(i, COST_VIEW_KEY);

    this.nodes.forEach(node => {
      node.setPBDGCost(this.calculateBDGCost(node[i], sumNodesPLNCost, costRate, value), i)
    });
  }

  setPBDGHourFromRole(value, i, allNodesIsLoaded) {
    let sumNodesPLN = this.getSumOfNodesPLN(i);
    const costRate = this.getCostRate(i);

    this.nodes.forEach(node => {
      node.setPBDGHourFromRole(this.calculateBDG(node[i], sumNodesPLN, costRate, value), i, allNodesIsLoaded)
    });
  }

  setPBDGFTEFromRole(value, i, allNodesIsLoaded) {
    let sumNodesPLNFTE = this.getSumOfNodesPLNFTE(i);

    this.nodes.forEach(node => {
      node.setPBDGFTEFromRole(this.calculateBDGFTE(node[i], sumNodesPLNFTE, value), i, allNodesIsLoaded)
    });
  }

  setPBDGCostFromRole(value, i, allNodesIsLoaded) {
    let sumNodesPLNCost = this.getSumOfNodesPLNCost(i);
    const costRate = this.getCostRate(i, COST_VIEW_KEY);

    this.nodes.forEach(node => {
      node.setPBDGCostFromRole(this.calculateBDGCost(node[i], sumNodesPLNCost, costRate, value), i, allNodesIsLoaded)
    });
  }
}
