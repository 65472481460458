/**
 * Created by iskuhihakobyan on 5/16/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Localization} from '../../localization/localization';
import ViewModeStore from '../stores/ViewModeStore';
import {loadingStore} from '../stores/LoadingStore';
import If from 'If';
import styled from 'react-emotion';
import {colorSecondaryText} from '@workfront/kashyyyk-theme';

@observer
export default class NoResults extends Component {
  //noinspection JSAnnotator
  props: {
    filterMessage: Boolean,
  };

  static defaultProps = {
    filterMessage: false,
  };

  render() {
    return (
      <NoResultsStyled className="rp-noResults">
        <If condition={loadingStore.clipped}>
          <h3>
            <Localization messageKey={`resourceplanner.${ViewModeStore.activeView.key}.view.rows.limit`}/>
            <br/>
            <Localization messageKey="resourceplanner.views.limit.description"/>
          </h3>
        </If>
        <If condition={!this.props.filterMessage && !loadingStore.clipped}>
          <div>
            <h3><Localization messageKey={`resourceplanner.no.${ViewModeStore.activeView.key}.found`}/></h3>
            <If condition={ViewModeStore.activeView.key !== 'user'}>
              <p><Localization messageKey={`resourceplanner.no.${ViewModeStore.activeView.key}.to.see.information.new`}/></p>
            </If>
          </div>
        </If>
        <If condition={this.props.filterMessage && !loadingStore.clipped}>
          <div>
            <h3><Localization messageKey={`resourceplanner.no.${ViewModeStore.activeView.key}.found`}/></h3>
            <p><Localization messageKey={'resourceplanner.no.filter.results'}/></p>
          </div>
        </If>
      </NoResultsStyled>
    );
  }
}

const NoResultsStyled = styled('div')`
    text-align: center;
    padding: 120px 32px 0;

    h3 span {
      color: ${colorSecondaryText};
      font-size: 18px;
      font-weight: 400;
    }

    p span {
      color: ${colorSecondaryText};
      font-size: 14px;
    }
`;
