import { PrimaryButton, TextButton } from '@wf-titan/react-button';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Localization } from '../../localization/localization';
import { getDataTestIdForButtons } from '../services/Utilities';
import { loadingStore } from '../stores/LoadingStore';
import ViewModeStore from '../stores/ViewModeStore';
import { recalculateFilterHeight } from '@wf-titan/react-filter-group';

export default function ResourcePlannerButtonsContainer({ onSave, onCancel, saveCallbackHandler }) {

  useEffect(() => {
    recalculateFilterHeight();

    return () => {
      recalculateFilterHeight();
    };
  }, []);

  return (
    <div
      className={`${
        ViewModeStore.isQuickSilver
          ? 'button-container-wrapper button-container-wrapper-qs'
          : 'button-container-wrapper'
      }`}
    >
      <div className={'button-container'}>
        <Localization messageKeys={['action.save', 'action.saving']}>
          {(messages) => (
            <PrimaryButton
              onClick={() => onSave(saveCallbackHandler)}
              className="btn-primary"
              id="btn-resource-planner-save"
              disabled={ViewModeStore.hasViewAccess || loadingStore.saveInProcess}
              data-testid={`${getDataTestIdForButtons(ViewModeStore, 'saveButton')}`}
            >
              {loadingStore.saveInProcess ? messages[1]() : messages[0]()}
            </PrimaryButton>
          )}
        </Localization>
        <TextButton
          onClick={onCancel}
          className="cancel-button"
          data-test="cancel-button"
          disabled={loadingStore.saveInProcess}
        >
          <Localization messageKey="action.cancel" />
        </TextButton>
      </div>
    </div>
  );
}

ResourcePlannerButtonsContainer.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveCallbackHandler: PropTypes.func,
};
