// Libraries
import React, {Component} from 'react';
import {observable, reaction, runInAction, when} from 'mobx';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {createFilterObject, FilterProvider} from 'titan/react-filter-group';
// Components
import ResourcePlannerContainer from './ResourcePlannerContainer';
import ResourcePlannerHeader from './header/ResourcePlannerHeader';
import ConfirmationDialog from './ConfirmationDialog';
import Filter from './filter/Filter';
import NoAccessComponent from './NoAccess';
import If from 'If';
// Stores
import UserTreeNodeStore from '../../userView/stores/TreeNodesStore';
import RoleTreeNodeStore from '../../roleView/stores/TreeNodesStore';
import ChangedHoursStore from '../../roleView/stores/ChangedHoursStore';
import ProjectTreeNodeStore, {filteredOutStore} from '../../projectView/stores/TreeNodesStore';
import UserIntervalsStore from '../../userView/stores/IntervalsStore';
import ProjectRoleIntervalsStore, {intervalsFTEs} from '../stores/IntervalsStore';
import ViewModeStore, {CurrencyConfig} from '../stores/ViewModeStore';
import FilterStore from '../stores/FilterStore';
import {loadingStore} from '../stores/LoadingStore';
import {exportStore} from '../stores/ExportStore';
import ScrollStore from '../stores/ScrollStore';
import SaveDialogStore from '../stores/SaveDialogStore';
import VisualizationStore from '../../userView/stores/VisualizationStore';
// Services
import APIService from '../services/APIService';
import userViewDataService from '../../userView/services/DataService';
import WindowSize from '../services/DefineWindowSize';
import AnalyticsService from '../../analytics/AnalyticsService';
import {getResourcePlannerArea, getTrackNameForPendoAnalytics} from '../services/Utilities';
// Constants
import {ViewPortSizeNames} from '../constants/ViewPortEnum';
import {HourViewCost, HourViewCustom, HourViewFTE, HourViewHours, ViewEnum, ViewSteps} from '../constants/ViewEnums';
import {viewLimits} from '../constants/LimitConstants';
import {IDExpOptions} from '../constants/ExpressionOptions';
import {RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT} from '../constants/StyleConstants';
import {DATE_FORMAT} from '../constants/MonthsEnum';
import {FILTER_TYPE} from '../constants/FilterOptions';
// Styles
import '../../styles/index.less';
import {recalculateFilterHeight} from '@wf-titan/react-filter-group';
import SettingsStore from '../stores/SettingsStore';
import { trackLaunchCustomEvent } from '@wf-mfe/logger';

@observer
export default class ResourcePlanner extends Component {
  static defaultProps = {
    cancelCallbackHandler: () => {
    },
    className: '',
    enablePoolSelection: false,
    filterVisible: true,
    fullMode: false,
    fullModeButtonVisible: false,
    hidePriority: false,
    viewModeVisible: true,
    visualizationVisible: true,
    saveCallbackHandler: () => {},
    settingsVisible: true,
    step: '',
    stepCount: 0,
  };

  //noinspection JSAnnotator
  @observable treeNodeStore = null;
  @observable intervalStore = null;
  @observable reRenderTable = false;
  @observable filterStore;
  @observable showPoolSelection = false;
  @observable showResourcePlanner = true;
  @observable noAccess = false;
  rowsCountInPage = 0;

  //noinspection JSAnnotator
  props: {
    access: ?String,
    cancelCallbackHandler: Function,
    className: ?String,
    enablePoolSelection: Boolean,
    filterVisible: ?Boolean,
    filterID: ?String,//selectedFilterID from unique url generation
    fullMode: ?Boolean,
    fullModeButtonVisible: ?Boolean,
    hidePriority: ?Boolean,
    modeFTE: ?String,
    noAccess: ?Boolean,
    projectID: ?String,
    saveCallbackHandler: ?Function,
    settingsVisible: ?Boolean,
    step: ?String,
    stepCount: Number,
    viewModeVisible: ? Boolean,
    visualizationVisible: ? Boolean,
    urlGeneratorVisible: ? Boolean,
    showProjectPriorityAlign: Boolean,
    isInInstantLoadingState: ?Boolean,
    projectPools: Array,
    mfeMigrationEnabled: ?Boolean,
    qs:?Boolean,
    isSecondaryNavOpened:?Boolean,
    customColumns: ?String
  };

  constructor(props) {
    super(props);

    this.loadInitialData = this.loadInitialData.bind(this);
    this.loadNextPrevData = this.loadNextPrevData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.applyFilterAction = this.applyFilterAction.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.loadMissingData = this.loadMissingData.bind(this);
    this.loadVisualizationData = this.loadVisualizationData.bind(this);
    this.filterToggleCallback = this.filterToggleCallback.bind(this);
    this.onResourcePoolSave = this.onResourcePoolSave.bind(this);
    this.closeResourcePlanner = this.closeResourcePlanner.bind(this);
    this.fullModeToggle = this.fullModeToggle.bind(this);
    this.getHourViewMode = this.getHourViewMode.bind(this);
    this.fullScreenElement = React.createRef();
    this.analyzingTimeout = React.createRef();

    this.showPoolSelection = !!(props.enablePoolSelection && props.projectID);
    this.showResourcePlanner = !this.showPoolSelection;

    ViewModeStore.urlGeneratorVisible = props.urlGeneratorVisible;
    ViewModeStore.filterVisible = props.filterVisible;
    ViewModeStore.viewModeVisible = props.viewModeVisible;
    ViewModeStore.settingsVisible = props.settingsVisible;
    ViewModeStore.visualizationVisible = props.visualizationVisible;
    ViewModeStore.stepCount = props.stepCount;
    ViewModeStore.hidePriority = props.hidePriority;
    ViewModeStore.projectID = props.projectID;
    ViewModeStore.isQuickSilver = props.qs;
    ViewModeStore.isOpenedFromBusinessCase = props.className === 'resource-planner-business-case';

    if (props.customColumns) {
      ViewModeStore.isCustomColumnFromLink = true;
      SettingsStore.customColumnOptions['resource_planner.customColumns'].value = props.customColumns;
      SettingsStore.customColumnOptions['resource_planner.customColumns'].updates = props.customColumns;
    }

    if (props.noAccess) {
      this.noAccess = props.noAccess;
      this.showResourcePlanner = false;
    }

    const modeFTEObject = _.find(ViewModeStore.activeHourViewList, ({key}) => key === props.modeFTE);
    if (modeFTEObject) {
      ViewModeStore.FTEView = modeFTEObject;
    }

    if (ViewSteps[props.step]) {
      ViewModeStore.step = ViewSteps[props.step];
    }

    ScrollStore.scrollStore.fullMode = props.fullMode;
    ScrollStore.scrollStore.fullModeButtonVisible = props.fullModeButtonVisible;

    if (ViewModeStore.activeView.key === ViewEnum.role) {
      this.treeNodeStore = new RoleTreeNodeStore();
      this.intervalStore = new ProjectRoleIntervalsStore();
    } else if (ViewModeStore.activeView.key === ViewEnum.user) {
      this.treeNodeStore = new UserTreeNodeStore();
      this.intervalStore = new UserIntervalsStore();
    } else {
      this.treeNodeStore = new ProjectTreeNodeStore();
      this.intervalStore = new ProjectRoleIntervalsStore();
    }

    // add or change treeNodeStore.intervalsIndexes

    reaction(() => this.intervalStore.intervals.map(({index, from}) => {
        return {index, from}
      }),
      (indexArray) => {
        if (ViewModeStore.activeView.key !== ViewEnum.user) {
          this.treeNodeStore.intervalsIndexes = indexArray;
        }
      }
    );

    this.filterStore = new FilterStore();
    ViewModeStore.intervalsCount = ViewModeStore.stepCount || (WindowSize() === ViewPortSizeNames.large ? 4 : 3);
  }

  componentDidUpdate(prevProps){
    const {isSecondaryNavOpened} = this.props;
    if(prevProps.isSecondaryNavOpened !== isSecondaryNavOpened){
      ViewModeStore.isSecondaryNavOpened = isSecondaryNavOpened;
      ScrollStore.calculateVirtualizedTableWidth()
    }
  }

  extractFilterRules(obj) {
    return Object.keys(obj).filter(key => key.includes('_Mod'))
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
  }

  componentDidMount() {
    if (this.showResourcePlanner) {
      APIService.getFilters().then((result) => {
        let promises = [APIService.getProjectStatuses()];

        if (this.props.filterID) {
          this.filterStore.uniqueUrlFilterID = this.props.filterID;
        }

        //selectedFilterID is a getter which is use uniqueUrlFilterID and localStorage for generation
        if (_.find(result.result, f => f.ID === this.filterStore.selectedFilterID)) {
          promises.push(APIService.getFilterByID(this.filterStore.selectedFilterID));
        }

        Promise.all(promises).then((resultArr) => {
          runInAction(() => {
            this.filterStore.fillStatusList(resultArr[0].data);
            this.filterStore.generateDefaultFilter(ViewModeStore.intervalsCount);
          });

          if (resultArr[1] && resultArr[1].length) {
            this.filterStore.selectedFilterExpression = createFilterObject(JSON.parse(resultArr[1][0].expression));
          } else {
            this.filterStore.selectedFilterExpression = createFilterObject(JSON.parse(this.filterStore.defaultFilter.expression));
          }

          this.loadInitialData();
          this.rowsCountInPage = ScrollStore.getNodeRowsCountInPage();
          const filterRules = this.extractFilterRules(this.filterStore.selectedFilterExpression);
          this.analyzingTimeout.current = setTimeout(() => {
              trackLaunchCustomEvent('Analyzing Resources In RP', {
                'resourceMgmnt': {
                  filterRuleRp: JSON.stringify(filterRules).toLowerCase(),
                  intervalTypeRp: ViewModeStore.activeStep.key && ViewModeStore.activeStep.key.toLowerCase(),
                  prioritiesDisplayed: SettingsStore && SettingsStore.options['resource_planner.globalPriorities'].value,
                  sourceNameRp: ViewModeStore.isOpenedFromBusinessCase ? 'business Case' : 'resourcing',
                  viewByRp: ViewModeStore.view.key && ViewModeStore.view.key.toLowerCase(),
                },
              });
            }, 45000);
        });
      });

      document.addEventListener('webkitfullscreenchange', this.handleFullScreenToggling, false);
      document.addEventListener('mozfullscreenchange', this.handleFullScreenToggling, false);
      document.addEventListener('fullscreenchange', this.handleFullScreenToggling, false);
      document.addEventListener('MSFullscreenChange', this.handleFullScreenToggling, false);

      if (!ViewModeStore.isCustomColumnFromLink) {
        this.loadCustomColumns().then((result) => {
          if (result && result[0] && result[0].value) {
            SettingsStore.customColumnOptions['resource_planner.customColumns'].value = JSON.parse(result[0].value);
            SettingsStore.customColumnOptions['resource_planner.customColumns'].updates = JSON.parse(result[0].value);
            const activeView = ViewModeStore.activeView.key;
            SettingsStore.customColumnOptions['resource_planner.customColumns'].value[activeView].usePLNInNETCalculationsSetting = JSON.parse(result[0].value)[activeView].usePLNInNETCalculationsSetting || false;

            let settingsOptions = [];
            Object.keys(SettingsStore.customColumnOptions['resource_planner.customColumns'].updates[ViewModeStore.activeView.key]).forEach(key => {
              settingsOptions.push(`${key}-${SettingsStore.customColumnOptions['resource_planner.customColumns'].updates[ViewModeStore.activeView.key][key]}`);
            });

            AnalyticsService.pendoAnalyticsTracker(
              getTrackNameForPendoAnalytics(ViewModeStore, `Load- ${settingsOptions.toString()}`));

            const customViewListObject = _.find(ViewModeStore.activeHourViewList, ({key}) => key === 'custom');
            if (customViewListObject) {
              customViewListObject.visible = true;
            }
          }
        });
      }
    }
    AnalyticsService.pendoAnalyticsTracker(
      getTrackNameForPendoAnalytics(
        ViewModeStore,
        `Load - ${ViewModeStore.activeView.key}, ${ViewModeStore.activeStep.key}, ${ViewModeStore.FTEView.key.toLowerCase()}`
    ));
  }

  componentWillUnmount() {
    document.removeEventListener('webkitfullscreenchange', this.handleFullScreenToggling);
    document.removeEventListener('mozfullscreenchange', this.handleFullScreenToggling);
    document.removeEventListener('fullscreenchange', this.handleFullScreenToggling);
    document.removeEventListener('MSFullscreenChange', this.handleFullScreenToggling);
  }

  applyFilter(expression) {
    this.filterStore.applyingIsCompleted = false;
    SaveDialogStore.state.isCanceled = false;
    if (this.treeNodeStore.unSavedChangesExists) {
      this.showSaveDialog(() => this.applyFilterAction(expression));

      return new Promise((resolve, reject) => {
        when(() => this.filterStore.applyingIsCompleted || SaveDialogStore.state.isCanceled, () => {
          if (this.filterStore.applyingIsCompleted) {
            resolve();
          } else {
            reject();
          }
        })
      });

    } else {
      return this.applyFilterAction(expression);
    }
  }

  applyFilterAction(expression) {
    this.filterStore.selectedFilterExpression = expression;
    this.filterStore.uniqueUrlFilterID = null;
    return this.loadInitialData().then(() => this.filterStore.applyingIsCompleted = true);
  }

  onResourcePoolSave(callbackFunction) {
    this.showResourcePlanner = true;
    this.loadInitialData(callbackFunction);
  }

  closeResourcePlanner() {
    this.showResourcePlanner = false;
  }

  loadInitialDataForRole() {
    const fromDate = this.intervalStore.visibleIntervalsFrom;
    intervalsFTEs.removeFTEs(this.treeNodeStore.intervalsIndexes);
    this.treeNodeStore = new RoleTreeNodeStore();
    this.intervalStore = new ProjectRoleIntervalsStore(fromDate);
    ChangedHoursStore.emptyChangedBDGHours();
    ChangedHoursStore.emptyTmpChangedBDGHours();

    ScrollStore.scrollStore.virtualizedTableHeaderHeight = RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT;

    return this.loadData(0, null, null).then((result) => {
      if (!result.items || !result.items.length) {
        loadingStore.noResults = true;
        if (result.clipped) {
          loadingStore.clipped = true;
        }
      } else {
        //const time = new Date();
        if (result.currency) {
          CurrencyConfig.currencyCharacter = result.currency;
        }
        ViewModeStore.showUserAndRoleLaborCost(result.showRoleLaborCost, result.showUserLaborCost);
        this.intervalStore.addHourIntervals(result.intervals, true);
        this.treeNodeStore.addNodes(result);
      }
      exportStore.canExport = result.canExport !== false;
      loadingStore.loading = false;
    });
  }

  loadInitialDataForUser() {
    const fromDate = this.intervalStore.visibleIntervalsFrom;
    this.treeNodeStore = new UserTreeNodeStore();
    this.intervalStore = new UserIntervalsStore(fromDate);
    VisualizationStore.data.showVisualization = false;
    ScrollStore.scrollStore.virtualizedTableHeaderHeight = RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT;

    return this.loadData(0, null, null).then((result) => {
      if (result.redirectUrl) {
        this.noAccess = true;
        loadingStore.loading = false;
        return null;
      }

      if (!result.items || !result.items.length) {
        loadingStore.noResults = true;
      } else {
        this.intervalStore.addIntervals(result.intervals);
        this.treeNodeStore.addNodes(result.items, result.intervals, this.intervalStore.intervals);
        loadingStore.loading = false;
      }
      exportStore.canExport = result.canExport !== false;
      loadingStore.loading = false;
    });
  }

  loadInitialDataForProject(callbackHandler) {
    const fromDate = this.intervalStore.visibleIntervalsFrom;
    intervalsFTEs.removeFTEs(this.treeNodeStore.intervalsIndexes);
    this.treeNodeStore = new ProjectTreeNodeStore();
    this.intervalStore = new ProjectRoleIntervalsStore(fromDate);
    filteredOutStore.clearFilteredOutStore();
    ScrollStore.scrollStore.virtualizedTableHeaderHeight = RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT;

    return this.loadData(0, null, null).then((result) => {
      exportStore.canExport = result.canExport !== false;

      if (!result.items || !result.items.length) {
        loadingStore.noResults = true;
        loadingStore.loading=false;
        loadingStore.isLazyLoadingMode = false;
      } else {
        CurrencyConfig.currencyCharacter = result.currency;
        ViewModeStore.showUserAndRoleLaborCost(result.showRoleLaborCost, result.showUserLaborCost);
      }

      if (_.isFunction(callbackHandler)) {
        callbackHandler();
      }
    });
  }

  loadInitialData(callbackFunction) {
    loadingStore.loading = true;
    loadingStore.noResults = false;
    loadingStore.clipped = false;

    if (ViewModeStore.activeView.key === ViewEnum.role) {
      return this.loadInitialDataForRole();
    } else if (ViewModeStore.activeView.key === ViewEnum.user) {
      return this.loadInitialDataForUser();
    } else {
      return this.loadInitialDataForProject(callbackFunction);
    }
  }

  loadData(offset = 0, node = null, IDExp = null) {
    let promise;
    if (ViewModeStore.activeView.key === ViewEnum.role) {
      promise = this.loadDataForRole(offset, IDExp);
    } else if (ViewModeStore.activeView.key === ViewEnum.user) {
      promise = this.loadDataForUser(offset, node, IDExp);
    } else {
      promise = this.loadDataForProject(offset);
    }

    return promise.then(result => {
      result.access && (ViewModeStore.access = result.access);
      return result;
    })
  }

  defineLoadLimit(offset) {
    let rowsCountInPage = ScrollStore.getNodeRowsCountInPage();
    const limit = viewLimits[ViewModeStore.activeView.key].loadLimit;

    if (offset + limit > viewLimits[ViewModeStore.activeView.key].firstLevelLimit) {
      return viewLimits[ViewModeStore.activeView.key].firstLevelLimit - offset;
    } else if (rowsCountInPage >= limit) {
      const increasedLimit = rowsCountInPage + 5;
      return increasedLimit;
    } else {
      return limit;
    }
  }

  loadCustomColumns() {
    return APIService.getSettings(SettingsStore.customColumnOptions);
  }

  loadDataForProject(offset, resultLength = 0) {
    let loadLimit = this.defineLoadLimit(resultLength);
    loadingStore.isLazyLoadingMode = true;
    const verticalFields = {
        offset: offset,
        limit: loadLimit,
        filters: this.filterStore.selectedFilterExpression,
        currentRowsCount: this.treeNodeStore.getNodesCount()
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        fromDate: this.intervalStore.visibleIntervalsFrom,
        stepCount: ViewModeStore.intervalsCount
      };

    return APIService.getData(`/internal/resourceplanner/projectview/projects${ViewModeStore.projectID ? `/${ViewModeStore.projectID}` : ''}`,
      ViewModeStore.projectID ? null : verticalFields, horizontalFields).then((result) => {
      if (result.items) {
        resultLength += result.items.length;
        this.treeNodeStore.lazyLoadOffset += loadLimit;
        const nodesLength = this.treeNodeStore.nodes.length;
        const lastLoadedProjectPriority = nodesLength ? this.treeNodeStore.nodes[nodesLength - 1].newPriority : 0;

        if (!this.intervalStore.intervals.length) {
          this.intervalStore.addHourIntervals(result.intervals);
        }

        loadingStore.clipped = !!result.clipped;
        filteredOutStore.addFilteredOutBudgets(result.filteredOutBudgets, lastLoadedProjectPriority, result.filteredOutProjects);
        this.treeNodeStore.addNodes(result, lastLoadedProjectPriority);

        if (result.hasMore && resultLength < loadLimit && !result.clipped && this.treeNodeStore.nodes.length < viewLimits.project.firstLevelLimit) {
          this.loadDataForProject(this.treeNodeStore.lazyLoadOffset, resultLength);
        } else {
          loadingStore.loading = false;
          this.treeNodeStore.deleteLoadingRow();
          loadingStore.isLazyLoadingMode = false;


          if (!this.treeNodeStore.nodes.length) {
            loadingStore.noResults = true;
          }

        }
      }
      return result;
    });
  }

  loadDataForUser(offset, node, IDExp) {
    let loadLimit = this.defineLoadLimit(offset);
    const verticalFields = {
        offset: offset,
        limit: loadLimit + 1, //got one mor for check need continue lazy load or show load more
        filters: this.filterStore.selectedFilterExpression
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        hourViewMode: this.getHourViewMode(),
        fromDate: this.intervalStore.visibleIntervalsFrom,
        stepCount: ViewModeStore.intervalsCount
      };

    return APIService.getData(userViewDataService.getUserViewRequestURL(IDExp), verticalFields, horizontalFields).then(result => {
      if (!result.items) {
        return result;
      }

      if (result.items.length <= loadLimit) {
        if (!IDExp) {
          this.treeNodeStore.continueLazyLoading = false;
        } else {
          node.showMore = false;
        }
      } else {
        //delete one more item
        let lastItem = result.items.pop();
        _.forEach(result.intervals, (interval) => {
          delete interval.hours[lastItem.ID];
        });
      }
      return result;
    });
  }

  loadDataForRole(offset, IDExp) {
    let url = '/internal/resourceplanner/roleview/roles';

    const verticalFields = {
        offset: offset,
        filters: this.filterStore.selectedFilterExpression
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        fromDate: this.intervalStore.visibleIntervalsFrom,
        stepCount: ViewModeStore.intervalsCount
      };

    if (IDExp) {
      url += `/${IDExp}/projects`;
      verticalFields.limit = viewLimits[ViewModeStore.activeView.key].loadLimit;
    } else {
      verticalFields.currentRowsCount = this.treeNodeStore.currentRowsCount;
      verticalFields.limit = this.defineLoadLimit(offset);
    }

    return APIService.getData(url, verticalFields, horizontalFields);
  }

  loadNextPrevData(nextPrev, stepCount, fromDate, requestFrom) {
    if (ViewModeStore.activeView.key === ViewEnum.project) {
      return this.loadNextPrevDataForProject(nextPrev, stepCount, fromDate, requestFrom);
    } else if (ViewModeStore.activeView.key === ViewEnum.user) {
      return this.loadNextPrevDataForUser(nextPrev, stepCount, fromDate, requestFrom);
    } else {
      return this.loadNextPrevDataForRole(nextPrev, stepCount, fromDate);
    }
  }

  loadNextPrevDataForUser(nextPrev, stepCount, from, requestFrom) {
    this.treeNodeStore.setEmptyRows(nextPrev, this.intervalStore.intervals.length, stepCount);
    this.intervalStore.addRemoveIntervals(nextPrev, from, stepCount);
    this.intervalStore.visibleIntervalsFrom = this.intervalStore.intervals[0].from;

    const fromDate = stepCount > 1 ? requestFrom : from.format(DATE_FORMAT); // if stepCount > 1 than "Today" button is clicked

    if (VisualizationStore.data.showVisualization) {
      this.loadVisualizationData(fromDate, stepCount);
    }

    return this.requestUserNextPrevData(requestFrom, stepCount).then((data) => {
      if (data) {
        data.forEach(({from, fth}) => {
          let intervalIndex = _.findIndex(this.intervalStore.intervals, (interval) => from === interval.from);
          this.intervalStore.intervals[intervalIndex].fth = fth;
        });
      }
    });
  }

  requestUserNextPrevData(fromDate, stepCount, IDs = null) { //also used in loadMissing Data
    let oldStep = ViewModeStore.activeStep.key;
    this.reRenderTable = false;

    let nodeIDs = IDs || this.treeNodeStore.getRequestIDs(this.treeNodeStore.renderedStartIndex, this.treeNodeStore.renderedStopIndex);

    let verticalFields = {
        filters: this.filterStore.selectedFilterExpression,
        IDS: nodeIDs
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        hourViewMode: this.getHourViewMode(),
        fromDate: fromDate,
        stepCount: stepCount
      };

    return APIService.getData('/internal/resourceplanner/userview/nextprevious', verticalFields, horizontalFields).then((nextPreviousResponse) => {
      if (oldStep !== ViewModeStore.activeStep.key) { //for prevent change step mess during loading
        return;
      }

      this.treeNodeStore.fillIntervalsHours(nextPreviousResponse.data, nodeIDs, this.intervalStore.intervals);
      this.reRenderTable = true;
      exportStore.canExport = nextPreviousResponse.canExport !== false;
      return nextPreviousResponse.data;
    });
  }

  loadNextPrevDataForProject(nextPrev, stepCount, from = null, requestFrom = null) {
    this.reRenderTable = false;

    let deleteIntervals = this.intervalStore.getIntervalsForDelete(nextPrev, stepCount);
    this.treeNodeStore.storeChangedBDGHours(deleteIntervals);
    filteredOutStore.removeFilteredOutBudgets(deleteIntervals);
    this.treeNodeStore.removeIntervalHours(deleteIntervals, this.treeNodeStore.nodes);
    this.intervalStore.addRemoveIntervals(nextPrev, from, stepCount);
    intervalsFTEs.removeFTEs(deleteIntervals);
    this.intervalStore.visibleIntervalsFrom = this.intervalStore.intervals[0].from;

    let offset = 0;
    let limit = viewLimits.project.nextPrevLimit;
    let promises = [];
    let verticalFields = {
        filters: this.filterStore.selectedFilterExpression,
        excludeItems: true
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        fromDate: requestFrom,
        stepCount: stepCount
      };

    if (ViewModeStore.projectID) {
      promises.push(APIService.getData(`/internal/resourceplanner/projectview/projects/${ViewModeStore.projectID}`, null, horizontalFields));
    } else {
      /**
       * getting data partially
       */
      while (offset < this.treeNodeStore.lazyLoadOffset) {
        limit = this.treeNodeStore.lazyLoadOffset - offset < limit ? this.treeNodeStore.lazyLoadOffset - offset : limit;
        verticalFields.offset = offset;
        verticalFields.limit = limit;

        promises.push(APIService.getData('/internal/resourceplanner/projectview/projects', verticalFields, horizontalFields));
        offset += limit
      }
    }

    return Promise.all(promises).then(result => {
      /**
       * FTE is the same for each part.
       */

      intervalsFTEs.addFTEs(result[0].intervals, this.treeNodeStore.intervalsIndexes);
      let nodeIndexForPriority = 0;

      result.forEach((data) => {
        const hoursLength = Object.keys(data.intervals[0].hours).length;

        if(hoursLength) {
          nodeIndexForPriority += hoursLength;
          let addToPriority = this.treeNodeStore.nodes[nodeIndexForPriority > 0 ? nodeIndexForPriority - 1 : 0].newPriority;

          filteredOutStore.addFilteredOutBudgets(data.filteredOutBudgets, addToPriority);
          this.treeNodeStore.fillHours(data.intervals);
        }
      });
      this.reRenderTable = true;
      exportStore.canExport = result[0].canExport !== false;
    });
  }

  loadNextPrevDataForRole(nextPrev, stepCount, from) { //TODO refactor and remove from, stepCount arguments
    let deleteIntervals = this.intervalStore.getIntervalsForDelete(nextPrev, stepCount);

    ChangedHoursStore.storeChangedBDGHours(this.treeNodeStore.nodes, deleteIntervals);
    this.treeNodeStore.removeHours(deleteIntervals);
    this.intervalStore.addRemoveIntervals(nextPrev, from, stepCount);
    intervalsFTEs.removeFTEs(deleteIntervals);
    this.intervalStore.visibleIntervalsFrom = this.intervalStore.intervals[0].from;

    return this.requestRoleNextPrevData();
  }

  requestRoleNextPrevData() { //also used in loadMissingData
    let requestParams = this.treeNodeStore.getRequestParams();

    if (requestParams) {
      let oldStep = ViewModeStore.activeStep.key;
      const verticalFields = {
          offset: requestParams.offset,
          limit: requestParams.limit,
          filters: this.filterStore.selectedFilterExpression
        },
        horizontalFields = {
          step: ViewModeStore.activeStep.key,
          fromDate: requestParams.fromDate,
          stepCount: requestParams.stepCount
        };

      return APIService.getData('/internal/resourceplanner/roleview/roles', verticalFields, horizontalFields).then((rolesResponseData) => {
        let isValidResponse = _.every(rolesResponseData.intervals,
          interval => _.some(this.treeNodeStore.intervalsIndexes, item => item.from === interval.from));

        if (!isValidResponse || oldStep !== ViewModeStore.activeStep.key) { //for prevent change step mess during loading
          return;
        }

        intervalsFTEs.addFTEs(rolesResponseData.intervals, this.treeNodeStore.intervalsIndexes);
        this.requestProjectsAndFillHours(rolesResponseData.intervals, verticalFields, horizontalFields);

        exportStore.canExport = rolesResponseData.canExport !== false;
        return rolesResponseData;
      });
    }
  }

  /**
   * The method is request projects portion by portion for each expanded role
   * each portion is 60 items
   * after load all projects portions started fill hours for loaded role and loaded projects
   * */
  requestProjectsAndFillHours(roleIntervals, verticalFields, horizontalFields) {
    let limit = verticalFields.limit,
      offset = verticalFields.offset;

    for (let i = offset; i < offset + limit; i++) {
      let roleNode = this.treeNodeStore.nodes[i];
      if (roleNode.toggled) {
        let projectsPromises = [];

        let prOffset = 0, prLimit;
        while (prOffset < roleNode.offset) {
          prLimit = roleNode.offset - prOffset < viewLimits.role.nextPrevLimit ? roleNode.offset - prOffset : viewLimits.role.nextPrevLimit;
          verticalFields.offset = prOffset;
          verticalFields.limit = prLimit;

          projectsPromises.push(APIService.getData(`/internal/resourceplanner/roleview/roles/${roleNode.ID}/projects`, verticalFields, horizontalFields));
          prOffset += prLimit
        }

        Promise.all(projectsPromises).then(responseArray => {
          this.treeNodeStore.fillRoleNodeHours(roleIntervals, roleNode);

          responseArray.forEach(projectsData => {
            projectsData.items.forEach(item => {
              let projectNode = _.find(roleNode.nodes, projectItem => projectItem.ID === item.ID);
              this.treeNodeStore.fillProjectNodeAndUsersHours(projectsData.intervals, projectNode, roleNode);
            });
          });

          this.reRenderTable = Math.random();
        });
      } else {
        this.treeNodeStore.fillRoleNodeHours(roleIntervals, roleNode);
        this.reRenderTable = Math.random();
      }
    }
  }

  /**
   * the method is called in user/role views when scrolling up/down (after next/prev/today) or when click on full mode
   * */
  loadMissingData() {
    if (ViewModeStore.activeView.key === ViewEnum.user) {
      return this.loadMissingDataForUser();
    } else if (ViewModeStore.activeView.key === ViewEnum.role) {
      return this.requestRoleNextPrevData();
    }
  }

  loadMissingDataForUser() { //need in user view after next/prev when scrolling up/down
    let from = null,
      stepCount = 0,
      IDs = [];

    this.intervalStore.intervals.forEach((interval, intervalIndex) => {
      let hasEmptyRow = false;

      for (let i = this.treeNodeStore.renderedStartIndex; i <= this.treeNodeStore.renderedStopIndex; i++) {
        let IDExp = this.treeNodeStore.nodesIDs[i];

        if (IDExp.indexOf(IDExpOptions.loading) !== -1 || IDExp.indexOf(IDExpOptions.more) !== -1 || IDExp.indexOf(IDExpOptions.notification) !== -1) {
          continue;
        }

        if (this.treeNodeStore.nodes[IDExp].intervals[intervalIndex] === IDExpOptions.loading) {
          if (IDs.indexOf(IDExp) === -1) {
            IDs.push(IDExp);
          }

          hasEmptyRow = true;
        }
      }

      if (hasEmptyRow) {
        from = from || interval.from;
        stepCount = intervalIndex + 1;
      }
    });

    if (IDs.length > 0) {
      this.requestUserNextPrevData(from, stepCount, IDs);
    }
  }

  loadVisualizationData(from, stepCount) {
    const verticalFields = {
        filters: this.filterStore.selectedFilterExpression
      },
      horizontalFields = {
        step: ViewModeStore.activeStep.key,
        hourViewMode: this.getHourViewMode(),
        fromDate: from,
        stepCount: stepCount
      };

    APIService.getData('/internal/resourceplanner/userview/visualization', verticalFields, horizontalFields).then((data) => {
      runInAction(() => {
        VisualizationStore.data.chartMaxPoint = 0;
        this.intervalStore.intervals.map((interval) => {
          _.forEach(data, (item) => {
            if ((item.from === interval.from) && (item.to === interval.to)) {
              interval.chartData.sumAVL = item.AVL;
              interval.chartData.sumPLN = item.PLN;
              interval.chartData.showChartWarningIcon = item.INF;
              interval.chartData.loading = false;
            }
          });

          VisualizationStore.data.chartMaxPoint = Math.max(VisualizationStore.data.chartMaxPoint, interval.chartData.sumAVL, interval.chartData.sumPLN);
        });
      });
    });
  }

  filterToggleCallback(open) {
    this.rowsCountInPage = ScrollStore.getNodeRowsCountInPage();
    ScrollStore.scrollStore.filterOpen = open;
    ScrollStore.calculateVirtualizedTableWidth();
  }

  showSaveDialog(callbackFunction, messageKey) {
    SaveDialogStore.showSaveDialog(callbackFunction, messageKey);
  }

  handleFullScreenToggling = () => {
      ScrollStore.scrollStore.fullMode = !ScrollStore.scrollStore.fullMode;
      setTimeout(() => { //helps for getting missing data after rerender list table
        ScrollStore.calculateVirtualizedTableHeight();
        ScrollStore.calculateVirtualizedTableWidth();
        recalculateFilterHeight();
        this.loadMissingData();
      }, 0);
  };

  fullModeToggle() {
    if (this.fullScreenElement.current) {
      if (!ScrollStore.scrollStore.fullMode) {
        const requestMethod =
          this.fullScreenElement.current.requestFullScreen ||
          this.fullScreenElement.current.webkitRequestFullscreen ||
          this.fullScreenElement.current.mozRequestFullScreen ||
          this.fullScreenElement.current.msRequestFullscreen;
        requestMethod.call(this.fullScreenElement.current);

      } else {
        const exitMethod =
          document.exitFullscreen ||
          document.mozCancelFullScreen ||
          document.webkitExitFullscreen ||
          document.msExitFullscreen;
        exitMethod.call(document);
      }
    }
  }

  getHourViewMode() {
    return ViewModeStore.hourViewMode.key !== HourViewCustom.key && ViewModeStore.hourViewMode.key !== HourViewCost.key && ViewModeStore.hourViewMode.key !== HourViewHours.key ? HourViewFTE.key : ViewModeStore.hourViewMode.key;
  }
  render() {
    return (
      <div ref={this.fullScreenElement} style={{backgroundColor: 'white'}}>
        {/*
        This should be uncommented when Resource Planner under business case is unshimmed
        */}
        {/*<If condition={this.showPoolSelection}>*/}
        {/*  <ResourcePoolsContainer saveCallbackHandler={this.onResourcePoolSave}*/}
        {/*                          closeResourcePlanner={this.closeResourcePlanner}*/}
        {/*                          cancelCallbackHandler={this.props.cancelCallbackHandler}*/}
        {/*                          access={this.props.access}*/}
        {/*                          projectPools={this.props.projectPools}*/}
        {/*  />*/}
        {/*</If>*/}

        <If condition={this.noAccess}>
          <NoAccessComponent/>
        </If>

        <div>
          <If condition={this.showResourcePlanner && !this.noAccess && (this.filterStore.defaultFilter || !this.props.filterVisible)}>
            <div data-wf-popover-container
                 className={`resource-planner resource-planner-${ViewModeStore.activeView.key}-view
                         ${this.props.className}
                        ${ViewModeStore.filterVisible && this.filterStore.filterOptions.visible ? 'filters-open' : ''}
                        ${ScrollStore.scrollStore.fullMode || ViewModeStore.isQuickSilver ? 'full-mode-margins' : '' }
                        `}>
              <FilterProvider
                savedFilterType={FILTER_TYPE}
                applyFilterPromise={this.applyFilter}
                defaultFilter={this.filterStore.defaultFilter}
                filterID={this.filterStore.uniqueUrlFilterID}
                openedFrom={getResourcePlannerArea(ViewModeStore)}
              >
                <ResourcePlannerHeader treeNodeStore={this.treeNodeStore}
                                       intervalStore={this.intervalStore}
                                       filterStore={this.filterStore}
                                       loadNextPrevData={this.loadNextPrevData}
                                       loadVisualizationData={this.loadVisualizationData}
                                       fullModeToggle={this.fullModeToggle}
                                       loadStoreData={this.loadInitialData}
                                       showSaveDialog={this.showSaveDialog}
                                       mfeMigrationEnabled={this.props.mfeMigrationEnabled}/>

                <Filter filterStore={this.filterStore}
                        treeNodeStore={this.treeNodeStore}
                        loadStoreData={this.loadInitialData}
                        filterToggleCallback={this.filterToggleCallback}/>

                <ResourcePlannerContainer treeNodeStore={this.treeNodeStore}
                                          intervalStore={this.intervalStore}
                                          cancelCallbackHandler={this.props.cancelCallbackHandler}
                                          loadMissingData={this.loadMissingData}
                                          loadData={this.loadData}
                                          loadInitialData={this.loadInitialData}
                                          filterStore={this.filterStore}
                                          reRenderTable={this.reRenderTable}
                                          saveCallbackHandler={this.props.saveCallbackHandler}
                                          showProjectPriorityAlign={this.props.showProjectPriorityAlign}
                                          showSaveDialog={this.showSaveDialog}
                                          isInInstantLoadingState={this.props.isInInstantLoadingState} exitFullScreen={this.fullModeToggle}
                                          isSecondaryNavOpened={this.props.isSecondaryNavOpened}
                                          analyzingTimeout = {this.analyzingTimeout}/>
              </FilterProvider>

            </div>
          </If>
        </div>
        <ConfirmationDialog/>
      </div>
    )
  }
}
