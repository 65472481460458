/**
 * Created by artakpoghosyan on 8/23/17.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {redrockClient, Localization} from '../../../localization/localization';
import {TertiaryButton} from 'titan/react-button';
import ExportToExcelDialogContent from './exportToExcelDialog/ExportToExcelDialogContent';
import {exportStore} from '../../stores/ExportStore';
import {Tip} from 'titan/react-popover';
import {standardTooltipOptions} from '../../constants/TooltipOptions';
import {getResourcePlannerArea} from '../../services/Utilities';
import ViewModeStore from '../../stores/ViewModeStore';
import {Dialog} from '@phoenix/all';
import {DialogHeader} from '../dialog/DialogHeader';

@observer
export default class ExportToExcel extends Component {

  //noinspection JSAnnotator
  props: {
    treeNodeStore: Object,
    intervalStore: Object,
    disabled: Boolean,
    filterStore: Object,
    showSaveDialog: Function
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.exportButtonConditionalRendering = this.exportButtonConditionalRendering.bind(this);
    this.exportButton = this.exportButton.bind(this);
  }

  handleClick() {
    if (this.props.disabled || !exportStore.canExport) {
      return false;
    }

    exportStore.isDialogVisible = true;
  }

  closeDialog() {
    exportStore.isDialogVisible = false;
  }

  exportButton(disabled) {
    return (<TertiaryButton onClick={this.handleClick} className={`btn-export ${disabled ? 'disabled' : ''}`}>
                <span>
                  <Localization messageKey="action.export"/>
                </span>
    </TertiaryButton>)
  }

  exportButtonConditionalRendering() {
    const canNotExport = !exportStore.canExport;
    if (canNotExport) {
      return (
        <div data-wf-popover-container>
          <Tip isEnabled={canNotExport}
               content={<span><Localization messageKey="resourceplanner.export.button.popover"/></span>}
               {...standardTooltipOptions}>
            {this.exportButton(canNotExport)}
          </Tip>
        </div>
      );
    }

    return this.exportButton(this.props.disabled);
  }

  render() {
    const {filterStore, showSaveDialog, treeNodeStore} = this.props;

    return (
      <div className="export-to-excel"
           data-testid={`exportButton-${getResourcePlannerArea(ViewModeStore)}-${ViewModeStore.activeStep.key}`}
      >
        {this.exportButtonConditionalRendering()}
        {exportStore.isDialogVisible && <Dialog>
          <div className={'dialog-content'}>
            <DialogHeader
              title={redrockClient.getTextSync('resourceplanner.export.period.title')}
              onCancel={this.closeDialog}
            />
            <ExportToExcelDialogContent
              filterStore={filterStore}
              showSaveDialog={showSaveDialog}
              closeDialog={this.closeDialog}
              treeNodeStore={treeNodeStore}
            />
            </div>
        </Dialog>}
      </div>
    );
  }
}
