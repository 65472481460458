/**
 * Created by artakpoghosyan on 7/16/18.
 */
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Localization} from '../../../../localization/localization';
import {PrimaryButton, TextButton} from 'titan/react-button';
import ViewModeStore from '../../../stores/ViewModeStore';
import {HourViewCost, HourViewFTE, HourViewHours, HourViewCustom, ViewEnum} from '../../../constants/ViewEnums';
import {DATE_FORMAT} from '../../../constants/MonthsEnum';
import {exportStore} from '../../../stores/ExportStore';
import APIService from '../../../services/APIService';
import {BackgroundJob} from 'workfront-objcodes';
import NotificationStore from '../../../stores/NotificationStore';
import {GROUPED} from '../../../constants/ExportToExcelEnum';
import {getResourcePlannerArea, getTrackNameForPendoAnalytics} from '../../../services/Utilities';
import AnalyticsService from '../../../../analytics/AnalyticsService';

@observer
export default class ExportToExcelDialogButtons extends Component {
  maxStatusCheckCount = 3;
  checkCount = 1;

  props: {
    disabled: Boolean,
    filterStore: Object,
    step: String,
    fromDate: Object,
    stepCount: Number,
    exportOption: String,
    closeDialog: Function,
    showSaveDialog: Function,
    treeNodeStore: Object,
    dataFormatting: String
  };

  constructor(props) {
    super(props);

    this.handleExportClick = this.handleExportClick.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.timeOutFn = this.timeOutFn.bind(this);
    this.changeWindowLocation = this.changeWindowLocation.bind(this);
  }

  /**
   * Api call to check status of excel file downloading process
   * It can do till 3 times the same call if process is not finished
   * and otherwise download immediately or show error message
   */
  timeOutFn(result) {
    APIService.checkDownloadStatus(result.data, BackgroundJob).then(({status}) => {
      if (status === 'SUCCESS') {
        this.changeWindowLocation(`/jobs/download?ID=${result.data}`);
        exportStore.exporting = false;
        this.checkCount = 1;
      } else if (status === 'FAILED' || status === 'ERROR' || status === 'CANCELLED') {
        NotificationStore.showNotification(<Localization messageKey="resourceplanner.export.canceled.error.status"/>, 'error');
        exportStore.isExportBtnActive = true;
        exportStore.exporting = false;
        this.checkCount = 1;
      } else {
        if (this.checkCount >= this.maxStatusCheckCount) {
          NotificationStore.showNotification(<Localization messageKey="resourceplanner.export.check.your.email"/>, 'info', 0);
          exportStore.isDialogVisible = false;
          exportStore.canExport = false;
          exportStore.exporting = false;
        } else {
          this.checkCount++;
          setTimeout(() => this.timeOutFn(result), 1000 * this.checkCount);
        }
      }
    }, () => {
      this.checkCount = 1;
    });
  }

  changeWindowLocation(url) {
    window.location = url
  }

  /**
   * Export to excel method. It's doing form submit when pitBoss is off
   * and otherwise sending a request to the server
   */
  exportToExcel() {
    exportStore.exporting = true;

    const verticalFields = {
        filters: this.props.filterStore.selectedFilterExpression,
        exportOption: this.props.exportOption
      },
      horizontalFields = {
        step: this.props.step,
        fromDate: this.props.fromDate.clone().locale('en').startOf(this.props.step).format(DATE_FORMAT),
        stepCount: parseInt(this.props.stepCount),
        hourViewMode: ViewModeStore.hourViewMode.key === HourViewCustom.key ? HourViewCustom.key : ViewModeStore.hourViewMode.key === HourViewCost.key ? HourViewCost.key : ViewModeStore.hourViewMode.key === HourViewHours.key ? HourViewHours.key : HourViewFTE.key,
        grouped: this.props.dataFormatting === GROUPED
      };

    APIService.startDownloadExcelProcess(ViewModeStore.activeView.key, verticalFields, horizontalFields, ViewModeStore.projectID).then((result) => {
      setTimeout(() => this.timeOutFn(result), 1000);
    }, () => {
      exportStore.isExportBtnActive = true;
      exportStore.exporting = false;
    });

  }

  handleCloseDialog() {
    this.props.closeDialog();
  }

  trackExportOption = () => {
    AnalyticsService.pendoAnalyticsTracker(
      getTrackNameForPendoAnalytics(
        ViewModeStore,
        `Export - ${ViewModeStore.activeView.key}, ${ViewModeStore.activeStep.key}, ${this.props.exportOption.toLowerCase()}, ${this.props.dataFormatting}, Period Count ${this.props.stepCount}`
      )
    );
  };

  /**
   * Click method on Export button. If it not user view and there are unsaved changes
   * it will open confirm dialog otherwise call exportToExcel method
   */
  handleExportClick() {
    if (ViewModeStore.activeView.key !== ViewEnum.user && this.props.treeNodeStore.unSavedChangesExists) {
      this.props.closeDialog();
      this.props.showSaveDialog(this.exportToExcel, 'action.save.and.switch.hint.export');
    } else {
      this.exportToExcel();
      this.trackExportOption();
    }
  }

  render() {
    return (
      <div className="dialog-footer" style={{
        bottom: '-55px'
      }}>
        <Localization messageKeys={['action.export', 'action.exporting']}>
          {
            (messages) => (
              <PrimaryButton onClick={this.handleExportClick}
                             disabled={this.props.disabled || exportStore.exporting}
                             data-testid={`exportButton-${getResourcePlannerArea(ViewModeStore)}`}
              >
                {exportStore.exporting ? messages[1]() : messages[0]()}
              </PrimaryButton>)
          }
        </Localization>

        <TextButton onClick={this.handleCloseDialog}
                    data-testid={`exportCancelButton-${getResourcePlannerArea(ViewModeStore)}`}
                    disabled={exportStore.exporting}>
          <Localization messageKey="action.cancel"/>
        </TextButton>
      </div>
    );
  }
}
