import moment from 'moment';
import { safeAPIDateToDate } from '../services/Utilities';

export default class IntervalsModel {
  from;
  to;
  current;

  constructor(from, to, index) {
    this.from = from;
    this.fromMoment = moment(from, 'YYYY-MM-DD');
    this.to = to;
    this.current = moment(safeAPIDateToDate(this.from)) <= moment() && moment() <= moment(safeAPIDateToDate(this.to));
    this.index = index;
  }
}
