const popupOptions = {
  attachTo: 'container',
  position: 'bottom',
  alignment: 'start'
};

export const dotPopupOptions = {
  tweakPosition: true,
  className: 'dot-action-popup',
  offset: 10,
  ...popupOptions
};

export const dropDownPopupOptions = {
  className: 'dropdown-popup',
  tweakPosition: false,
  offset: 1,
  ...popupOptions
};

export const urlPopupOptions = {
  className: 'unique-url-popup',
  tweakPosition: false,
  offset: 1,
  ...popupOptions,
  alignment: 'end',
};
