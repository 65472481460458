import React, {Component} from 'react';
import {observer} from 'mobx-react';
import ViewMode from './ViewMode';
import ShowByFTE from './ShowByFTE';
import NextPrevious from './NextPrevious';
import PeriodButtons from './PeriodButtons';
import FilterDropDown from '../filter/FilterDropDown';
import {loadingStore} from '../../stores/LoadingStore';
import ViewModeStore from '../../stores/ViewModeStore';
import ScrollStore from '../../stores/ScrollStore';

@observer
export default class HeaderLeft extends Component {
  // noinspection JSAnnotator
  props: {
    intervalStore: Object,
    treeNodeStore: Object,
    filterStore: Object,
    loadStoreData: Function,
    loadNextPrevData: Function,
    disableCondition: Boolean,
    showSaveDialog: Function
  };

  constructor(props) {
    super(props);
  }

  filterToggleCallback(open) {
    ScrollStore.scrollStore.filterOpen = open;
    ScrollStore.calculateVirtualizedTableWidth();
  }

  render() {
    const {intervalStore, treeNodeStore, loadStoreData, filterStore, disableCondition, loadNextPrevData, showSaveDialog} = this.props,
      disabledWhenNoResult = loadingStore.noResults || disableCondition;

    return (
      <div className="resource-planner-header-left">
        {ViewModeStore.filterVisible ? (
          <FilterDropDown filterStore={filterStore}
                          toggleCallback={this.filterToggleCallback}
                          loadStoreData={loadStoreData}
                          disabled={disableCondition || loadingStore.saveInProcess}
                          treeNodeStore={treeNodeStore}
                          showSaveDialog={showSaveDialog}/>
        ) : null}
        {ViewModeStore.viewModeVisible ? (
          <ViewMode disabled={disableCondition || loadingStore.saveInProcess}
                    showSaveDialog={showSaveDialog}
                    treeNodeStore={treeNodeStore}
                    intervalStore={intervalStore}
                    loadStoreData={loadStoreData}
                    toggleCallback={this.filterToggleCallback}/>
        ) : null}

        <PeriodButtons intervalStore={intervalStore}
                       treeNodeStore={treeNodeStore}
                       loadStoreData={loadStoreData}
                       disabled={disabledWhenNoResult || loadingStore.saveInProcess}
                       showSaveDialog={showSaveDialog}/>

        <NextPrevious intervalStore={intervalStore}
                      loadNextPrevData={loadNextPrevData}
                      disabled={disableCondition || loadingStore.noResults} />


        <ShowByFTE disabled={disabledWhenNoResult}
                   treeNodeStore={treeNodeStore}
                   intervalStore={intervalStore}
                   loadStoreData={loadStoreData}/>
      </div>
    )
  }
}
