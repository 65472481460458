import React from 'react';

const TreeNodeItemLoading = () => {
  return (
    <div className="loading-row">
      <div className="loading-box"></div>
      <div className="loading-box loading-box-lg"></div>
    </div>
  );
};
export default TreeNodeItemLoading;
