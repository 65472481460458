/**
 * Created by iskuhihakobyan on 5/24/17.
 */
import {observable, computed, reaction, action} from 'mobx';
import {
  ViewSteps,
  ViewList,
  ViewEnum,
  HourViewHours,
  HourViewCost,
  getHourViewList,
  HourViewCustom,
  COST_VIEW_KEY
} from '../constants/ViewEnums';
import {HourKeys, HourHeaders, HourMetrics} from '../constants/HourEnum';
import {getObjectFromLocalStorage, setObjectToLocalStorage} from '../services/Utilities';
import {getSortableCustomColumns} from '../services/ColumnsCustomization';
import SettingsStore from '../stores/SettingsStore';
import {Localization} from '../../localization/localization';
import React from 'react';

const defaultView = getObjectFromLocalStorage('resourcePlannerViewMode') || ViewList[2];
const defaultFTE = getObjectFromLocalStorage('resourcePlannerViewByFTE') || HourViewHours;
const defaultShowActualHours = getObjectFromLocalStorage('resourcePlannerShowActualHours') || null;
const defaultFTEBusinessCase = getObjectFromLocalStorage('resourcePlannerViewByFTEBusinessCase') || HourViewHours;
const defaultStep = getObjectFromLocalStorage('resourcePlannerStep') || ViewSteps.month;
const defaultStepBusinessCase = getObjectFromLocalStorage('resourcePlannerStepBusinessCase') || ViewSteps.month;
const defaultPaddingLeft = 24;

const viewModeStore = observable({
  @observable access: '',
  @observable projectID: null,
  @observable urlGeneratorVisible: true,
  @observable isOpenedFromBusinessCase: false,
  @observable viewModeVisible: true,
  @observable step: defaultStep,
  @observable limitIconVisibilityForViews: {},
  @observable view: defaultView,
  @observable _actualHoursStateChanged: false,
  @observable FTEView: defaultFTE,
  @observable _priorityWidth: 0,
  @observable stepCount: 0,
  @observable changedActiveView: null,
  @observable isSecondaryNavOpened: true,
  @observable dragStart: false,
  @observable _showRoleLaborCost: true,
  @observable _showUserLaborCost: true,
  @observable customColumns: {},
  @observable _showActualHours: defaultShowActualHours,
  intervalsCount: 0,
  priorityChanged: false,
  priorityIsInEditableMode: false,
  isQuickSilver: false,
  isCustomColumnFromLink: false,

  @computed
  get hasViewAccess() {
    return this.access === 'view';
  },
  @computed get activeStep() {
    return this.step;
  },
  set activeStep(value) {
    setObjectToLocalStorage(this.projectID ? 'resourcePlannerStepBusinessCase' : 'resourcePlannerStep', value);
    this.step = value;
  },
  @computed get nodeCollectionPaddingLeft() {
    return this.activeView.key === ViewEnum.project ? this._priorityWidth + defaultPaddingLeft : defaultPaddingLeft;
  },
  set priorityWidth(priorityWidth) {
    this._priorityWidth = this.activeView.key === ViewEnum.project ? priorityWidth : 0;
  },
  @computed get priorityWidth() {
    return this._priorityWidth;
  },
  @computed get activeView() {
    return this.view;
  },
  set activeView(value) {
    setObjectToLocalStorage('resourcePlannerViewMode', value);
    this.view = value;
    // Set "Hour View List" dropdown to its first value if its current value is not available for new view
    if (this.isUserActiveView && this.hourViewMode.key === HourViewCost.key) {
      this.hourViewMode = HourViewHours;
    }

    let showActualHours = this.showActualHours;
    if (this.isUserActiveView && this._showActualHours !== showActualHours) {
      this._showActualHours = showActualHours;
      this.actualHoursStateChanged = true;
    }
  },

  @computed get hourViewMode() {
    return this.FTEView;
  },
  set hourViewMode(value) {
    setObjectToLocalStorage(this.projectID ? 'resourcePlannerViewByFTEBusinessCase' : 'resourcePlannerViewByFTE', value);
    this.FTEView = value;
    if(this.isUserActiveView) {
      let showActualHours = this.showActualHours;
      this.actualHoursStateChanged = this._showActualHours !== showActualHours;
      this._showActualHours = showActualHours;
    }
  },
  @computed get activeHourViewList() {
    return getHourViewList(!this.isUserActiveView && this.showCost, !this.isCustomColumnFromLink);
  },
  @computed get showCost() {
    return this.showRoleLaborCost || this.showUserLaborCost;
  },
  get hourKeys() {
    return HourKeys[this.activeView.key];
  },
  get hourMetrics() {
    let hourMetrics = {};

    if (!this.showCost) {
      Object.keys(HourMetrics).map(viewType => {
        if (HourMetrics[viewType]) {
          hourMetrics[viewType] = HourMetrics[viewType].filter(metric => {
            return metric !== COST_VIEW_KEY;
          });
        }
      });

      return hourMetrics;
    } else {
      return HourMetrics;
    }
  },
  get showActualHours() {
    let customHourKeys = this.customHourKeys;
    return this.isUserActiveView ? !!(this.hourViewMode.key !== HourViewCustom.key || customHourKeys['ACT'] && customHourKeys['ACT'].length !== 0) : false;
  },
  @computed get actualHoursStateChanged() {
    return this._actualHoursStateChanged;
  },
  set actualHoursStateChanged(value) {
    setObjectToLocalStorage('resourcePlannerShowActualHours', this.showActualHours);
    this._actualHoursStateChanged = value;
  },
  get customHourKeys() {
    let orderedCustomColumns = {};

    let customColumns = SettingsStore.customColumnOptions['resource_planner.customColumns'].value[this.activeView.key] || {};
    const sortableCustomColumns = getSortableCustomColumns(customColumns);

    sortableCustomColumns.sort((metricOne, metricTwo) => {
      if (metricOne && metricTwo) {
        return HourHeaders[this.activeView.key][metricOne].displayOrder - HourHeaders[this.activeView.key][metricTwo].displayOrder;
      } else {
        return metricOne ? -1 : metricTwo ? 1 : 0;
      }
    }).forEach(key => {
      orderedCustomColumns[key] = customColumns[key];
    });

    if (!this.showCost) {
      let updatedCustomColumns = JSON.parse(JSON.stringify(orderedCustomColumns));

      if (!this.showCost) {
        Object.keys(updatedCustomColumns).map(type => {
          if (updatedCustomColumns[type]) {
            updatedCustomColumns[type] = updatedCustomColumns[type].filter(metric => {
              return metric !== COST_VIEW_KEY;
            });
          }
        });
      }

      return updatedCustomColumns;
    }

    return orderedCustomColumns;
  },
  get customColumnCount() {
    let count = 0;
    const hourKeys = this.customHourKeys;

    if (hourKeys) {
      count = Object.keys(this.customHourKeys).reduce((acc, key) => {
        const customColumns = this.customHourKeys[key];
        acc += customColumns && Array.isArray(customColumns) ? customColumns.length : 0;
        return acc;
      }, 0);
    }

    return count;
  },
  get isCustomViewMode() {
    return this.hourViewMode && this.hourViewMode.key === HourViewCustom.key;
  },
  get hourHeaders() {
    return HourHeaders[this.activeView.key];
  },
  @computed get showLimitIcon() {
    return this.limitIconVisibilityForViews[this.activeView.key];
  },
  @computed get isUserActiveView() {
    return this.activeView.key === ViewEnum.user;
  },
  get initialStepCount() {
    return 4;
  },
  get dateFormat() {
    return 'YYYY-MM-DD';
  },

  @computed
  get showRoleLaborCost() {
    return this._showRoleLaborCost;
  },
  set showRoleLaborCost(value) {
    this._showRoleLaborCost = value;
  },

  @computed get showUserLaborCost() {
    return this._showUserLaborCost;
  },
  set showUserLaborCost(value) {
    this._showUserLaborCost = value;
  },

  @action
  showUserAndRoleLaborCost(showRoleLaborCost, showUserLaborCost) {
    this.showRoleLaborCost = showRoleLaborCost;
    this.showUserLaborCost = showUserLaborCost;
  }
});

const CurrencyConfig = {
  @observable currencyCharacter: '',

  @computed get currencyText() {
    if (viewModeStore.isCustomViewMode) {
      return CurrencyConfig.currencyCharacter === '' ?
        <Localization messageKey={'resourceplanner.cost'}/> : '(' + CurrencyConfig.currencyCharacter + ')';
    } else {
      return viewModeStore.hourViewMode.key === HourViewCost.key ? '(' + CurrencyConfig.currencyCharacter + ')' : '';
    }
  }
};

reaction(() => viewModeStore && viewModeStore.projectID,//reacts if projectID changes/given and changes step & FTEView to BusinessCase default values
  projectID => {
    if (projectID) {
      viewModeStore.step = defaultStepBusinessCase;
      viewModeStore.FTEView = defaultFTEBusinessCase;
    }
  }
);

/* if the cost view is disabled for specific user and the user is currently on cost view mode in "View By Role"(Project), by changing to
"View By Project(Role)" the hourViewMode should be changed from Cost to Hour view, as Cost view is disabled for that user */
reaction(() => !viewModeStore.isUserActiveView && !viewModeStore.showRoleLaborCost && !viewModeStore.showUserLaborCost && viewModeStore.hourViewMode.key === HourViewCost.key,
  (areCostsDisabled) => {
    if (areCostsDisabled) {
      viewModeStore.hourViewMode = HourViewHours;
    }
  }
);

export {
  viewModeStore as default,
  CurrencyConfig
};
