import React from 'react';
import {PrimaryButton, TextButton} from 'titan/react-button';
import PropTypes from 'prop-types';
import {DialogHeader} from './DialogHeader';
import {redrockClient} from '../../../localization/localization';

export const DialogBody = (props) => {
  let {title, onCancel, onSave, onSaveText, onCancelText, isSavedDisabled} = props;

  if (!onSaveText) {
    onSaveText = redrockClient.getTextSync('action.save', 'Save')
  }
  if (!onCancelText) {
    onCancelText = redrockClient.getTextSync('action.cancel', 'Cancel')
  }

  return (
    <div className={'dialog-content'}>
      <DialogHeader title={title} onCancel={onCancel}/>
      <div data-test-id="dialogbox-content" className={'dialogbox-content'}>
        {props.children}
      </div>

      <div className={'dialog-footer'}>
        <PrimaryButton
          onClick={onSave}
          data-test-id={'modal-save-btn'}
          disabled={isSavedDisabled}
        >
          {onSaveText}
        </PrimaryButton>
        <TextButton
          onClick={onCancel}
          data-test-id={'modal-cancel-btn'}
        >
          {onCancelText}
        </TextButton>
      </div>
    </div>
  )
}

DialogBody.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveText: PropTypes.string,
  onCancelText: PropTypes.string,
  isSavedDisabled: PropTypes.bool,
};

