/**
 * Created by anihambardzumyan on 5/25/17.
 */
import {observable} from 'mobx';

const dialogStore = observable({
  dialogVisible: false,
  onConfirm: undefined,
  messageKey: '',
  titleKey: ''
});

const showConfirmationDialog = (onConfirm, messageKey, titleKey) => {
  dialogStore.onConfirm = onConfirm;
  dialogStore.messageKey = messageKey;
  dialogStore.titleKey = titleKey;
  dialogStore.dialogVisible = true;
};

const hideConfirmationDialog = () => {
  dialogStore.dialogVisible = false;
  dialogStore.onConfirm = undefined;
  dialogStore.messageKey = '';
  dialogStore.titleKey = '';
};

export default {
  dialogStore,
  showConfirmationDialog,
  hideConfirmationDialog
}
