/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import ObjectModel from './ObjectModel';
import {Project as ProjectObjCode} from 'workfront-objcodes';
import {isNodeIdDefined} from '../../../shared/services/Utilities';

export default class ObjectProjModel extends ObjectModel {
  plannedStartDate;
  plannedCompletionDate;

  constructor(ID, name, plannedStartDate, plannedCompletionDate, intervals) {
    super(ProjectObjCode, ID, name, isNodeIdDefined(ID), intervals);

    this.plannedStartDate = plannedStartDate;
    this.plannedCompletionDate = plannedCompletionDate;
    this.showProjectLink = isNodeIdDefined(ID);
  }
}
