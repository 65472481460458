import React, {Component} from 'react';
import {Localization, redrockClient} from '../../../../localization/localization';
import {COST_VIEW_KEY, HOUR_VIEW_KEY, HourViews, ViewList} from '../../../constants/ViewEnums';
import {Checkbox} from '@phoenix/all';
import {HourHeaders} from '../../../constants/HourEnum';
import _ from 'lodash';
import ViewModeStore from '../../../stores/ViewModeStore';
import SettingsStore from '../../../stores/SettingsStore';
import {
  getUsePLNInNETCalculationsSettingValue,
  getShowNetCalculationCheckboxValue
} from '../../../services/ColumnsCustomization';
import {observer} from 'mobx-react';
import {Tip} from '@wf-titan/react-popover';
import {topTooltipOptions} from '../../../constants/TooltipOptions';

@observer
export default class CustomizeColumnsDialogContent extends Component {
  props: {
    customColumnOptions: Object,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedMetrics: [],
      selectedViewType: ViewModeStore.activeView ? ViewModeStore.activeView.key : 'project'
    };

    this.handleViewTypeSelection = this.handleViewTypeSelection.bind(this);
    this.addSelectedMetrics = this.addSelectedMetrics.bind(this);
    this.removeSelectedMetrics = this.removeSelectedMetrics.bind(this);
    this.handleUsePLNInNETCalculationsSettingValueChange = this.handleUsePLNInNETCalculationsSettingValueChange.bind(this)
  }

  componentDidMount() {
    let { customColumnOptions } = this.props;

    let initialSelectedMetrics = Object.keys(customColumnOptions).reduce((acc, viewType) => {
      if (!_.isEmpty(customColumnOptions[viewType])) {
        Object.keys(customColumnOptions[viewType]).forEach(metricType => {
          if (!_.isEmpty(customColumnOptions[viewType][metricType])) {
            customColumnOptions[viewType][metricType].forEach(metric => {
              acc.push(viewType + metricType + metric);
            });
          }
        });
      }
      return acc;
    }, []);

    this.setState({
      selectedMetrics: initialSelectedMetrics
    });
  }

  handleViewTypeSelection(event) {
    if (event && event.target) {
      this.setState({
        selectedViewType: event.target.getAttribute('data-type')
      })
    }
  }

  handleMetricTypeChange(header, event) {
    if (event && event.target) {
      let { selectedViewType } = this.state;

      if (event.target.checked) {
        this.addSelectedMetrics(header, ViewModeStore.hourMetrics[selectedViewType]);
      } else {
        this.removeSelectedMetrics(header, ViewModeStore.hourMetrics[selectedViewType]);
      }
    }
  }

  handleMetricChange(header, metric, event) {
    if (event && event.target) {
      if (event.target.checked) {
        this.addSelectedMetrics(header, [metric]);
      } else {
        this.removeSelectedMetrics(header, [metric]);
      }
    }
  }

  addSelectedMetrics(header, metrics = []) {
    if (_.isEmpty(metrics)) {
      return;
    }

    let { selectedViewType, selectedMetrics } = this.state;
    let { customColumnOptions } = this.props;

    let updatedSelectedMetrics = [...selectedMetrics];

    metrics = metrics.filter(metric => updatedSelectedMetrics.indexOf(selectedViewType + header + metric) === -1);

    this.setState({
      selectedMetrics: [...updatedSelectedMetrics, ...metrics.reduce((acc, metric) => {
        acc.push(selectedViewType + header + metric);
        return acc;
      }, [])]
    });

    if (!customColumnOptions[selectedViewType][header]) {
      customColumnOptions[selectedViewType][header] = [];
    }

    customColumnOptions[selectedViewType][header].push(...metrics);
    customColumnOptions[selectedViewType][header].sort((metricOne, metricTwo) => {
      if (metricOne === HOUR_VIEW_KEY || metricTwo === COST_VIEW_KEY) {
        return -1
      }
      if (metricOne === COST_VIEW_KEY || metricTwo === HOUR_VIEW_KEY) {
        return 1;
      }

      return 1;
    });
  }

  removeSelectedMetrics(header, metrics = []) {
    if (_.isEmpty(metrics)) {
      return;
    }

    let { selectedViewType, selectedMetrics } = this.state;
    let { customColumnOptions } = this.props;

    let updatedSelectedMetrics = [...selectedMetrics];

    metrics.filter(metric => updatedSelectedMetrics.indexOf(selectedViewType + header + metric) !== -1).map(metric => {
      if (customColumnOptions[selectedViewType][header]) {
        let customColumnIndex = customColumnOptions[selectedViewType][header].indexOf(metric);
        customColumnOptions[selectedViewType][header].splice(customColumnIndex, 1);
      }

      let index = updatedSelectedMetrics.indexOf(selectedViewType + header + metric);
      updatedSelectedMetrics.splice(index, 1);
    });

    this.setState({
      selectedMetrics: updatedSelectedMetrics
    });
  }

  handleUsePLNInNETCalculationsSettingValueChange = (event) => {
    const {selectedViewType} = this.state;
    SettingsStore.customColumnOptions['resource_planner.customColumns'].updates[selectedViewType].usePLNInNETCalculationsSetting = event.target.checked;
}

  render() {
    let { selectedViewType, selectedMetrics } = this.state;
    const showNetCalculationCheckbox = getShowNetCalculationCheckboxValue(selectedViewType);

    return (
      <div className="resource-planner-customize-column-settings">
        <div className="view-type-section">
          <div className="section-header"><Localization messageKey={'resourceplanner.view.type'}/></div>
          <div className="view-types">
            {
              ViewList.map(view => (
                <div className={`view-type ${selectedViewType === view.key ? 'selected' : ''}`} data-type={view.key} onClick={this.handleViewTypeSelection}><Localization messageKey={view.objectMessageKey}/></div>
              ))
            }
          </div>
        </div>
        <div className="displayed-metrics-section">
          <div className="section-header"><Localization messageKey={'resourceplanner.display.selected.items'}/></div>
          <div className="displayed-metrics">
            {
              Object.keys(HourHeaders[selectedViewType]).map(header =>
                (
                  <div className="displayed-metric-type">
                    <Checkbox
                      checked={
                        ViewModeStore.hourMetrics[selectedViewType].reduce((acc, metric) => {
                          if (selectedMetrics.indexOf(selectedViewType + header + metric) !== -1) {
                            acc++;
                          }

                          return acc;
                        }, 0) === ViewModeStore.hourMetrics[selectedViewType].length
                      }
                      name={redrockClient.getTextSync(redrockClient.getTextSync(HourHeaders[selectedViewType][header].name))}
                      label={`${redrockClient.getTextSync(HourHeaders[selectedViewType][header].displayName)} (${redrockClient.getTextSync(HourHeaders[selectedViewType][header].name)})`}
                      onChange={this.handleMetricTypeChange.bind(this, header)}
                    />
                    <div className="displayed-metric">
                      {
                        ViewModeStore.hourMetrics[selectedViewType].filter(metric => ViewModeStore.showCost || (!ViewModeStore.showCost && metric !== COST_VIEW_KEY)).map(metric =>
                          <Checkbox
                            checked={selectedMetrics.indexOf(selectedViewType + header + metric) !== -1}
                            name={`${selectedViewType}-${header}-${HourViews[metric].key}`}
                            label={<Localization messageKey={HourViews[metric].messageKey}/>}
                            onChange={this.handleMetricChange.bind(this, header, metric)}
                          />
                        )
                      }
                    </div>
                  </div>
                )
              )
            }
          </div>
          {showNetCalculationCheckbox &&
            <div className="net-calculation-items">
              <div className="net-calculation-checkbox">
                <Checkbox
                  name={<Localization messageKey={'resourceplanner.net.calculation.setting.description'}/>}
                  label={<Localization messageKey={'resourceplanner.net.calculation.setting.description'}/>}
                  checked={getUsePLNInNETCalculationsSettingValue(SettingsStore.customColumnOptions['resource_planner.customColumns'].updates, selectedViewType)}
                  onChange={this.handleUsePLNInNETCalculationsSettingValueChange}
                />
              </div>
              <div data-wf-popover-container className="info-icon">
                <Tip {...topTooltipOptions} content={<span>{redrockClient.getTextSync('resourceplanner.net.calculation.info')}</span>}>
                  <div>
                    <svg viewBox="0 0 24 24" height="24" width="24"><g fill="#454B57">
                      <path d="M13.91 1.17A10.54 10.54 0 0012 1a11 11 0 00-1.9 21.83A10.54 10.54 0 0012 23a11 11 0 001.9-21.83zm7.94 12.57A10 10 0 0112 22a10.34 10.34 0 01-1.75-.15A10 10 0 0112 2a10.34 10.34 0 011.75.15 10 10 0 018.11 11.59z"></path><path d="M13.5 16H13v-4.5a.5.5 0 00-.5-.5h-2a.5.5 0 000 1H12v4h-1.5a.5.5 0 000 1h3a.5.5 0 000-1z"></path><circle cx="12" cy="8" r="1"></circle></g>
                    </svg>
                  </div>
                </Tip>
              </div>
            </div>
           }
        </div>
      </div>
    );
  }
}
