/**
 * Created by iskuhihakobyan on 8/8/17.
 */
import {
  User as UserObjCode,
  Project as ProjectObjCode,
  Task as TaskObjCode,
  OpTask as OpTaskObjCode
} from 'workfront-objcodes';

export const ViewEnum = {
  project: 'project',
  role: 'role',
  user: 'user'
};

export const COST_VIEW_KEY = 'Cost';
export const HOUR_VIEW_KEY = 'Hour';
export const FTE_VIEW_KEY = 'FTE';
export const CUSTOM_VIEW_KEY = 'custom';
export const CUSTOMIZE_VIEW_KEY = 'customize';

export const HourViewAttributes = {
  Cost: {
    fteCoefficient: 1,
    roundPrecision: 100
  },
  Hour: {
    fteCoefficient: 1,
    costCoefficient: 1,
    roundPrecision: 100
  },
  FTE: {
    costCoefficient: 1,
    roundPrecision: 1000
  }
};

export const HourViewCost = {
  messageKey: 'resourceplanner.cost',
  key: COST_VIEW_KEY,
  fteCoefficient: HourViewAttributes[COST_VIEW_KEY].fteCoefficient,
  roundPrecision: HourViewAttributes[COST_VIEW_KEY].roundPrecision,
};

export const HourViewHours = {
  messageKey: 'resourceplanner.hours',
  key: HOUR_VIEW_KEY,
  fteCoefficient: HourViewAttributes[HOUR_VIEW_KEY].fteCoefficient,
  costCoefficient: HourViewAttributes[HOUR_VIEW_KEY].costCoefficient,
  roundPrecision: HourViewAttributes[HOUR_VIEW_KEY].roundPrecision,
};

export const HourViewFTE = {
  messageKey: 'resourceplanner.fte',
  key: FTE_VIEW_KEY,
  costCoefficient: HourViewAttributes[FTE_VIEW_KEY].costCoefficient,
  roundPrecision: HourViewAttributes[FTE_VIEW_KEY].roundPrecision,
};

export const HourViewCustom = {
  messageKey: 'custom',
  key: 'custom',
  visible: false
};

export const HourViewCustomize = {
  messageKey: 'customize',
  key: 'customize',
};

export const HourViews = {
  [FTE_VIEW_KEY]: HourViewFTE,
  [HOUR_VIEW_KEY]: HourViewHours,
  [COST_VIEW_KEY]: HourViewCost
};

export const ViewList = [
  {
    key: ViewEnum.project,
    messageKey: 'resourceplanner.project.view',
    objectMessageKey: 'project',
    errorMessageKey: 'resourceplanner.role.budgeted.error',
    budgetMessageKey: 'resourceplanner.role.budget',
    uneditableMessage: 'resourceplanner.budgeting.uneditable.proj.cost',
    learnMoreLinkClassic: 'https://one.workfront.com/s/csh?context=1698&pubname=workfront-classic',
    learnMoreLinkNWE: 'https://one.workfront.com/s/csh?context=1698&pubname=the-new-workfront-experience'
  },
  {
    key: ViewEnum.role,
    messageKey: 'resourceplanner.role.view',
    objectMessageKey: 'role.abbr',
    errorMessageKey: 'resourceplanner.project.budgeted.error',
    budgetMessageKey: 'resourceplanner.project.budget',
    uneditableMessage: 'resourceplanner.budgeting.uneditable.role',
    learnMoreLinkClassic: 'https://one.workfront.com/s/csh?context=1698&pubname=workfront-classic',
    learnMoreLinkNWE: 'https://one.workfront.com/s/csh?context=1698&pubname=the-new-workfront-experience'
  },
  {
    key: ViewEnum.user,
    messageKey: 'resourceplanner.user.view',
    objectMessageKey: 'user'
  }
];

export const ViewSteps = {
  week: {
    key: 'week',
    name: 'week.lowercase'
  },
  month: {
    key: 'month',
    name: 'month.lowercase'
  },
  quarter: {
    key: 'quarter',
    name: 'quarter.lowercase'
  }
};

export const Links = {
  [UserObjCode]: 'user/view',
  [ProjectObjCode]: 'project/view',
  [TaskObjCode]: 'task/view',
  [OpTaskObjCode]: 'issue/view'
};

// The key is the number of columns divided by the divisor (truncating the remainder).  The value is the width.
export const CustomColumnWidths = {
  0: 500,
  1: 1000,
  2: 1500,
  3: 2600,
  4: 3200,
  5: 3400
};

export const USER_CUSTOM_COLUMN_WIDTH_DIVISOR = 2;
export const PROJECT_ROLE_CUSTOM_COLUMN_WIDTH_DIVISOR = 3;

export const getHourViewList = (showCost, showCustomize = true) => {
  let viewList = [HourViewHours, HourViewFTE];

  if (showCost) {
    viewList.push(HourViewCost);
  }

  viewList.push(HourViewCustom);

  if (showCustomize) {
    viewList.push(HourViewCustomize);
  }

  return viewList;
};
