import ObjectModel from './ObjectModel';
import {isNodeIdDefined} from '../../../shared/services/Utilities';
import {Role as RoleObjCode} from 'workfront-objcodes';
import {COST_VIEW_KEY} from '../../../shared/constants/ViewEnums';

export default class ObjectRoleModel extends ObjectModel {

  constructor(ID, name, nodes, parentID, editBudgeting = true) {
    super(RoleObjCode, ID, name, nodes, parentID);

    this.showEquateBudgetedFromUserLink = isNodeIdDefined(ID) && this.expandable;
    this.showSetPlannedHourToBudgetedLink = editBudgeting && isNodeIdDefined(ID) && this.expandable;
    this.showThreeDots = editBudgeting && (this.showSetPlannedHourToBudgetedLink || this.showEquateBudgetedFromUserLink);
    this.editBudgeting = editBudgeting;

    //hour
    this.showOnlyPlannedHour = !isNodeIdDefined(this.ID) || !this.expandable;
  }

  setBDGHour(value, i) {
    let sumNodesPLN = this.nodes.reduce((sum, node) => sum + node[i].PLNHour, 0);
    const costRate = this.getCostRate(i);
    let userBDG;

    if (sumNodesPLN === 0) {
      userBDG = costRate ? (value / this.nodes.length) / costRate : 0;
      this.nodes.forEach(node => node.setPBDGHour(userBDG, i));
    } else {
      this.nodes.forEach(node => {
        userBDG = costRate ? node[i].PLNHour / sumNodesPLN * (value / costRate) : 0;
        node.setPBDGHour(userBDG, i);
      });
    }
  }

  setBDGFTE(value, i) {
    let sumNodesPLNFTE = this.nodes.reduce((sum, node) => sum + node[i].PLNFTE, 0);
    let userBDG;

    if (sumNodesPLNFTE === 0) {
      userBDG = value / this.nodes.length;
      this.nodes.forEach(node => node.setPBDGFTE(userBDG, i));
    } else {
      this.nodes.forEach(node => {
        userBDG = node[i].PLNFTE / sumNodesPLNFTE * value;
        node.setPBDGFTE(userBDG, i);
      });
    }
  }

  setBDGCost(value, i) {
    let sumNodesPLNCost = this.nodes.reduce((sum, node) => sum + node[i].PLNCost, 0);
    const costRate = this.getCostRate(i, COST_VIEW_KEY);
    let userBDG;

    if (sumNodesPLNCost === 0) {
      userBDG = costRate ? (value / this.nodes.length) / costRate : 0;
      this.nodes.forEach(node => node.setPBDGCost(userBDG, i));
    } else {
      this.nodes.forEach(node => {
        userBDG = costRate ? node[i].PLNCost / sumNodesPLNCost * (value / costRate) : 0;
        node.setPBDGCost(userBDG, i);
      });
    }
  }
}
