import '../../../styles/customizeColumns.less';
import React, {Component} from 'react';
import ViewModeStore from '../../stores/ViewModeStore';
import DropDown from '../DropDown';
import DropDownList from '../DropDownList';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import CustomizeColumnsDialogContent from './customizeColumnsDialog/CustomizeColumnsDialogContent';
import APIService from '../../services/APIService';
import {loadingStore} from '../../stores/LoadingStore';
import NotificationStore from '../../stores/NotificationStore';
import SettingsStore from '../../stores/SettingsStore';
import _ from 'lodash';
import {HourViewCustom, HourViewHours} from '../../constants/ViewEnums';
import {redrockClient} from '../../../localization/localization';
import {Dialog} from '@phoenix/all';
import {DialogBody} from '../dialog/DialogBody';

@observer
export default class ShowByFTE extends Component {
  @observable openDialog = false;

  // noinspection JSAnnotator
  props: {
    // noinspection JSAnnotator
    disabled: Boolean,
    treeNodeStore: Object,
    intervalStore: Object,
    loadStoreData: Function
  };

  constructor(props) {
    super(props);

    this.changeDialogState = this.changeDialogState.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.saveCustomColumnSettings = this.saveCustomColumnSettings.bind(this);
    this.changeView = this.changeView.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  saveCustomColumnSettings() {
    this.changeDialogState ();

    loadingStore.loading = true;

    SettingsStore.customColumnOptions['resource_planner.customColumns'].value = JSON.parse(JSON.stringify(SettingsStore.customColumnOptions['resource_planner.customColumns'].updates));

    APIService.saveSettings('resource_planner.customColumns', JSON.stringify(SettingsStore.customColumnOptions['resource_planner.customColumns'].value)).then(() => {
      const customViewListObject = _.find(ViewModeStore.activeHourViewList, ({key}) => key === HourViewCustom.key);

      const customColumnCountForActiveView = ViewModeStore.customColumnCount;

      if (customColumnCountForActiveView === 0) {
        if (ViewModeStore.hourViewMode.key === HourViewCustom.key) {
          this.changeView(HourViewHours);
        }

        if (customViewListObject) {
          customViewListObject.visible = false;
        }
      } else if (customColumnCountForActiveView > 0) {
        this.changeView(HourViewCustom);
        if (customViewListObject) {
          customViewListObject.visible = true;
        }
      }

      NotificationStore.showNotification(redrockClient.getTextSync('resourceplanner.settings.saved.successfully', 'success'));
      loadingStore.loading = false;
    });
  }

  changeDialogState() {
    this.openDialog = !this.openDialog;
  }

  closeDialog() {
    SettingsStore.customColumnOptions['resource_planner.customColumns'].updates = JSON.parse(JSON.stringify(SettingsStore.customColumnOptions['resource_planner.customColumns'].value));

    this.changeDialogState();
  }

  changeView(item) {
    if (item.key === 'customize') {
      this.changeDialogState();
      return;
    }

    if (ViewModeStore.hourViewMode.key === item.key && item.key !== HourViewCustom.key) {
      return;
    }
    ViewModeStore.hourViewMode = item;
    if(ViewModeStore.actualHoursStateChanged) {
      this.resetView();
    }
  }

  @action resetView() {
    if(ViewModeStore.isUserActiveView) {
      loadingStore.loading = true;
      this.props.treeNodeStore.clearTreeNodesStore();
      this.props.intervalStore.clearIntervalStore();

      ViewModeStore.actualHoursStateChanged = false;
      this.props.loadStoreData();
    } else {
      loadingStore.loading = false;
    }
  }

  render() {
    const customViewListObject = _.find(ViewModeStore.activeHourViewList, ({key}) => key === HourViewCustom.key);
    if (customViewListObject) {
      const customColumnCountForActiveView = ViewModeStore.customColumnCount;
      customViewListObject.visible = (customColumnCountForActiveView > 0);
    }

    return (
      <div className="show-by-fte rp-has-dropdown" data-wf-popover-container>
        <DropDown selectedName={redrockClient.getTextSync(ViewModeStore.hourViewMode.messageKey)}
                  disabled={this.props.disabled}>
          <DropDownList listItems={ViewModeStore.activeHourViewList}
                        handleClick={this.changeView}
                        activeItem={ViewModeStore.hourViewMode}/>
        </DropDown>
        {this.openDialog && <Dialog
          width={1000}
        >
          <DialogBody
            title={redrockClient.getTextSync('resourceplanner.settings.customize.columns')}
            onCancel={this.closeDialog}
            onSave={this.saveCustomColumnSettings}
          >
            <CustomizeColumnsDialogContent customColumnOptions={SettingsStore.customColumnOptions['resource_planner.customColumns'].updates}/>
          </DialogBody>
        </Dialog>}
      </div>
    )
  }
}
