import {observable} from 'mobx';
import {NextPreviousEnum} from '../constants/NextPreviousEnum';
import {notify} from '../services/Utilities';

export const loadingStore = observable({
  _loading: true,
  nextPreviousLoading: NextPreviousEnum.none,
  isLazyLoadingMode: false,
  noResults: false,
  clipped: false,
  setPlannedHourToBudgeted: false,
  saveInProcess: false,

  get loading() {
    return this._loading;
  },
  set loading(value) {
    this._loading = value;
    if(this._loading) {
      notify.start();
    } else {
      notify.stop();
    }
  }
});

