/**
 * Created by artakpoghosyan on 2/22/18.
 */
import {ViewPortBreackPoints, ViewPortSizeNames} from '../constants/ViewPortEnum';

const WindowSize = () => {
  let size;
  if (window.innerWidth >= ViewPortBreackPoints.medium) {
    size = ViewPortSizeNames.large;
  } else if (window.innerWidth >= ViewPortBreackPoints.small && window.innerWidth <= ViewPortBreackPoints.medium) {
    size = ViewPortSizeNames.medium;
  } else if (window.innerWidth >= ViewPortBreackPoints.extraSmall && window.innerWidth <= ViewPortBreackPoints.small) {
    size = ViewPortSizeNames.small;
  } else {
    size = ViewPortSizeNames.extraSmall;
  }
  return size;
};

export default WindowSize;
