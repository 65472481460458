import React, {Component} from 'react';
import {observer} from 'mobx-react';
import ScrollStore from '../../stores/ScrollStore';
import {IconButton} from 'titan/react-button';
import If from 'If';
import {getResourcePlannerArea} from '../../services/Utilities';
import ViewModeStore from '../../stores/ViewModeStore';

@observer
export default class FullModeToggle extends Component {
  // noinspection JSAnnotator
  props: {
    disabled: Boolean,
    isFilterVisible: Boolean,
    fullModeToggle: Function
  };

  constructor(props) {
    super(props);
  }


  /*componentWillUnmount() {
    if (ScrollStore.scrollStore.fullMode) {
      this.props.fullModeToggle();
    }
  }
*/
  render() {
    const {disabled, fullModeToggle} = this.props;

    return (
      <div className="rp-full-mode" data-testid={`fullModeButton-${getResourcePlannerArea(ViewModeStore)}`}>
        <IconButton onClick={fullModeToggle} disabled={disabled}>
          <div>
            <If condition={ScrollStore.scrollStore.fullMode}>
              <svg width="14px" height="14px" viewBox="0 0 14 14" className="rp-full-mode-icon">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-1354.000000, -32.000000)" stroke={disabled ? '#BDBDBD' : '#2f609f'}
                     className="stroke-full-mode">
                    <g transform="translate(1354.000000, 32.000000)">
                      <polyline points="8.5 0.5 8.5 5.5 13.5 5.5"/>
                      <path d="M9,5 L13,1"/>
                      <path d="M5.5,13.5 L5.5,8.5 L0.5,8.5 M5,9 L1,13"/>
                    </g>
                  </g>
                </g>
              </svg>
            </If>
            <If condition={!ScrollStore.scrollStore.fullMode}>
              <svg width="14px" height="14px" viewBox="0 0 14 14" className="rp-default-mode-icon">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
                   strokeLinejoin="round">
                  <g transform="translate(-1109.000000, -487.000000)" stroke={disabled ? '#BDBDBD' : '#2f609f'}
                     className="stroke-default-mode">
                    <g transform="translate(1107.000000, 485.000000)">
                      <g
                        transform="translate(8.838835, 8.838835) rotate(-225.000000) translate(-8.838835, -8.838835) translate(0.338835, 4.838835)">
                        <polyline points="3.64742926 -7.46069873e-14 -7.28306304e-14 3.82255391 3.64742926 7.64510781"/>
                        <polyline
                          transform="translate(14.954460, 3.907038) rotate(-180.000000) translate(-14.954460, -3.907038)"
                          points="16.7781746 0.0844838472 13.1307453 3.90703775 16.7781746 7.72959166"/>
                        <path d="M0.729485852,3.84499834 L16.4134317,3.84499834"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

            </If>
          </div>
        </IconButton>
      </div>
    )
  }
}
