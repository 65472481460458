// Libraries
import {computed, observable} from 'mobx';
import _ from 'lodash';
// Stores
import {intervalsFTEs} from '../../shared/stores/IntervalsStore';
import ViewModeStore from '../../shared/stores/ViewModeStore';
import ChangedHoursStore from '../stores/ChangedHoursStore';
// Constants
import {COST_VIEW_KEY, FTE_VIEW_KEY, HOUR_VIEW_KEY, HourViewCost} from '../../shared/constants/ViewEnums';
// Models
import ObjectRoleModel from '../models/nodes/ObjectRoleModel';
import ObjectProjModel from '../models/nodes/ObjectProjModel';
import ObjectUserModel from '../models/nodes/ObjectUserModel';
//Services
import {roundNumber} from '../../shared/services/Utilities';
import dataServiceUtilities from '../../shared/services/DataServiceUtilities';

const roleViewDataService = {
  createRoleNodeModel: (node, intervals, indexes) => {
    let objectModel = new ObjectRoleModel(
      node.ID,
      node.name,
      [],
      !!node.BPC,
      node.editBudgeting
    );

    intervals.forEach((interval) => {
      let hour = interval.hours[objectModel.ID],
        index = dataServiceUtilities.getIndex(interval.from, indexes);
      roleViewDataService.fillRoleNodeHours(objectModel, hour, index, interval.from);
    });

    return objectModel;
  },
  /**
   * after collapse role we delete all subNodes and keep changed BDG hours of role and subNodes
   * after expand the role we should apply the role BDG hour only on that users which PBDG hours were not been changed
   */
  //TODO make it more easy
  updateProjectNodesPBDG(from, roleNode, projectNode, index) {
    let tmpChangedRoles = _.find(ChangedHoursStore.tmpChangedBDGHours.roles, item => item.from === from);

    if ((tmpChangedRoles && tmpChangedRoles.hours[roleNode.ID]) || roleNode[index].isSetPLN || roleNode[index].chg) {
      const sumNodesPLN = projectNode.getSumOfNodesPLN(index);
      const costRate = projectNode.getCostRate(index);
      let projectBDG;
      let tmpBudgetedHours = _.find(ChangedHoursStore.tmpChangedBDGHours.budgetedHours, item => item.from === from);
      let tmpBudgetedUsersHours = null;

      if (tmpBudgetedHours && tmpBudgetedHours.hours[projectNode.ID] && tmpBudgetedHours.hours[projectNode.ID][roleNode.ID]) {
        tmpBudgetedUsersHours = tmpBudgetedHours.hours[projectNode.ID][roleNode.ID];
      }

      if (roleNode[index].isSetPLN) {
        projectBDG = projectNode[index].PLNHour;
      } else {
        const FTE = intervalsFTEs.getFTE(index);
        const BDG = FTE.get() === 0 ? 0 : roleNode[index].BDG / FTE * roleNode.getCostRate(index);
        projectBDG = roleNode.calculateBDG(BDG, index, projectNode);
      }

      projectNode.nodes.forEach(node => {
        if (!node[index].chgPbdg && (!tmpBudgetedUsersHours || !tmpBudgetedUsersHours[node.ID] || !_.isNumber(tmpBudgetedUsersHours[node.ID].PBDG))) {
          const userPBDG = projectNode.calculateBDG(node[index], sumNodesPLN, costRate, projectBDG);
          node.setPBDGHourFromRole(userPBDG, index, !roleNode.showMore)
        }
      });
    }
  },

  createProjectsModels: (nodes, intervals, indexes, roleNode) => {
    if (!nodes) {
      return [];
    }

    let hour;

    return nodes.map((node) => {
      let objectModel = new ObjectProjModel(
        node.ID,
        node.name,
        roleViewDataService.createUserNodesModels(node.nodes, intervals, indexes, roleNode.ID, node.ID, node.editBudgeting),
        roleNode.ID,
        node.plannedStartDate,
        node.plannedCompletionDate,
        node.editBudgeting
      );

      intervals.forEach((interval) => {
        if (_.isEmpty(interval.hours[objectModel.ID])) {
          hour = {};
        } else {
          hour = interval.hours[objectModel.ID];
        }

        let index = dataServiceUtilities.getIndex(interval.from, indexes);
        if (_.isNumber(index)) { //prevent issue when during loading of expand role click on next | prev
          roleViewDataService.fillProjectNodeHours(objectModel, hour, index, roleNode[index].costRate);
          /**
           * when the role BDG is changed
           * we should distribute this change on projects and users which are under the role
           * except the users which are exists in ChangedHoursStore.changedBDGHours
           */
          roleViewDataService.updateProjectNodesPBDG(interval.from, roleNode, objectModel, index);
        }
      });

      return objectModel;
    });
  },

  createUserNodesModels(userNodes, intervals, indexes, roleID, projectID, editBudgeting = true) {
    if (!userNodes) {
      return [];
    }

    return userNodes.map((userNode) => {
      let userHour = {};
      let objectUserModel = new ObjectUserModel(userNode.ID, userNode.name, roleID, null, editBudgeting);

      intervals.forEach((interval) => {
        if (!_.isEmpty(interval.hours[projectID])) {
          userHour = interval.hours[projectID][objectUserModel.ID];
        }

        roleViewDataService.fillUserNodeHours(objectUserModel, userHour, dataServiceUtilities.getIndex(interval.from, indexes), interval.from, roleID, projectID);
      });

      return objectUserModel;
    });
  },

  fillRoleNodeHours(objectModel, hour, index, from) {
    let FTE = intervalsFTEs.getFTE(index);
    let changedData = ChangedHoursStore.getChangedRoleData(objectModel.ID, from);

    if (hour) {
      let roleBDG = _.isNumber(changedData.BDG) ? changedData.BDG : hour.BDG || 0;
      let roleBPLN = hour.BPLN || 0;

      if (changedData.setPLN) {
        roleBDG = roleBPLN;
      }

      objectModel[index] = {
        chg: changedData.isChanged,
        costRate: hour.COST_RATE || 0,
        PLNCost: hour.PLN_COST || 0,
        BPLN: roleBPLN,
        BPLNCost: hour.BPLN_COST || 0,
        @observable BDG: roleBDG,
        notEmptyNodesCount: hour.BPC || 0,
        isSetPLN: changedData.setPLN,
        get BPLNHour() {
          if (ViewModeStore.hourViewMode.key === HourViewCost.key) {
            return objectModel[index].BPLNCost || 0
          }
          if (FTE.get() === 0) {
            return 0;
          }
          return objectModel[index].BPLN / FTE;
        },
        get BPLNFTE() {
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          return objectModel[index].BPLN / FTE;
        },
        get PLNHour() {
          if (ViewModeStore.hourViewMode.key === HourViewCost.key) {
            return objectModel[index].PLNCost
          }
          if (FTE.get() === 0) {
            return 0;
          }

          return (hour.PLN || 0) / FTE;
        },
        @computed get PLNFTE() {
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          return (hour.PLN || 0) / FTE;
        },
        @computed get AVLHour() {
          if (FTE.get() === 0) {
            return 0;
          }
          let AVL = !hour.AVL || hour.AVL < 0 ? 0 : hour.AVL;

          return AVL / FTE * objectModel.getCostRate(index);
        },
        @computed get AVLFTE() {
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          let AVL = !hour.AVL || hour.AVL < 0 ? 0 : hour.AVL;

          return AVL / FTE;
        },
        @computed get AVLCost() {
          let AVL = !hour.AVL || hour.AVL < 0 ? 0 : hour.AVL;

          return AVL * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        /**
         * role BDG hour is the
         * it's oun BDG hour
         * plus sum of loaded nodes initial and changed PBDG hours difference
         * plus sum of not loaded nodes changed and initial BDG hours difference which was stored before
         */
          @computed get BDGHour() {
          let loadedProjectIDs = [], subNodesBDGHours = 0;

          _.forEach(objectModel.nodes, node => {
            loadedProjectIDs.push(node.ID);
            if (node[index]) {
              subNodesBDGHours += node[index].BDGHourForRole;
            }
          });

          let PBDGVariance = ChangedHoursStore.getChangedAndNotLoadedUsersPBDGVariance(from, objectModel.ID, loadedProjectIDs);
          if (FTE.get() === 0) {
            return 0;
          }
          return (objectModel[index].BDG + PBDGVariance) / FTE * objectModel.getCostRate(index) + subNodesBDGHours;
        },

        @computed get BDGFTE() {
          let loadedProjectIDs = [], subNodesBDGFTEs = 0;

          _.forEach(objectModel.nodes, node => {
            loadedProjectIDs.push(node.ID);
            if (node[index]) {
              subNodesBDGFTEs += node[index].BDGFTEForRole;
            }
          });

          let PBDGVariance = ChangedHoursStore.getChangedAndNotLoadedUsersPBDGVariance(from, objectModel.ID, loadedProjectIDs);
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          return (objectModel[index].BDG + PBDGVariance) / FTE + subNodesBDGFTEs;
        },

        @computed get BDGCost() {
          let loadedProjectIDs = [], subNodesBDGCost = 0;

          _.forEach(objectModel.nodes, node => {
            loadedProjectIDs.push(node.ID);
            if (node[index]) {
              subNodesBDGCost += node[index].BDGCostForRole;
            }
          });

          let PBDGVariance = ChangedHoursStore.getChangedAndNotLoadedUsersPBDGVariance(from, objectModel.ID, loadedProjectIDs);
          return (objectModel[index].BDG + PBDGVariance) * objectModel.getCostRate(index, COST_VIEW_KEY) + subNodesBDGCost;
        },
      };
    } else {
      objectModel[index] = {};
    }
  },

  fillProjectNodeHours(objectModel, hour, index, costRate) {
    let FTE = intervalsFTEs.getFTE(index);
    if (hour) {
      objectModel[index] = {
        costRate: costRate || 0,
        PLNCost: hour.PLN_COST || 0,
        get PLNHour() {
          if (ViewModeStore.hourViewMode.key === HourViewCost.key) {
            return objectModel[index].PLNCost || 0
          }
          if (FTE.get() === 0) {
            return 0;
          }
          return (hour.PLN || 0) / FTE;
        },
        @computed get AVLHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLHour, 0) * objectModel.getCostRate(index);
        },
        @computed get AVLFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLFTE, 0);
        },
        @computed get AVLCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PAVLCost, 0) * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        @computed get BDGHourForRole() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + (node[index].PBDGHour - node[index].initialPBDG), 0) * objectModel.getCostRate(index);
        },
        @computed get BDGFTEForRole() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + (node[index].PBDGFTE - node[index].getInitialPBDGValue(FTE_VIEW_KEY)), 0);
        },
        @computed get BDGCostForRole() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + (node[index].PBDGCost - node[index].getInitialPBDGValue(COST_VIEW_KEY)), 0) * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        @computed get BDGUserHour() { //It's used when click on total BDG hour for project
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].BDGHour, 0);
        },
        @computed get BDGHour() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGHour, 0) * objectModel.getCostRate(index);
        },
        @computed get BDGFTE() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGFTE, 0);
        },
        @computed get BDGCost() {
          return _.reduce(objectModel.nodes, (sum, node) => sum + node[index].PBDGCost, 0) * objectModel.getCostRate(index, COST_VIEW_KEY);
        },
        @computed get PLNFTE() {
          const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
          if (FTE.get() === 0) {
            return 0;
          }
          return (hour.PLN || 0) / FTE;
        },
        get hasInvalidBDGUserHour() {
          return roundNumber(objectModel[index].BDGUserHour) > roundNumber(objectModel[index].BDGHour);
        }
      };
    } else {
      objectModel[index] = {};
    }
  },

  fillUserNodeHours(objectModel, hour, index, from, roleID, projectID) {
    let FTE = intervalsFTEs.getFTE(index);
    let changedHours = ChangedHoursStore.getChangedUserBDG(from, roleID, projectID, objectModel.ID);

    let BDG = _.isNumber(changedHours.BDG) ? changedHours.BDG : hour.BDG || 0;
    let PBDG = _.isNumber(changedHours.PBDG) ? changedHours.PBDG : hour.PBDG || 0;
    let initialPBDG = _.isNumber(changedHours.initialPBDG) ? changedHours.initialPBDG : hour.PBDG || 0;

    objectModel[index] = {
      costRate: hour.COST_RATE || 0,
      PLNCost: hour.PLN_COST || 0,
      _AVL: hour.AVL || 0,
      _PAVL: hour.PAVL || 0,
      _PLN: hour.PLN || 0,
      chgBdg: changedHours.isChangedBDG,
      chgPbdg: changedHours.isChangedPBDG,
      filteredOutBDG: hour.FBDG || 0,
      filteredOutPBDG: hour.FPBDG || 0,
      @observable _BDG: BDG,
      @observable _PBDG: PBDG,
      @observable _initialPBDG: initialPBDG,

      get PLNHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        return ViewModeStore.hourViewMode.key === HourViewCost.key ? objectModel[index].PLNCost : objectModel[index]._PLN / FTE
      },
      get BDGHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._BDG / FTE * objectModel.getCostRate(index);
      },
      get BDGFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._BDG / FTE;
      },
      get BDGCost() {
        return objectModel[index]._BDG * objectModel.getCostRate(index, COST_VIEW_KEY);
      },
      @computed get PLNFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (FTE.get() === 0) {
          return 0;
        }
        return (hour.PLN || 0) / FTE;
      },
      @computed get PBDGHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._PBDG / FTE
      },
      @computed get PBDGFTE() {
        const FTE = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (FTE.get() === 0) {
          return 0;
        }
        return objectModel[index]._PBDG / FTE;
      },
      @computed get PBDGCost() {
        return objectModel[index]._PBDG;
      },
      @computed get initialPBDG() {
        return this.getInitialPBDGValue();
      },
      getInitialPBDGValue(metric = HOUR_VIEW_KEY) {
        const fteValue = intervalsFTEs.getFTE(index, metric);
        if (fteValue.get() === 0) {
          return 0;
        }

        return objectModel[index]._initialPBDG / fteValue
      },
      @computed get AVLHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        const filteredOutBDG = objectModel[index].filteredOutBDG / FTE * objectModel.getCostRate(index);
        const value = objectModel[index]._AVL / FTE * objectModel.getCostRate(index) - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGHour : value;
      },
      @computed get AVLFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (fteValue.get() === 0) {
          return 0;
        }
        const filteredOutBDG = objectModel[index].filteredOutBDG / fteValue;
        const value = objectModel[index]._AVL / fteValue - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGFTE : value;
      },
      @computed get AVLCost() {
        const costRateValue = objectModel.getCostRate(index, COST_VIEW_KEY);
        const filteredOutBDG = objectModel[index].filteredOutBDG * costRateValue;
        const value = objectModel[index]._AVL * costRateValue - filteredOutBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherBDGCost : value;
      },
      @computed get PAVLHour() {
        if (FTE.get() === 0) {
          return 0;
        }
        const filteredOutPBDG = objectModel[index].filteredOutPBDG / FTE;
        const value = objectModel[index]._PAVL / FTE - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGHour : value;
      },
      @computed get PAVLFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        if (fteValue.get() === 0) {
          return 0;
        }
        const filteredOutPBDG = objectModel[index].filteredOutPBDG / fteValue;
        const value = objectModel[index]._PAVL / fteValue - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGFTE : value;
      },
      @computed get PAVLCost() {
        const filteredOutPBDG = objectModel[index].filteredOutPBDG;
        const value = objectModel[index]._PAVL - filteredOutPBDG;

        return objectModel.prevProjectUserModel ? value - objectModel.prevProjectUserModel[index].higherPBDGCost : value;
      },
      @computed get higherBDGHour() {
        const filteredOutBDG = FTE.get() === 0 ? 0 : objectModel[index].filteredOutBDG / FTE * objectModel.getCostRate(index);
        const value = objectModel[index].BDGHour + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGHour : value;
      },
      @computed get higherBDGFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        const filteredOutBDG = fteValue.get() === 0 ? 0 : objectModel[index].filteredOutBDG / fteValue;
        const value = objectModel[index].BDGFTE + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGFTE : value;
      },
      @computed get higherBDGCost() {
        const filteredOutBDG = objectModel[index].filteredOutBDG * objectModel.getCostRate(index, COST_VIEW_KEY);
        const value = objectModel[index].BDGCost + filteredOutBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherBDGCost : value;
      },
      @computed get higherPBDGHour() {
        const filteredOutPBDG = FTE.get() === 0 ? 0 : objectModel[index].filteredOutPBDG / FTE;
        const value = objectModel[index].PBDGHour + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGHour : value;
      },
      @computed get higherPBDGFTE() {
        const fteValue = intervalsFTEs.getFTE(index, FTE_VIEW_KEY);
        const filteredOutPBDG = fteValue.get() === 0 ? 0 : objectModel[index].filteredOutPBDG / fteValue;
        const value = objectModel[index].PBDGFTE + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGFTE : value;
      },
      @computed get higherPBDGCost() {
        const filteredOutPBDG = objectModel[index].filteredOutPBDG;
        const value = objectModel[index].PBDGCost + filteredOutPBDG;
        return objectModel.prevProjectUserModel ? value + objectModel.prevProjectUserModel[index].higherPBDGCost : value;
      },
    };
  }
};

export default roleViewDataService;
