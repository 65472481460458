import { createReactLifecycles } from 'single-spa-workfront';
import { ResourcePlannerContainer } from './index';

export { ResourcePlanner } from './index';

export const Component = ResourcePlannerContainer;

const lifecycles = createReactLifecycles({
    suppressComponentDidCatchWarning: true,
    rootComponent: ResourcePlannerContainer,
});

export const { bootstrap } = lifecycles;
export const { mount } = lifecycles;
export const { unmount } = lifecycles;
