import {observable} from 'mobx';
import ViewModeStore from '../../../shared/stores/ViewModeStore';
import {HOUR_VIEW_KEY, HourViewAttributes, ViewEnum} from '../../../shared/constants/ViewEnums';
import {
  checkIsNetEqualToAvlMinusPln,
  getUsePLNInNETCalculationsSettingValue
} from '../../../shared/services/ColumnsCustomization';
import SettingsStore from '../../../shared/stores/SettingsStore';

export default class ObjectModel {
  ID;
  objCode;
  name;
  parentID;
  expandable;
  @observable toggled;

  constructor(objCode, ID, name, expandable) {
    this.objCode = objCode;
    this.ID = ID;
    this.name = name;
    this.expandable = expandable;
    this.toggled = this.expandable ? false : undefined;
  }

  getCostRate(i, metric = HOUR_VIEW_KEY) {
    const costCoefficient = ViewModeStore.isCustomViewMode && HourViewAttributes[metric] ? HourViewAttributes[metric].costCoefficient : ViewModeStore.hourViewMode.costCoefficient;
    return costCoefficient || this[i].costRate;
  }

  getShowNetDiffOfAvlAndPln () {
    return checkIsNetEqualToAvlMinusPln(
      ViewModeStore.isCustomViewMode,
      getUsePLNInNETCalculationsSettingValue(SettingsStore.customColumnOptions['resource_planner.customColumns'].updates, ViewEnum.role)
    );
  }

  NETHour(i) {
    return this.getShowNetDiffOfAvlAndPln() ? this[i].AVLHour - this[i].PLNHour : this[i].AVLHour - this[i].BDGHour;
  }

  NETFTE(i) {
    return this.getShowNetDiffOfAvlAndPln() ? this[i].AVLFTE - this[i].PLNFTE : this[i].AVLFTE - this[i].BDGFTE;
  }

  NETCost(i) {
    return this.getShowNetDiffOfAvlAndPln() ? this[i].AVLCost - this[i].PLNCost : this[i].AVLCost - this[i].BDGCost;
  }

  VARHour(i) {
    return this[i].BDGHour - this[i].PLNHour;
  }

  VARFTE(i) {
    return this[i].BDGFTE - this[i].PLNFTE;
  }

  VARCost(i) {
    return this[i].BDGCost - this[i].PLNCost;
  }
}
