/**
 * Created by artakpoghosyan on 6/5/18.
 */
import React from 'react';
import OverlayStore from '../stores/OverlayStore';

const ResourcePlannerOverlay = () => (
  <div className={`planner-overlay ${OverlayStore.overlay.fullScreen ? 'planner-overlay-full' : ''}`}></div>
);

export default ResourcePlannerOverlay;
