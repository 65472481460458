/**
 * Created by artakpoghosyan on 8/8/17.
 */
export const NextPreviousEnum = {
  previous: -1,
  none: false,
  next: 1
};


export const PRIORITY_MAX_VALUE = 1000000;
