const MonthsMessageKeys = [
  'january.abbr',
  'february.abbr',
  'march.abbr',
  'april.abbr',
  'may.abbr',
  'june.abbr',
  'july.abbr',
  'august.abbr',
  'september.abbr',
  'october.abbr',
  'november.abbr',
  'december.abbr'
];

export default MonthsMessageKeys;

export const DATE_FORMAT = 'YYYY-MM-DD';
