const GoogleAnalyticsTracker = {
  trackEvent: function (category, action, label, value) {
    if (window._gaq) {
      // Legacy Google Analytics (ga.js)
      window._gaq.push(['_trackEvent', category, action, label, value]);
    }
    else if (window.ga) {
      // Universal Google Analytics (analytics.js)
      window.ga('send', 'event', category, action, label, value, {nonInteraction: 1});
    }
    else {
      console.warn('Google Analytics was not installed/configured properly on this page!');
    }
  }
};

export default GoogleAnalyticsTracker;
