/**
 * Created by artakpoghosyan on 2/9/18.
 */
import React, { Component } from 'react';
import { PageContentSlot } from '@wf-mfe/layout';
import { MFELoggerAndReactErrorBoundary } from '@wf-mfe/logger';
import { observer } from 'mobx-react';
import ResourcePlannerComponent from './shared/components/ResourcePlanner';
import './shared/services/ClosestPolyfill';
import ViewModeStore from './shared/stores/ViewModeStore';
import { ViewEnum, ViewList } from './shared/constants/ViewEnums';
import _ from 'lodash';
import {
  LoadLocalizations,
  LocalizationProvider,
  Localization,
  redrockClient,
} from './localization/localization';
import { MessageKeys } from './localization/messageKeys';

const { ErrorBoundary } = MFELoggerAndReactErrorBoundary({ name: '@wf-mfe/resource-planning-ui' });

@observer
export class ResourcePlanner extends Component {
  //noinspection JSAnnotator
  props: {
    //this property for planner support in BC
    disableWindowEvents: ?Boolean,
    fullModeButtonVisible: ?Boolean,
    className: ?String,
    filterVisible: ?Boolean,
    viewModeVisible: ?Boolean,
    settingsVisible: ?Boolean,
    visualizationVisible: ?Boolean,
    activeViewMode: ?String,
    saveCallbackHandler: Function,
    cancelCallbackHandler: Function,
    stepCount: Number,
    step: ?String,
    hidePriority: Boolean,
    projectID: ?String,
    enablePoolSelection: Boolean,
    urlGeneratorVisible: Boolean,
    shareableLink: ?String,
    modeFTE: ?String,
    fullMode: ?Boolean,
    filterID: ?String,
    customColumns: ?String,
    showProjectPriorityAlign: Boolean,
  };

  static defaultProps = {
    disableWindowEvents: false,
    fullModeButtonVisible: true,
    fullMode: false,
    className: '',
    filterID: '',
    step: '',
    filterVisible: true,
    viewModeVisible: true,
    settingsVisible: true,
    visualizationVisible: true,
    hidePriority: false,
    enablePoolSelection: false,
    urlGeneratorVisible: true,
    customColumns: '',
    saveCallbackHandler: () => {},
    cancelCallbackHandler: () => {},
    showProjectPriorityAlign: true,
  };

  shareableLinkProps = {};

  constructor(props) {
    super(props);

    if (!props.viewModeVisible && props.activeViewMode && ViewEnum[props.activeViewMode]) {
      ViewModeStore.view = _.find(ViewList, ({ key }) => key === props.activeViewMode);
    }

    if (props.shareableLink) {
      const decoded = atob(decodeURIComponent(props.shareableLink));
      const shareableLinkData = Object.fromEntries(new URLSearchParams(decoded));
      const {
        full,
        filter,
        count,
        step,
        mode,
        view,
        hideView,
        hideURLGenerator,
        hideFullScreen,
        customColumns,
      } = shareableLinkData;

      this.shareableLinkProps = {
        fullMode: full,
        filterID: filter,
        stepCount: count,
        step: step,
        modeFTE: mode,
        activeViewMode: view,
        viewModeVisible: !hideView,
        urlGeneratorVisible: !hideURLGenerator,
        fullModeButtonVisible: !hideFullScreen,
        customColumns: customColumns,
      };
    }
  }

  render() {
    return (
      <div data-wf-reactroot>
        <LocalizationProvider clientFactory={() => redrockClient}>
          <LoadLocalizations messageKeys={MessageKeys}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <ResourcePlannerComponent {...this.props} {...this.shareableLinkProps} />
            </ErrorBoundary>
          </LoadLocalizations>
        </LocalizationProvider>
      </div>
    );
  }
}

// almost the same as the one from @wf-mfe/logger
// except for the dependency on react-router's Router/useLocation
function Fallback() {
  return (
    <div
      className="subpage-error pt-4 flex flex-col items-center"
      role="alert"
    >
      <h1 className="h2 mr-8 ml-8">
        <Localization
          messageKey="quicksilver.loading.error"
          fallback="An error has occurred and we are working to resolve the issue. To continue with your work, try refreshing this browser page."
        />
      </h1>
    </div>
  )
}

export const ResourcePlannerContainer = (options) => {
  const shareableLink = window.location.href.split('p=')[1];

  return (
    <PageContentSlot mfeName="@wf-mfe/resource-planning-ui">
      <div
        className={`responsive-content flex flex-col flex-1 h-full w-full overflow-auto relative z-0 pb-0 ${
          shareableLink ? '' : 'p-4'
        }`}
      >
        <ResourcePlanner
          {...options}
          qs={!shareableLink}
          shareableLink={shareableLink}
        />
      </div>
    </PageContentSlot>
  );
};
