/**
 * Created by iskuhihakobyan on 3/22/17.
 */
import ObjectModel from './ObjectModel';
import {Role as RoleObjCode} from 'workfront-objcodes';

export default class ObjectRoleModel extends ObjectModel {

  constructor(ID, name, intervals) {
    super(RoleObjCode, ID, name, true, intervals);

    this.showThreeDots = false;
  }
}
