/**
 * Created by iskuhihakobyan on 4/5/17.
 */
//Libraries
import {observable} from 'mobx';
import _ from 'lodash';
// Stores
import ChangedHoursStore from './ChangedHoursStore';
// Constants
import {User as UserObjCode} from 'workfront-objcodes';
import {viewLimits} from '../../shared/constants/LimitConstants';
import {IDExpOptions} from '../../shared/constants/ExpressionOptions';
// Services
import roleViewDataService from '../services/DataService';
import dataServiceUtilities from '../../shared/services/DataServiceUtilities';
import {intervalsFTEs} from '../../shared/stores/IntervalsStore';

export default class TreeNodesStore {
  @observable continueLazyLoading = true;
  @observable nodesIDs = [];
  renderedStartIndex = 0;
  renderedStopIndex = 0;
  nodes = [];
  intervalsIndexes = [];
  currentRowsCount = 0;
  lazyLoadOffset = 0;

  addNodes(result, IDExp = '') {
    let ids;
    if (!IDExp) {
      ids = this.addRoleNodes(result);
    } else {
      ids = this.addProjectUserNodes(result, IDExp);
    }

    let loadingIndex = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.loading}`);
    let spliceIndex = loadingIndex !== -1 ? loadingIndex : this.nodesIDs.length;
    this.nodesIDs.splice(spliceIndex, 0, ...ids);
  }

  addRoleNodes(result) {
    let ids = [];
    this.lazyLoadOffset += result.items.length;

    if (!result.hasMore || result.clipped || this.lazyLoadOffset >= viewLimits.role.firstLevelLimit) {
      this.continueLazyLoading = false;
    }

    result.items.forEach(node => {
      this.nodes.push(roleViewDataService.createRoleNodeModel(node, result.intervals, this.intervalsIndexes));
      ids.push(node.ID);
      this.currentRowsCount += node.rows;
    });

    if (this.lazyLoadOffset >= viewLimits.role.firstLevelLimit) {
      ids.push(IDExpOptions.notification);
    } else if (result.clipped) {
      ids.push(IDExpOptions.notificationRows);
    }

    return ids;
  }

  addProjectUserNodes(result, IDExp) {//here IDExp is the role ID
    let ids = [];
    let roleNode = _.find(this.nodes, node => node.ID === IDExp);

    //this is create and add second and third level models to nodes
    roleNode.nodes.push(
      ...roleViewDataService.createProjectsModels(result.items, result.intervals, this.intervalsIndexes, roleNode)
    );
    this.updateAvailableByPriority(roleNode.nodes);

    result.items.forEach(node => {
      ids.push(`${IDExp}_${node.ID}`);
    });

    if (!result.hasMore || result.clipped || roleNode.nodes.length < roleNode.offset) {
      roleNode.showMore = false;
    }

    //Show more only for project level
    if (roleNode.showMore) {
      ids.push(`${IDExp}${IDExpOptions.more}`);
    }

    return ids;
  }

  insertUserNodeIDs(IDExp) {
    let levels = IDExp.split('_');
    let ids = [];
    let index = this.nodesIDs.indexOf(IDExp);

    let userNodes = _.find(_.find(this.nodes, ({ID}) => ID === levels[0]).nodes, ({ID}) => ID === levels[1]).nodes;

    userNodes.forEach(node => {
      ids.push(`${IDExp}_${node.ID}`);
    });

    this.nodesIDs.splice(index + 1, 0, ...ids);
  }

  updateAvailableByPriority(nodes, projects = {}) {
    nodes.forEach((node) => {
      if (!node) {
        return;
      }

      if (node.objCode === UserObjCode) {
        node.prevProjectUserModel = projects[node.ID] || null;
        projects[node.ID] = node;
      } else {
        this.updateAvailableByPriority(node.nodes, projects);
      }
    });
  }

  addLoadingRow(IDExp = '') {
    if (!IDExp) {
      //add loading row at the end of array
      this.nodesIDs.push(`${IDExp}${IDExpOptions.loading}`);
    } else {
      const indexOfLoadMore = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.more}`);

      if (indexOfLoadMore !== -1) {
        //add loading instead of loadMore
        this.nodesIDs.splice(indexOfLoadMore, 1, `${IDExp}${IDExpOptions.loading}`);
      } else {
        //add loading after clicked node
        this.nodesIDs.splice(this.nodesIDs.indexOf(IDExp) + 1, 0, `${IDExp}${IDExpOptions.loading}`);
      }
    }
  }

  deleteLoadingRow(IDExp = '') {
    let loadingIndex = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.loading}`);
    this.nodesIDs.splice(loadingIndex, 1);
  }

  deleteSubNodes(IDExp) {
    let IDExpArr = IDExp.split('_');
    let start = this.nodesIDs.indexOf(IDExp) + 1;
    let deleteCount = 0;
    let length = this.nodesIDs.length;

    if (IDExpArr.length === 1) {
      let node = _.find(this.nodes, node => node.ID === IDExpArr[0]);
      node.nodes = [];
    }

    for (let i = start; i < length; i++) {
      if (this.nodesIDs[i].indexOf(IDExp + '_') === -1) {
        break;
      }
      deleteCount++;
    }
    this.nodesIDs.splice(start, deleteCount);
  }

  get unSavedChangesExists() {
    return this.hasChangedBDGHours();
  }

  hasChangedBDGHours() {
    if (!_.isEmpty(ChangedHoursStore.changedBDGHours.roles)
      || !_.isEmpty(ChangedHoursStore.changedBDGHours.plannedRoles)
      || !_.isEmpty(ChangedHoursStore.changedBDGHours.budgetedHours)) {
      return true;
    }

    let hasChangeBdg = false;

    _.forEach(this.nodes, roleNode => { // role level
      this.intervalsIndexes.forEach(item => {
        if (roleNode[item.index] && (roleNode[item.index].chg || roleNode[item.index].isSetPLN)) {
          hasChangeBdg = true;
          return false;
        }
      });

      if (hasChangeBdg) {
        return false;
      }

      roleNode.nodes.forEach(projectNode => { // project level
        if (hasChangeBdg) {
          return false;
        }

        projectNode.nodes.forEach(userNode => { // user level
          if (hasChangeBdg) {
            return false;
          }

          this.intervalsIndexes.forEach(item => {
            if (userNode[item.index] && (userNode[item.index].chgBdg || userNode[item.index].chgPbdg)) {
              hasChangeBdg = true;
              return false;
            }
          });
        });
      });
    });

    return hasChangeBdg;
  }

  setPlannedHoursToBudgeted(roleID, projectID) {
    const roleNode = _.find(this.nodes, ({ID}) => ID === roleID);
    if (projectID) {
      const projectNode = _.find(roleNode.nodes, ({ID}) => ID === projectID);

      projectNode.nodes.forEach(user => {
        this.intervalsIndexes.forEach(({index}) => {
          if (user[index].PLNHour !== 0 || (user[index].PLNHour === 0 && user[index].BDGHour !== 0)) {
            user.setBDGHour(user[index].PLNHour, index);
          }
        });
      });
    } else {
      this.intervalsIndexes.forEach(({index, from}) => {
        roleNode.setPLNToBDG(index);
        ChangedHoursStore.removeStoredPBDGHours(roleNode.ID, from);
      });
    }
  }

  changeNodesBDGHoursByTmpDataAfterSave(tmpData) {
    const roleIDs = [];
    const roleNodes = {};

    tmpData.forEach(tmpDataItem => {
      this.intervalsIndexes.forEach(intervalIndexItem => {

        if (intervalIndexItem.from === tmpDataItem.from) {
          _.forEach(tmpDataItem.hours, (roleData, projectID) => {

            Object.keys(roleData).forEach(roleID => {
              const index = intervalIndexItem.index;

              if (roleIDs.indexOf(roleID) === -1) {
                roleIDs.push(roleID);
                const roleNode = _.find(this.nodes, ({ID}) => ID === roleID);
                roleNode[intervalIndexItem.index].BDG = roleNode[index].BDGHour * intervalsFTEs.getFTE(index) / roleNode.getCostRate(index);
                roleNodes[roleID] = roleNode;
              }

              roleNodes[roleID].nodes.forEach(projectNode => {
                projectNode.nodes.forEach(userNode => {
                  if (projectNode.ID === projectID && roleData[roleID] && roleData[roleID][userNode.ID]) {
                    userNode.setPBDGHourFromRole(roleData[roleID][userNode.ID].PBDG / intervalsFTEs.getFTE(index), index, false);
                  }
                });
              });

            });
          });
        }
      })
    });
  }

  equateBudgetedFromUser(roleID, projectID) {
    const project = _.find(_.find(this.nodes, ({ID}) => ID === roleID).nodes, ({ID}) => ID === projectID);

    this.intervalsIndexes.forEach(({index}) => {
      project.setBDGHour(project[index].BDGUserHour, index);
    });
  }

  removeHours(intervalIndexes, nodes = null) {
    (nodes || this.nodes).forEach(node => {

      if (node.objCode === 'ROLE') {
        node.isInLoadingMode = true;
      }

      intervalIndexes.forEach(({index}) => {
        delete node[index];
      });

      if (node.nodes) {
        this.removeHours(intervalIndexes, node.nodes);
      }
    });
  }

  getRequestParams() { // TODO request missing data by role ID is the best way
    let offsetStart, offsetEnd, stepInfo = [];
    let renderedFirstRoleID = this.nodesIDs.length > 0 ? this.nodesIDs[this.renderedStartIndex].split('_')[0] : null;
    let renderedLastRoleID = this.nodesIDs.length > 0 ? this.nodesIDs[this.renderedStopIndex].split('_')[0] : null;
    if (!renderedFirstRoleID) {
      return null;
    }
    /**
     * renderedLastRoleID can be empty when the last rendered item is a limitation reached message
     */
    if (!renderedLastRoleID) {
      renderedLastRoleID = this.nodesIDs[this.renderedStopIndex - 1].split('_')[0];
    }

    const startIndex = _.findIndex(this.nodes, node => node.ID === renderedFirstRoleID);
    const stopIndex = _.findIndex(this.nodes, node => node.ID === renderedLastRoleID);

    for (let i = startIndex; i <= stopIndex; i++) {
      let hasUnloaded = false;

      _.forEach(this.intervalsIndexes, ({index}, itr) => {
        if (!this.nodes[i][index]) {
          hasUnloaded = true;

          if (stepInfo.indexOf(itr) === -1) {
            stepInfo.push(itr);
          }

          return null;
        }
      });

      if (hasUnloaded) {
        offsetEnd = i;

        if (typeof offsetStart === 'undefined') {
          offsetStart = i;
        }
      }
    }

    if (stepInfo.length) {
      stepInfo.sort((a, b) => a - b);
      return {
        offset: offsetStart,
        limit: offsetEnd - offsetStart + 1,
        stepCount: stepInfo[stepInfo.length - 1] - stepInfo[0] + 1,
        fromDate: this.intervalsIndexes[stepInfo[0]].from
      }
    } else {
      return null;
    }
  }

  fillRoleNodeHours(intervals, roleNode) {
    intervals.forEach((interval) => {
      let hour = interval.hours[roleNode.ID];
      /**
       * index can be undefined when
       * during loading of missing data the next / prev / today button has been clicked
       */
      let index = dataServiceUtilities.getIndex(interval.from, this.intervalsIndexes);
      if (_.isNumber(index)) {
        roleViewDataService.fillRoleNodeHours(roleNode, hour, index, interval.from);
      }
    });

    roleNode.isInLoadingMode = false;
  }

  fillProjectNodeAndUsersHours(intervals, projectNode, roleNode) {
    intervals.forEach((interval) => {
      let index = dataServiceUtilities.getIndex(interval.from, this.intervalsIndexes);
      if (_.isNumber(index)) {
        let projectHour = interval.hours[projectNode.ID] || {};

        projectNode.nodes.forEach(userNode => {
          let userHour = _.isEmpty(interval.hours[projectNode.ID]) ? {} : interval.hours[projectNode.ID][userNode.ID];

          roleViewDataService.fillUserNodeHours(userNode, userHour, index, interval.from, roleNode.ID, projectNode.ID);
        });

        roleViewDataService.fillProjectNodeHours(projectNode, projectHour, index, roleNode[index].costRate);
        roleViewDataService.updateProjectNodesPBDG(interval.from, roleNode, projectNode, index);
      }
    });
  }

  emptyNodesChangedBDGHours() {
    this.intervalsIndexes.forEach(({index}) => {
      this.nodes.forEach(roleNode => {
        if (roleNode[index]) {
          roleNode[index].chg = false;
          roleNode[index].isSetPLN = false;

          roleNode.nodes.forEach(projectNode => {
            projectNode.nodes.forEach(userNode => {
              userNode[index].chgBdg = false;
              userNode[index].chgPbdg = false;
            });
          });
        }
      });
    });
  }

  getChangedBudgetedHourByIntervalsForSave() {
    //TODO remove initialPBD before save
    ChangedHoursStore.storeChangedBDGHours(this.nodes, this.intervalsIndexes);
    ChangedHoursStore.tmpChangedBDGHours = {...ChangedHoursStore.changedBDGHours};
    this.emptyNodesChangedBDGHours();
    ChangedHoursStore.emptyChangedBDGHours();

    return ChangedHoursStore.tmpChangedBDGHours;
  }

  clearTreeNodesStore() {
    this.continueLazyLoading = true;
    this.nodesIDs = [];
    this.renderedStartIndex = 0;
    this.renderedStopIndex = 0;
    this.nodes = [];
    this.intervalsIndexes = [];
    this.currentRowsCount = 0;
    this.lazyLoadOffset = 0;
    ChangedHoursStore.emptyChangedBDGHours();
    ChangedHoursStore.emptyTmpChangedBDGHours();

  }
}
