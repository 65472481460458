import _ from 'lodash';

const dataServiceUtilities = {
  getIndex: (intervalFrom, indexes) => {
    /**
     * indexData can be undefined when during loading of missing data the next / prev / today button has been clicked
     */
    let indexData = _.find(indexes, item => intervalFrom === item.from);
    if(indexData) {
      return indexData.index;
    }

    return null;
  }
};

export default dataServiceUtilities;
