import ObjectModel from './ObjectModel';
import {computed, observable} from 'mobx';
import {User as UserObjCode} from 'workfront-objcodes';
import ViewModeStore from '../../../shared/stores/ViewModeStore';
import {isNodeIdDefined} from '../../../shared/services/Utilities';
import {intervalsFTEs} from '../../../shared/stores/IntervalsStore';
import {COST_VIEW_KEY, FTE_VIEW_KEY} from '../../../shared/constants/ViewEnums';

export default class ObjectUserModel extends ObjectModel {
  @observable prevProjectUserModel;
  avatarDownloadURL;

  constructor(ID, name, parentID, editBudgeting = true) {
    super(UserObjCode, ID, name, null, parentID);

    this.avatarDownloadURL = `/internal/user/avatar?ID=${ID}&size=SMALL&time=${new Date().getTime()}`;
    this.showOnlyPlannedHour = !isNodeIdDefined(parentID);
    this.editBudgeting = editBudgeting;
  }

  @computed
  get costPLN() {
    return ViewModeStore.hourViewMode.costCoefficient || this.PLN_COST;
  }

  setBDGHour(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = this.getCostRate(i) ? value * intervalsFTEs.getFTE(i) / this.getCostRate(i) : 0;
  }

  setBDGFTE(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);
  }

  setBDGCost(value, i) {
    if (value === this[i]._BDG) {
      return;
    }

    this[i].chgBdg = true;
    this[i]._BDG = this.getCostRate(i, COST_VIEW_KEY) ? value / this.getCostRate(i, COST_VIEW_KEY) : 0;
  }

  setPBDGHour(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }
    this[i].chgPbdg = true;
    this[i]._PBDG = value * intervalsFTEs.getFTE(i);
  }

  setPBDGFTE(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }
    this[i].chgPbdg = true;
    this[i]._PBDG = value * intervalsFTEs.getFTE(i, FTE_VIEW_KEY);
  }

  setPBDGCost(value, i) {
    if (value === this[i]._PBDG) {
      return;
    }
    this[i].chgPbdg = true;
    this[i]._PBDG = value;
  }
}
