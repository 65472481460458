/**
 * Created by iskuhihakobyan on 4/5/17.
 */
import {observable} from 'mobx';
import userViewDataService from '../services/DataService';
import {regExpForID} from '../../shared/services/Utilities';
import {viewLimits} from '../../shared/constants/LimitConstants';
import {IDExpOptions} from '../../shared/constants/ExpressionOptions';
import {NextPreviousEnum} from '../../shared/constants/NextPreviousEnum';
import UserViewHourUserRoleModel from '../models/hours/HourUserRoleModel';
import UserViewHourTaskProjModel from '../models/hours/HourTaskProjModel';
import {User as UserObjCode} from 'workfront-objcodes';
import _ from 'lodash';

export default class TreeNodesStore {
  @observable continueLazyLoading = true;
  @observable nodesIDs = [];
  lazyLoadOffset = 0;
  //For showing save dialog in project and role views
  unSavedChangesExists = false;
  renderedStartIndex = 0;
  renderedStopIndex = 0;
  nodes = {};

  addNodes(nodes, intervals, storeIntervals, IDExp = '', parentNode = null) {
    let idStr = '',
      level = 0,
      ids = [];

    if (IDExp) {
      idStr = `${IDExp}_`;
      level = IDExp.split('_').length;
      parentNode.offset += nodes.length;
    } else {
      this.lazyLoadOffset += nodes.length;
    }

    nodes.forEach(node => {
      let itemID = idStr + node.ID;

      this.nodes[itemID] = userViewDataService.createNodeObjectModel(level, node, intervals, storeIntervals);
      ids.push(itemID);
    });

    //showing notification when reaching limit and canceling load more for Project Level
    if (!parentNode && this.lazyLoadOffset >= viewLimits.user.firstLevelLimit) {
      ids.push(`${IDExp}${IDExpOptions.notification}`);
      this.continueLazyLoading = false;
    } else if (parentNode && parentNode.objCode === UserObjCode && parentNode.offset >= viewLimits.user.secondLevelLimit) {
      ids.push(`${IDExp}${IDExpOptions.notification}`);
      parentNode.showMore = false;
    }

    //lazy loading or load More for all levels except User
    if (parentNode && parentNode.showMore) {
      ids.push(`${IDExp}${IDExpOptions.more}`);
    }

    let loadingIndex = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.loading}`);
    let spliceIndex = loadingIndex !== -1 ? loadingIndex : this.nodesIDs.length;

    this.nodesIDs.splice(spliceIndex, 0, ...ids);
  }

  deleteSubNodes(IDExp) {
    let regExp = regExpForID(IDExp + '_'),
      start = this.nodesIDs.indexOf(IDExp) + 1,
      deleteCount = 0,
      length = this.nodesIDs.length;

    for (let i = start; i < length; i++) {
      let nodeIDExp = this.nodesIDs[i];

      if (!regExp.test(nodeIDExp)) {
        break;
      }

      delete this.nodes[nodeIDExp];

      deleteCount++;
    }
    this.nodesIDs.splice(start, deleteCount);
  }

  //this function replaces loadMore with loading
  addLoadingRow(IDExp = '') {
    if (!IDExp) {
      this.nodesIDs.push(`${IDExp}${IDExpOptions.loading}`);
    } else {
      const indexOfLoadMore = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.more}`);

      if (indexOfLoadMore !== -1) {
        this.nodesIDs.splice(indexOfLoadMore, 1, `${IDExp}${IDExpOptions.loading}`); //add loading instead of loadMore
      } else {
        this.nodesIDs.splice(this.nodesIDs.indexOf(IDExp) + 1, 0, `${IDExp}${IDExpOptions.loading}`);  //add loading after clicked node
      }
    }
  }

  deleteLoadingRow(IDExp = '') {
    let loadingIndex = this.nodesIDs.indexOf(`${IDExp}${IDExpOptions.loading}`);
    this.nodesIDs.splice(loadingIndex, 1);
  }

  setEmptyRows(nextPrevious, intervalsLength, stepCount) { //For show loading rows
    let deleteIndex, addIndex;

    if (nextPrevious === NextPreviousEnum.next) {
      deleteIndex = 0;
      addIndex = intervalsLength - 1;
    } else {
      deleteIndex = intervalsLength - 1;
      addIndex = 0;
    }

    let addItems = [];
    for (let i = 0; i < stepCount; i++) {
      addItems.push(IDExpOptions.loading);
    }

    _.forEach(this.nodes, (node) => {
      node.intervals.splice(deleteIndex, stepCount);
      node.intervals.splice(addIndex, 0, ...addItems);
    });
  }

  fillIntervalsHours(responseData, nodeIDs, intervals) {
    responseData.forEach((dataItem) => {
      const intervalIndex = _.findIndex(intervals, interval => {
        return interval.from === dataItem.from
      });

      _.forEach(nodeIDs, (IDExp) => {
        let level = IDExp.split('_').length,
          hour = dataItem.hours[IDExp],
          hourModel;

        if (level === 1 || level === 3) {
          hourModel = hour ? new UserViewHourUserRoleModel(hour.AVL, hour.PLN, hour.ACT, dataItem.fth) : null;
        } else if (level === 2 || level === 4) {
          hourModel = hour ? new UserViewHourTaskProjModel(hour.PLN, hour.ACT, dataItem.fth) : null;
        }

        this.nodes[IDExp].intervals[intervalIndex] = hourModel;

      });
    });
  }

  getRequestIDs(sliceStart, sliceEnd) {
    let IDs = this.nodesIDs.slice(sliceStart, sliceEnd + 1);

    return IDs.filter((ID) => ID.indexOf(IDExpOptions.notification) === -1 && ID.indexOf(IDExpOptions.more) === -1 && ID.indexOf(IDExpOptions.loading) === -1)
  }

  clearTreeNodesStore() {
    this.continueLazyLoading = true;
    this.nodesIDs = [];
    this.lazyLoadOffset = 0;
    this.unSavedChangesExists = false;
    this.renderedStartIndex = 0;
    this.renderedStopIndex = 0;
    this.nodes = {};
  }
}
