//Libraries
import _ from 'lodash';
import moment from 'moment';
// Stores
import ViewModeStore from '../../shared/stores/ViewModeStore';

const ChangedHoursStore = {
  changedBDGHours: {
    roles: [],
    plannedRoles: [], // It is that roles in which was clicked "set planned to budgeted"
    budgetedHours: []
  },
  /**
   * tmpChangedBDGHours is used for
   * used for roll back changed data when the save is crushed,
   * used for change role and loaded project nodes PBDG and initialPDG hours after save
   * used for next/Prev actions when planner is in saving mode, but now it isn't actual because planner is going to loading mode during save
   */
  tmpChangedBDGHours: {
    roles: [],
    plannedRoles: [],
    budgetedHours: []
  },

  emptyChangedBDGHours: () => {
    ChangedHoursStore.changedBDGHours = {
      roles: [],
      plannedRoles: [],
      budgetedHours: []
    };
  },

  emptyTmpChangedBDGHours: () => {
    ChangedHoursStore.tmpChangedBDGHours = {
      roles: [],
      plannedRoles: [],
      budgetedHours: []
    };
  },

  getToDate(from) {
    return moment(from).add(1, ViewModeStore.activeStep.key).format(ViewModeStore.dateFormat);
  },

  storeChangedBDGHours(nodes, intervalsIndexes) {
    _.forEach(intervalsIndexes, ({from, index}) => {
      let to = ChangedHoursStore.getToDate(from);
      let roles = {}, budgetedHours = {}, plannedRoles = [];

      let storedRoles = _.find(ChangedHoursStore.changedBDGHours.roles, item => item.from === from);
      if (storedRoles) {
        roles = storedRoles.hours;
      }

      let storedPlannedRoles = _.find(ChangedHoursStore.changedBDGHours.plannedRoles, item => item.from === from);
      if (storedPlannedRoles) {
        plannedRoles = storedPlannedRoles.roles;
      }

      let storedBudgetedHours = _.find(ChangedHoursStore.changedBDGHours.budgetedHours, item => item.from === from);
      if (storedBudgetedHours) {
        budgetedHours = storedBudgetedHours.hours;
      }

      nodes.forEach(roleNode => {
        if (roleNode[index]) {
          if (roleNode[index].chg) {
            roles[roleNode.ID] = roleNode[index].BDG;
          }

          if (roleNode[index].isSetPLN && plannedRoles.indexOf(roleNode.ID) === -1) {
            plannedRoles.push(roleNode.ID);
          }
        }

        roleNode.nodes.forEach(projectNode => {
          projectNode.nodes.forEach(userNode => {
            if (userNode[index].chgBdg || userNode[index].chgPbdg) {
              budgetedHours[projectNode.ID] = budgetedHours[projectNode.ID] || {};
              budgetedHours[projectNode.ID][roleNode.ID] = budgetedHours[projectNode.ID][roleNode.ID] || {};
              budgetedHours[projectNode.ID][roleNode.ID][userNode.ID] = budgetedHours[projectNode.ID][roleNode.ID][userNode.ID] || {};
              budgetedHours[projectNode.ID][roleNode.ID][userNode.ID].BDG = userNode[index]._BDG;

              //if (userNode[index].chgPbdg) {
              budgetedHours[projectNode.ID][roleNode.ID][userNode.ID].PBDG = userNode[index]._PBDG;
              budgetedHours[projectNode.ID][roleNode.ID][userNode.ID].initialPBDG = userNode[index]._initialPBDG;
              // }
            }
          });
        });
      });

      ChangedHoursStore.pushToChangedStore('roles', from, to, roles);
      ChangedHoursStore.pushToChangedStore('plannedRoles', from, to, plannedRoles);
      ChangedHoursStore.pushToChangedStore('budgetedHours', from, to, budgetedHours);
    });
  },

  pushToChangedStore(key, from, to, data) {
    let index = _.findIndex(ChangedHoursStore.changedBDGHours[key], item => item.from === from);
    let pushData = key === 'plannedRoles' ? {from, to, roles: data} : {from, to, hours: data};

    if (index !== -1) {
      ChangedHoursStore.changedBDGHours[key][index] = pushData;
    } else {
      if (!_.isEmpty(data)) {
        ChangedHoursStore.changedBDGHours[key].push(pushData);
      }
    }
  },

  getChangedUserBDG: (from, roleID, projectID, userID) => {
    let tmpChangedHours = _.find(ChangedHoursStore.tmpChangedBDGHours.budgetedHours, item => item.from === from);
    let changedHours = _.find(ChangedHoursStore.changedBDGHours.budgetedHours, item => item.from === from);
    let BDG = null,
      PBDG = null,
      initialPBDG = null,
      isChangedBDG = false,
      isChangedPBDG = false;

    //get tmp changes
    if (tmpChangedHours && tmpChangedHours.hours[projectID] && tmpChangedHours.hours[projectID][roleID] && tmpChangedHours.hours[projectID][roleID][userID]) {
      BDG = tmpChangedHours.hours[projectID][roleID][userID].BDG;

      if (tmpChangedHours.hours[projectID][roleID][userID].PBDG) {
        PBDG = tmpChangedHours.hours[projectID][roleID][userID].PBDG;
        initialPBDG = tmpChangedHours.hours[projectID][roleID][userID].initialPBDG;
      }
    }

    //overwrite tmp changes if the userHour has been changed after stored in tmp
    if (changedHours && changedHours.hours[projectID] && changedHours.hours[projectID][roleID] && changedHours.hours[projectID][roleID][userID]) {
      BDG = changedHours.hours[projectID][roleID][userID].BDG;
      isChangedBDG = true;

      if (changedHours.hours[projectID][roleID][userID].PBDG) {
        isChangedPBDG = true;
        PBDG = changedHours.hours[projectID][roleID][userID].PBDG;
        initialPBDG = changedHours.hours[projectID][roleID][userID].initialPBDG;
      }
    }

    return {BDG, PBDG, initialPBDG, isChangedBDG, isChangedPBDG}
  },

  getChangedRoleData(roleID, from) {
    let isChanged = false, setPLN = false, BDG = null;

    // The same role can't be simultaneously in changedBDGHours.roles and changedBDGHours.plannedRoles
    let tmpRoles = _.find(ChangedHoursStore.tmpChangedBDGHours.roles, item => item.from === from);
    if (tmpRoles && _.isNumber(tmpRoles.hours[roleID])) {
      BDG = tmpRoles.hours[roleID];
    }

    let tmpPlannedRoles = _.find(ChangedHoursStore.tmpChangedBDGHours.plannedRoles, item => item.from === from);
    if (tmpPlannedRoles && tmpPlannedRoles.roles.indexOf(roleID) !== -1) {
      setPLN = true;
    }

    //if role is changed after stored in tmp, the BDG data should be overwritten
    let roles = _.find(ChangedHoursStore.changedBDGHours.roles, item => item.from === from);
    if (roles && _.isNumber(roles.hours[roleID])) {
      BDG = roles.hours[roleID];
      setPLN = false;
      isChanged = true;
    }

    let plannedRoles = _.find(ChangedHoursStore.changedBDGHours.plannedRoles, item => item.from === from);
    if (plannedRoles && plannedRoles.roles.indexOf(roleID) !== -1) {
      BDG = null;
      setPLN = true;
    }

    return {isChanged, setPLN, BDG}
  },

  getChangedAndNotLoadedUsersPBDGVariance(from, roleID, loadedProjectIDs) {
    let sumVariances = 0;
    let budgetedHours = _.find(ChangedHoursStore.changedBDGHours.budgetedHours, item => item.from === from);
    let tmpBudgetedHours = _.find(ChangedHoursStore.tmpChangedBDGHours.budgetedHours, item => item.from === from);

    if (budgetedHours) {
      _.forEach(budgetedHours.hours, (roleData, projectID) => {
        if (loadedProjectIDs.indexOf(projectID) === -1 && roleData[roleID]) {
          sumVariances += _.reduce(roleData[roleID], (PBDGSum, userNode) => PBDGSum + (_.isNumber(userNode.PBDG) ? userNode.PBDG - userNode.initialPBDG : 0), 0);
        }
      });
    }

    //also should add tmp changed hours if they are not exists in real changes
    if (tmpBudgetedHours) {
      _.forEach(tmpBudgetedHours.hours, (roleData, projectID) => {
        if (loadedProjectIDs.indexOf(projectID) === -1 && roleData[roleID]) {
          sumVariances += _.reduce(roleData[roleID], (PBDGSum, userNode, userID) => {
            let PBDG = 0;

            if (!budgetedHours || !budgetedHours.hours[projectID] || !budgetedHours.hours[projectID][roleID] || !budgetedHours.hours[projectID][roleID][userID]) {
              PBDG = _.isNumber(userNode.PBDG) ? userNode.PBDG - userNode.initialPBDG : 0;
            }

            return PBDGSum + PBDG;
          }, 0);
        }
      });
    }

    return sumVariances;
  },

  removeStoredDataForRole(store, roleID, from) {
    // delete appropriate role data from store.plannedRoles
    let plannedRolesIndex = _.findIndex(store.plannedRoles, item => item.from === from);
    if (plannedRolesIndex !== -1) {
      let plannedRoles = store.plannedRoles[plannedRolesIndex].roles;
      plannedRoles.splice(plannedRoles.indexOf(roleID), 1);
      if (_.isEmpty(plannedRoles)) {
        store.plannedRoles.splice(plannedRolesIndex, 1);
      }
    }

    // delete appropriate role data from store.roles
    let rolesIndex = _.findIndex(store.roles, item => item.from === from);
    if (rolesIndex !== -1) {
      let roles = store.roles[rolesIndex].hours;
      delete roles[roleID];
      if (_.isEmpty(roles)) {
        store.roles.splice(rolesIndex, 1);
      }
    }

    // delete appropriate role data from store.budgetedHours
    let budgetedHoursIndex = _.findIndex(store.budgetedHours, item => item.from === from);
    if (budgetedHoursIndex !== -1) {
      let budgetedHours = store.budgetedHours[budgetedHoursIndex].hours;

      _.forEach(budgetedHours, (roleData, projectID) => {
        if (roleData[roleID]) {
          _.forEach(roleData[roleID], (userData, userID) => {
            delete userData.PBDG;
            delete userData.initialPBDG;

            if (_.isEmpty(userData)) {
              delete roleData[roleID][userID];
            }
          });

          if (_.isEmpty(roleData[roleID])) {
            delete roleData[roleID];
          }

          if (_.isEmpty(budgetedHours[projectID])) {
            delete budgetedHours[projectID];
          }
        }
      });

      if (_.isEmpty(budgetedHours)) {
        store.budgetedHours.splice(budgetedHoursIndex, 1);
      }
    }
  },

  removeStoredPBDGHours(roleID, from) {
    ChangedHoursStore.removeStoredDataForRole(ChangedHoursStore.changedBDGHours, roleID, from);
    ChangedHoursStore.removeStoredDataForRole(ChangedHoursStore.tmpChangedBDGHours, roleID, from);
  },

  //The following code is working only when save gives error
  mergeChangedBDGHoursRoles() {
    _.forEach(ChangedHoursStore.tmpChangedBDGHours.roles, tmpItem => {
      let to = ChangedHoursStore.getToDate(tmpItem.from);
      let changedRoles = _.find(ChangedHoursStore.changedBDGHours.roles, item => item.from === tmpItem.from);
      let changedPlannedRoles = _.find(ChangedHoursStore.changedBDGHours.plannedRoles, item => item.from === tmpItem.from);

      _.forEach(tmpItem.hours, (BDG, roleID) => {
        if (!changedPlannedRoles || changedPlannedRoles.roles.indexOf(roleID) === -1) {
          if (changedRoles && !changedRoles.hours[roleID]) {
            changedRoles.hours[roleID] = BDG;
          } else if (!changedRoles) {
            ChangedHoursStore.changedBDGHours.roles.push({from: tmpItem.from, to: to, hours: {roleID: BDG}})
          }
        }
      });
    });
  },

  //The following code is working only when save gives error
  mergeChangedBDGHoursPlannedRoles() {
    _.forEach(ChangedHoursStore.tmpChangedBDGHours.plannedRoles, tmpItem => {
      let to = ChangedHoursStore.getToDate(tmpItem.from);
      let changedPlannedRoles = _.find(ChangedHoursStore.changedBDGHours.plannedRoles, item => item.from === tmpItem.from);
      let changedRoles = _.find(ChangedHoursStore.changedBDGHours.roles, item => item.from === tmpItem.from);

      _.forEach(tmpItem.roles, roleID => {
        if (!changedRoles || !changedRoles.hours[roleID]) {
          if (changedPlannedRoles && changedPlannedRoles.roles.indexOf(roleID) === -1) {
            changedPlannedRoles.roles.push(roleID);
          } else if (!changedPlannedRoles) {
            ChangedHoursStore.changedBDGHours.plannedRoles.push({from: tmpItem.from, to: to, roles: [roleID]})
          }
        }
      });
    });
  },

  //The following code is working only when save gives error
  mergeBudgetedHours() {
    _.forEach(ChangedHoursStore.tmpChangedBDGHours.budgetedHours, tmpItem => {
      let to = ChangedHoursStore.getToDate(tmpItem.from);
      let changedBudgetedHours = _.find(ChangedHoursStore.changedBDGHours.budgetedHours, item => item.from === tmpItem.from);

      if (!changedBudgetedHours) {
        changedBudgetedHours = {from: tmpItem.from, to: to, hours: {}};
        ChangedHoursStore.changedBDGHours.budgetedHours.push(changedBudgetedHours);
      }

      _.forEach(tmpItem.hours, (projectData, projectID) => {
        changedBudgetedHours.hours[projectID] = changedBudgetedHours.hours[projectID] || {};

        _.forEach(projectData, (roleData, roleID) => {
          changedBudgetedHours.hours[projectID][roleID] = changedBudgetedHours.hours[projectID][roleID] || {};
          _.forEach(roleData, (userData, userID) => {
            changedBudgetedHours.hours[projectID][roleID][userID] = changedBudgetedHours.hours[projectID][roleID][userID] || {};
            let changedUser = changedBudgetedHours.hours[projectID][roleID][userID];

            if (!changedUser.BDG && _.isNumber(userData.BDG)) {
              changedUser.BDG = userData.BDG;
            }

            if (!changedUser.PBDG && _.isNumber(userData.PBDG)) {
              changedUser.PBDG = userData.PBDG;
              changedUser.initialPBDG = userData.initialPBDG;
            }
          });
        });
      });
    });
  },
  /**
   * when save has not been successfully done
   * should set tmpChangedBDGHours into changedBDGHours
   * by giving priority to data which already exists in changedBDGHours
   */
  //TODO there can be issue because never been tested
  mergeCurrentAndTmpChanges() { //only need when save has not been successfully done
    ChangedHoursStore.mergeChangedBDGHoursRoles();
    ChangedHoursStore.mergeChangedBDGHoursPlannedRoles();
    ChangedHoursStore.mergeBudgetedHours();
  }
};
export default ChangedHoursStore
