import React, {Component} from 'react';
import ViewModeStore from '../../stores/ViewModeStore';

const rowTypeClassName = {
  body: {
    columnClassName: 'hour-block',
    rowClassName: 'hours-row'
  },
  header: {
    columnClassName: 'hour-type',
    rowClassName: 'hour-types-row'
  }
};

export default class LoadingRow extends Component {
  //noinspection JSAnnotator
  props:{
    rowType: Object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const type = rowTypeClassName[this.props.rowType];

    const loadingRowItem = ViewModeStore.hourKeys.map(hour => {
      return (
        <div className={type.columnClassName} key={hour}>
          <div className="loading-box loading-box-sm"></div>
        </div>
      );
    });

    return (
      <div className={type.rowClassName}>
        {loadingRowItem}
      </div>
    );
  }
}


