/**
 * Created by artakpoghosyan on 8/29/17.
 */
import React, {Component} from 'react';
import {TertiaryButton} from 'titan/react-button';
import {Localization} from '../../../localization/localization';
import {ViewSteps} from '../../constants/ViewEnums';
import ViewModeStore from '../../stores/ViewModeStore';
import {DATE_FORMAT} from '../../constants/MonthsEnum';
import ScrollStore from '../../stores/ScrollStore';
import DropDown from '../DropDown';
import DropDownList from '../DropDownList';
import {observer} from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';

@observer
export default class PeriodButtons extends Component {
  viewStep;
  viewStepsList = [];

  //noinspection JSAnnotator
  props: {
    intervalStore: Object,
    treeNodeStore: Object,
    loadStoreData: Function,
    disabled: Boolean,
    showSaveDialog: Function
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClickAction = this.handleClickAction.bind(this);
    this.viewOnSmallScreen = this.viewOnSmallScreen.bind(this);

    /**
     * Create new array from 'ViewSteps' for rendering view steps
     * dropDown on small screens
     */
    this.viewStepsList = _.map(ViewSteps, (value) => {
      return {
        key: value.key,
        name: value.name,
        messageKey: value.key
      }
    });

  }

  handleClickAction() {
    let {intervalStore} = this.props,
      fromDate = moment(intervalStore.intervals[0].from).locale('en');

    /*
     * In week view (when week is between 2 months) as a
     * visibleIntervalsFrom we should take the first day of 2th month in the week;
     */

    if (ViewModeStore.activeStep.key === ViewSteps.week.key) {
      fromDate = fromDate.endOf('week').startOf(this.viewStep.key);
    } else {
      fromDate = fromDate.startOf(this.viewStep.key);
    }

    intervalStore.visibleIntervalsFrom = fromDate.format(DATE_FORMAT);
    ViewModeStore.activeStep = this.viewStep;

    ScrollStore.calculateVirtualizedTableWidth();
    this.props.loadStoreData();
  }

  handleClick(step) {
    if (ViewModeStore.activeStep.key === step.key) {
      return;
    }

    this.viewStep = step;

    if (this.props.treeNodeStore.unSavedChangesExists) {
      this.props.showSaveDialog(this.handleClickAction);
    } else {
      this.handleClickAction();
    }
  }

  viewOnSmallScreen() {
    return (
      <DropDown selectedName={<Localization messageKey={ViewModeStore.activeStep.key}/>}
                disabled={this.props.disabled}>
        <DropDownList listItems={this.viewStepsList} handleClick={this.handleClick}
                      activeItem={ViewModeStore.activeStep}/>
      </DropDown>
    )
  }

  render() {
    const {disabled} = this.props;

    return (
      <div className="period-buttons">
        <div className="rp-has-dropdown period-buttons-sm" data-wf-popover-container>
          {this.viewOnSmallScreen()}
        </div>

        <div className="period-buttons-lg">
          <TertiaryButton className="btn-period" onClick={() => this.handleClick(ViewSteps.week)}
                          disabled={disabled || ViewModeStore.activeStep.key === ViewSteps.week.key}>
            <Localization messageKey="week"/>
          </TertiaryButton>
          <TertiaryButton className="btn-period" onClick={() => this.handleClick(ViewSteps.month)}
                          disabled={disabled || ViewModeStore.activeStep.key === ViewSteps.month.key}>
            <Localization messageKey="month"/>
          </TertiaryButton>
          <TertiaryButton className="btn-period" onClick={() => this.handleClick(ViewSteps.quarter)}
                          disabled={disabled || ViewModeStore.activeStep.key === ViewSteps.quarter.key}>
            <Localization messageKey="quarter"/>
          </TertiaryButton>
        </div>
      </div>
    );
  }
}
